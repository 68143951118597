.basket {

    .singleissue {

        &:before {
            content: 'example';
            display: block;
            transform: rotate(-52deg);
            position: absolute;
            font-weight: bold;
            color: $off-black-border-colour;
            font-size: 25px;
            top: 39px;
            left: -13px;
            z-index: 1;
            opacity: 0.5;
        }
        img {
            filter: opacity(0.75);
        }
    }

    .startIssueBlock {
        color: $primary-color;
        font-size: 14px;
        font-style: normal;
        font-weight: 600 !important;
        line-height: 24px;
        margin-right: 5px;

        .tt-info {
            margin: 0px 5px !important;
            position: relative;
            top: 2px;

            .fa-question:before {
                top: -2px;
                font-size: 13px;
                position: relative;
            }
        }
    }

    .checkout_btn {
        width: 288px;
        padding: 10px 16px;
        border: 1px solid $red;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        font-weight: 700;
    }

    h5 {
        margin-top: 34px;
        font-weight: 700;
        font-size: 28px !important;
        font-family: $normal-font-stack !important;
    }
}

@media screen and (min-width: 750px) and (max-width: 1100px) and (orientation: landscape) {
    .basket {

        .singleissue {
            
            &:before {
                font-size: 16px;
                top: 24px;
                left: -6px !important;
            }
        }
    }
}
@media screen and (min-width: 750px) and (max-width: 1100px) and (orientation: portrait) {
    .basket {

        .singleissue {

            &:before {
                font-size: 38px;
                top: 69px;
                left: -17px !important;
            }
        }
    }
}

@media screen and (min-width: 750px) and (max-width: 930px) and (orientation: landscape) {
    .basket {
        
        .singleissue {
            
            &:before {
                font-size: 42px;
                top: 84px;
                left: -16px !important;
            }
        }
    }
}

@media screen and (max-width: 750px) and (orientation: landscape) {
    .basket {
        
        .singleissue {
            
            &:before {
                font-size: 38px;
                top: 82px;
                left: 13px !important;
            }
        }
    }
}

@media screen and (max-width: 750px) and (orientation: portrait) {
    #basket-message {
        width: 97% !important;
    }
    .basket {
        
        .singleissue {
            
            &:before {
                font-size: 22px;
                left: 14px !important;
            }
        }
        
        .checkoutButtons {
            form.paypalForm, form.checkoutForm {
                width: 100%;

                #paypalimmediatecheckoutbutton, #checkoutButtonMobile, #checkoutButton {
                    width: 100% !important;
                }
            }

            form.paypalForm {
                margin-bottom: 15px;
            }
        }

        h5 {
            margin-top: 15px;
        }
    }
}