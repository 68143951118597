.mobileOnlyShow {
	display: none!important;
}

.banner_countdown {
	text-align: center;
	li {
		display: inline-block;
		width: 52px;
		height: 32px;
		font-family: $demibold-font-stack ;
		font-size: 30px;
		text-align: center;
		margin-right: 10px;
		padding: 10px;
		line-height: 18px;
		span {
			font-size: 12px;
		}
	}
}

.flag {
	float: left;
	height: 28px;
	margin: 4px;
	border: 1px #ccc solid;
}
.country-changer-bs {
	right: 0px;
	
	.country-arrow {
		height: 7px;
		vertical-align: super;
		transition: transform 0.5s;
	}
	.country-arrow.rotate {
		transform: rotate(180deg);
	}
		
	.header-flag {
		height: 24px;
		margin: 0 4px 0 10px;
		border: 0px #ccc solid;
	}
	
	.country-select {
		margin-top: 35px!important;
		
		.flag {
			height: 25px;
			margin: 3px 5px;
		}
		input[type=text] {
			height: 30px;
			width: 270px;
			font-size: 13px;
		}
	}
}
@media screen and (max-width: 768px) {
	.site-header {
		transition: height 0.25s;
		padding-top: 0px!important;
	}
	.mobile-singleprice {
		height: 145px;
	}
	.country-changer-bs {
		top: 35px;
		right: 10px;
		
		.country-select {
			display: none;
		}
	}
}
@media screen and (max-width: 650px) {
	.mobileOnlyHide {
		display: none!important;
	}
	.mobileOnlyShow {
		display: block!important;
	}
	.mobileImageWidth {
		width: 100%;
	}
}
.mobileOnlyHide, .mobileOnlyShow {
	h2 {
		img {
			width: 100%!important;
			height: auto !important;
		}
	}
}
.disclaimerText, #singleIssueDisclaimer {
	font-style: italic;
    font-size: 12px;
    text-align: center;
    margin-top: -3px;
    line-height: 17px;
}