.centred {
	margin: 0 auto;
	text-align: center;
}

.test-462461 {
	overflow: hidden;	
	
	.blue-flash {
		min-height: 100px;
		height: auto;
		.message {
			padding: 8px 80px 0 80px;
			* {
				font-size: 17px;
				line-height: 24px;
			}
			#intro_more {
				margin-bottom: 80px;
			}
			h2 {
				font-size: 30px;
				line-height: 50px;
			}				
		}
		#countdown {
			bottom: 70px !important;
			left: 78px !important;
		}
	}
	.row.subscriptionblock {
		margin-top: -30px;
		position: relative;
		padding: 0 80px;
		overflow: hidden;
				
		.lefthandside {
			width: 50%;
			float: left ;
			
			.covers {
				overflow: hidden;

				.small {
					width: 73px;
					float: left;
					.updatecover {
						width: 100%;
					}
				}
				.large {
					width: 375px;
					float: right;
					position: relative ;
					
					#mag-cover {
						width: 100%;
					}
					.look-inside.move-to-top {
						padding: 7px;
						height: inherit;
					}
				}
			}
			.giftboxwitharrow {
				position: relative;

				.giftbox {
					clear: both;
					margin: 10px 0 0 95px;
					padding: 10px;
					border: 1px solid #e9e9e9;
					
					.headline {
						font-weight: bold;
					}
											
					img.left {
						float: left;
						padding-bottom: 30%;
					}
					&.imageattop {
						img {
							width: 100% ;
						}
					}
				}
				.arrow-right {
					position: absolute;
					right: -20px;
					top: 80px;
					width: 0; 
					height: 0; 
					border-top: 20px solid transparent;
					border-bottom: 20px solid transparent;
					border-left: 20px solid #e9e9e9;
				}
			}
			.publisher-statement {
				float: right;
				width: 360px;
				padding: 14px 0 0 14px;
				text-indent: -14px ;
			}
		}
		.righthandside {
			width: 420px;
			float: right;
			background-color: initial ;
			
			.tabs {
				overflow: hidden;
			
				.tab {
					background-color: $grey-border-colour;
					width: 192px;
					height: 32px;
					border: 1px #000000 solid;
					float: left;
					list-style: none;
					padding: 8px;
					text-align: center;
					cursor: pointer;
				}
				.tab:first-child {
					border-top-right-radius: 0;
					border-right: 1px  $grey-border-colour solid;
				}
				.tab.active {
					border-bottom: 1px #f9f9f9 solid;
					background-color: #f9f9f9;
				}
				
				.tab:first-child.active {
					border-right: 1px #f9f9f9 solid ;
				}
				.tab:only-child {
					width: 402px;
					border-right: 1px  #000000 solid !important;
				}
			}
			
			.blueflashtext {
				background-color: #f9f9f9;
				border-left: 1px #000000 solid;
				border-right: 1px #000000 solid;

				* {
					display: none;
				}

				h3 {
					display: block;
					text-align: center;
					color: $red;
					padding: 20px;
				}
			}
			
			.choose-offer {
				background-color: #f9f9f9;
				text-align: center;
				border-left: 1px #000000 solid;
				border-right: 1px #000000 solid;
				border-bottom: none;
				border-radius: 0;
				width: 418px;
				display: none;

				.only {
					font-weight: bold;
					font-size: 20px;
					padding-top: 20px;
				}
				
				&.active {
					
					.price-string{
						color: $red;
						font-size: 80px;
						line-height: 100px;
					}
					.price-statement {
						font-weight: bold;
						font-size: 18px;
					}
					.price-offer {
						color: $red;
						font-size: 40px;
						padding-bottom: 15px;
					}
					
					.simple-inline {
						display: inline;
						font-size: 15px;
						border: none;
						width: auto;
						float: none;
						height: 25px;
						outline: none;
						background-color: none;
					}
					.country {
						a {
							color: $red;
						}
						.country-select {
							display: inline-block;
							margin: 8px 0;
						}	
					}
					.giftblock {
						text-align: left;
						padding: 0px 20px 10px 20px;
						width: auto ;
						
						.isGift {
							width: 50px;
							padding-left: 26px;
						}
						label {
							width: 177px;
							margin: 0 ;
							background: white;
						}
						label:first-child {
							margin-right: 20px;
						}
						.labelled {
							margin: 0;
							padding: 0;
							height: 40px;
						}
						.gifterror {
							visibility: hidden; 
							font-size: 14px;
						}
						&.error {
							label {
								border-color: #ff0000;
								background: rgba(214, 9, 9, 0.5);
							}
							.gifterror {
								visibility: visible; 
								color: $red;
							}	
						}
					}
					.button {
						width: 400px;
					}
				}
			}
			.bottom-of-box {
				background-color: #f9f9f9;
				border: 1px #000000 solid;
				border-top: none;
				padding: 20px 45px;
				
				.green-ticks {
					li {
						background-image: url(/images/green-tick-icon.png) ;
						background-repeat: no-repeat;
						background-position-y: center;
						
						span {
							display: inline-block;
							padding: 10px 0 10px 38px;
						}
					}
				}
				.asa {
					border-top: 1px solid grey;
					margin-top: 12px;
					padding-top: 12px;
					text-align: center;
				}
				.more-info {
					left: -140px;
				}
			}
		}
	}
	
	.row.messagefromeditor {
		margin: 30px 120px 60px 120px;
		* {
			text-align: center;
		}
		h2 {
			width: 100%;
			padding-bottom: 20px;
			border-bottom: 1px black solid;
			margin: 0 auto;
		}
		.editor-message {
			padding-top: 10px;
		}
	}
	
}