.gift-card {
	.heading {
		clear:both;
		font:bold 25px sans-serif;
		margin:25px 0;
		padding:5px 0 4px 6px;
	}
	
	h3 {
		color:#b10019;
		font-size:16px;
		font-weight:bold;
		margin:20px 0 5px 0;
	}

	h1 {
		padding: 32px 0;
		color: $black;
		margin-bottom:0;
	}
	padding: 0 85px;
	b {
		font-family:$demibold-font-stack ;
	}	
	p {
		line-height:19px; 
		margin:0 0 10px 0;
	}
	button, input, select, textarea {
		font-size:100%; 
		margin:0; 
		vertical-align:baseline;
	}
	.send-options {
		float:left;
	}
	#sending-datetime {
		width: 340px;
	}
	.send-options p {
		font-weight:bold;
		line-height:normal;
		margin-top:10px;
	}
	.send-method {
		margin-bottom:15px;
	}
	.send-method p {
		float:left;
		margin:0 10px 0 0;
	}
	.send-method input {
		clear: right;
		float: right;
		margin-bottom: 10px;
	}
	.send-method label {
		float: left;
		margin-top: 7px;
		margin-right: 10px;
	}
	.error {
		margin-top:0;
		display:none;
		color: #b10019;
		font-weight:bold !important;
		font-size:20px !important;
	}
	.buttons.clearfix.giftbtn {
		margin-top: -40px;
		margin-right: 149px;
		margin-bottom: 20px;
    }
	.recipient-method,
	.recipient-method-confirm {
		float:none;
		margin-bottom:10px;
	}
	.date {
		position:relative;
	}
	.date .icon-calendar {
		background: url("/images/calendar.gif") no-repeat 0 0 transparent;
		border: none !important;
		border-radius: 0 0 0 0 !important;
		height: 15px !important;
		padding: 0 !important;
		text-indent: -999em;
		width: 16px !important;
		cursor: pointer;
		margin: 12px 0 0 -26px;
	}
	.date .error {
		padding-top:10px;
	}
	.sending-datetime {
		cursor: pointer;
		width:194px;
	}
	.recipient-name input {
		clear:right;
		float:right;
		margin-bottom:10px;
	}
	.clearfix, .form li {
		zoom:1;
	}
	.recipient-name label {
		float:right;
		margin-top: 7px;
		margin-right: 10px;
	}
	.recipient-name .error {
		margin:0 0 10px 17px;
		float: right;
	}
	.send-gift-card {
		margin:7px 0 0 0;
	}
	.form {
		list-style:none;
		margin:0;
		padding:0;
	}
	.form li {
		margin:12px 0;
		display: inline-block;
	}
	.form label {
		float:left;
		font-weight:bold;
		margin:5px 10px 0 0;
		width:170px;
	}
	.form input {
		float:left;
		width:280px;
	}
	textarea {
		-moz-box-shadow:0 3px 6px rgba(0, 0, 0, 0.1) inset;
		-webkit-box-shadow:0 3px 6px rgba(0, 0, 0, 0.1) inset;
		box-shadow:0 3px 6px rgba(0, 0, 0, 0.1) inset;
		behavior:url(/pie/pie.php);
	}
	.form .multichoice .clearfix {
		display:block;
		margin:5px 0;
	}
	.form .multichoice label {
		margin:0;
		width:400px;
	}
	.form .multichoice input, 
	.form .textarea label {
		display:block;
		float:none;
		margin:0 0 5px;
	}
	.form textarea {
		border:1px solid #ddd;
		height:60px;
		padding:4px 6px;
		width:460px;
	}
	.gift-card-options {
	clear:left;
	float:left;
	width:202px;
	}
	.gift-card-message {
		float: none;
		width: 820px;
	}
	.active {
		display:inline-block !important;
	}
	.inactive {
		display:none !important;
	}
#giftcard-slider {
    position: relative;
    overflow: hidden;
}
#gift-card-details {
	position: relative;
}
a.control_prev, a.control_next {
	position: absolute;
	top: 55px;
    z-index: 999;
    display: block;
    width: auto;
    height: auto;
    color: #2a2a2a;
    text-decoration: none;
    font-weight: 600;
    font-size: 68px;
    opacity: 0.6;
    cursor: pointer;
}

a.control_prev:hover, a.control_next:hover {
	opacity: 1;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
}

a.control_prev {
	left: -18px;
}

a.control_next {
	right: 6px;
}
	.gift-card-settings {
		width: 795px;
	}
	.gift-card-options input {
		clear:left;
		margin:11px 10px 0 0;
		padding:0;
		width:auto;
	}
	.oldie .gift-card-options input {
		height:20px;
		margin-top:3px;
	}
	.gift-card-options label {
		margin:10px 0 0 0;
		width:auto;
	}
	.gift-card-message.editmessage {
		padding: 65px 0 0px 60px;
		width: 740px;
		margin: 0 auto;
		border: 1px solid #bfbfbf;
	}
	.gift-card-message img {
		border:3px solid #fff;
		cursor: pointer;
		margin:5px;
	}
	.gift-card-message img:hover, .gift-card-message .selected {
		border-color:#021419;
	}
	.gift-card-message .last {
		margin-right:0;
	}
	.gift-card-settings div {
		text-align: center;
		width: 151px;
		height: 125px;
	}
	.gift-card-message .error {
		padding-top:10px;
	}
	#gift-card-details textarea {
		font-weight: bold;
		min-height: 450px;
		margin-top:12px;
		width:510px;
		border: 0px solid;
		background: transparent;
		resize: none;
		-moz-box-shadow:0 0 0 rgba(0, 0, 0, 0.1) inset;
		-webkit-box-shadow:0 0 0 rgba(0, 0, 0, 0.1) inset;
		box-shadow:0 0 0 rgba(0, 0, 0, 0.1) inset;
		behavior:url(/pie/pie.php);
		overflow: hidden;
	}
	.card {
		clear:both;
		padding:30px 0;
		width: 820px;
	}
	.preview-card {
		margin:0 auto;
	}
	.bg-card-top {
		background:no-repeat 0 0;
		min-height:100px;
		border: 1px solid #bfbfbf;
	}
	.bg-card-bottom {
		background:no-repeat 0 bottom;
		height:0px;
	}
	.card pre {
		white-space:-moz-pre-wrap;
		white-space:-pre-wrap;
		white-space:-o-pre-wrap;
		white-space:pre-wrap;
		word-wrap:break-word;
		width: 485px;
		display: inline-block;
		margin: 75px 0 20px 75px;
		font-weight: bold;
		line-height: 17px;
	}
	.ie6 .card pre {
		margin:55px 0 0 50px;
	}
	.cover-image {
		display:inline;
		float:right;
		width:117px;
		margin: 60px 50px 0 0;
	}
	.cover-image p {
		font-weight:bold;
		line-height:normal;
		margin:15px 0 10px 0;
		text-align:center;
	}
	.cover-image img {
		border:none;
		cursor:default;
		display:block;
		float:none;
		margin:0 0 5px 0;
		padding:0;
	}
	.white p, .white pre {
		color:#fff;
	}
	.black p, .black pre {
		color:$black;
	}
	.red p, .red pre {
		color:#f00;
	}
	.green p, .green pre {
		color:#2CBB2B;
	}
	.clearfix:before, .clearfix:after, .form li:before, .form li:after { 
		content:'';
		display:table; 
	}
	.clearfix:after, .form li:after { 
		clear:both; 
	}
	
	#confirm-card-details dt, #confirm-card-details dd {
		color:#b10019;
		float:left;
		font-weight:bold;
		margin:10px 0;
	}
	#confirm-card-details dt {
		clear:left;
		color:#494949;
		width:180px;
	}
	.button {
		background-color: #b10019;
		float: right !important;	
	}
		
	}
.track-my-order {

	.item-details {
		margin-bottom:40px;
	}
	.item-details img {
		float:left;
	}
	
	.item-details dl {
		float:left;
		margin:2px 0 0 40px;
		max-width:520px;
	}
	.item-details .basketItemDeliveryMessage {
		display:none;
	}
	.item-details dt, .item-details dd {
		display:inline;
		float:left;
		font-size:15px;
		font-weight:bold;
		margin:6px 5px 6px 0;
		width:370px;
	}
	.item-details dt {
		clear:left;
		color:#b10019;
		width:140px;
	}
	h3 {
		color:#b10019;
		font-size:16px;
		font-weight:bold;
		margin:20px 0 5px 0;
	}
	.column-address {
		float:left;
		margin-right:50px;	
	}
	.column-billing-address {
		clear:left;
	}
	.customer-support {
		margin-top:40px;
	}
	.customer-support span, .customer-support a, .emphasise {
		color:#b10019;
		font-weight:bold;
	}
	.customer-support a:hover {
		text-decoration:underline;
	}
	h1 {
		padding: 32px 0;
		color: $black;
	}
	padding: 0 85px;
	b {
		font-family:$demibold-font-stack ;
	}	
	p {
		line-height:19px;
		margin:0 0 10px 0;
	}
	.column-address.payment-details {
		max-width:none;
	}
	.payment-details {
		clear:left;
		float:none;
		margin:0;
		padding-top:1px;
	}
	
	.heading {
		clear:both;
		font:bold 20px sans-serif;
		margin:0 0 20px 0;
		padding:5px 0 4px 6px;
	}
	.column-address .loading {
		line-height:16px;
		margin-top:15px;
	}
	.column-address .loading img {
		margin-right:8px;
	}
	.column-create-giftdirect {
		
		width:224px;
		top:22px;
		left:225px;
		margin:0;
	}
	#send-gift-card {
		background-color:#F5F5F5 !important;
		border:2px solid #b10019;
		float:right;
		margin-left:80px;
		padding:10px 5px;
		width:210px;
	}
}

.display-gift-card {
	.bg-card-top {
		background:no-repeat 0 0;
		min-height:100px;
	}
	.bg-card-bottom {
		background:no-repeat 0 bottom;
		height:10px;
	}
	.cover-image {
	display:inline;
	float:right;
	margin: 60px 50px 0 0;
	width:117px;
	}
	.cover-image p {
		font-weight:bold;
		line-height:normal;
		margin:15px 0 10px 0;
		text-align:center;
	}
	.cover-image img {
		border:none;
		cursor:default; 
		display:block;
		float:none;
		margin:0 0 5px 0;
		padding:0;
	}
	.white p, .white pre {
		color:#fff;
	}
	.black p, .black pre {
		color:$black;
	}
	.red p, .red pre {
		color:#f00;
	}
	.green p, .green pre {
		color:#2CBB2B;
	}
	.indigo p, .indigo pre {
		color:indigo;
	}
	.cornflowerblue p, .cornflowerblue pre {
		color:cornflowerblued;
	}
	.brown p, .brown pre {
		color:#3b1d01;
	}
	.card {
		clear:both;
		padding:0px 0;
		width: 800px;
	}
	.card pre {
		font:bold 13px sans-serif;	
		float:left;
		margin: 89px 0 0 100px;
		width: 485px;
		white-space:-moz-pre-wrap;
		white-space:-pre-wrap;
		white-space:-o-pre-wrap;
		white-space:pre-wrap;
		word-wrap:break-word;
	}
	.ie6 .card pre {
		margin:55px 0 0 50px;
	}
	.clearfix:before, .clearfix:after, .form li:before, .form li:after { 
		content:""; display:table; 
	}
	.clearfix:after, .form li:after { 
		clear:both; 
	}
	.clearfix, .form li { 
		zoom:1; 
	}
	.preview-card {
		margin:0 auto;
		border: 1px solid #bfbfbf;
	}
	
	.required {
		color:#999;
		font-size:11px;
		font-weight:bold;
		margin:0;
	}
	h1, h2, h3, h4, h5, h6 {
		margin:0;
	}
	p {
		line-height:19px;
		margin:0 0 10px 0;
	}
	
	.heading {
		clear:both;
		font:bold 20px sans-serif;
		margin:0 0 20px 0;
		padding:5px 0 4px 6px;
	}
	h3 {
		color:#b10019;
		font-size:16px;
		font-weight:bold;
		margin:20px 0 5px 0;
	}
	
	h1 {
		padding: 32px 0;
		color: $black;
	}
	b {
		padding: 0 85px;
		font-family:$demibold-font-stack ;
	}	
	
	
	label {
		width:200px;
		padding: 4px 6px;
		float: left;
		height: 25px;
	}
	input {
		width:268px;
		height: 25px;
		padding: 4px 6px;
		float: left;
	}
	.postcode {
		width:158px;
	}
	.multichoice p {
		margin-top:20px;
	}
	
}

@media screen and (max-width: 650px) {
	.gift-card {
		padding: 0;
		
		input:not(.hasDatepicker), select, textarea, label {
			width: 100%;
			float: none !important;
			clear: both !important;
		}
		.buttons.clearfix.giftbtn {
			margin-top: 20px;
		}
		
		.gift-card-settings, #giftcard-slider {
			width: 100% !important;
		}
		
		.button {
			float: none !important;
			display: inline-block;
		}
		.button-next {
			text-align: center;
			
			span {
				display: inline-block;
				float: none !important;
			}
		}
		.gift-card-settings {
			margin-left: 0px !important;
		}
		
		.gift-card-message.editmessage {
			width: 100%;
			padding: 0;
			
			textarea#message {
				display: inline-block;
				min-height: 293px;
				margin-top: 12px;
				padding: 4px 15px;
				width: 91%;
			}
			.cover-image {
				display:inline-block;
				float: none;
				width:100%;
				margin: 0 0 10px;
				
				img {
					margin: auto;
				}
			}
		}
	}
}