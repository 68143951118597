.search-autocomplete {
	border-bottom: 1px solid $grey-border-colour;
	border-top: 0 ;
	padding: 5px !important;

	li {
		height: 70px;
		border-top: 1px dotted $grey-border-colour;
		padding: 5px 0;
		line-height: 70px;
		
		a {
			float: left;
		}
		img {
			height: 60px;
			width: 49px;
			float: left;
		}
		.autocomplete-label {
			float: left;
			padding-left: 4px;
			font-size: 15px;
		}
	}
} 
.ui-autocomplete.search_variant0 {
	max-width: 290px;
}

.search-results {
	padding: 32px 85px;
	overflow: hidden;

	h1 {
		color: $black;
		font-size: 40px;
		line-height: 45px;
	}
	
	.result-description {
		font-size: 22px;
		line-height: 24px;
		padding: 20px 0 5px 0;
	}
	
	.magazine {
		background-color: $white;
		border: 1px solid $grey-border-colour;
		padding: 25px;
		overflow: hidden;
		margin: 15px 0;
		float: left;
		width: 878px;
		
		.lhs {
			float: left;
			width: 25%;
			text-align: center;
			vertical-align: top;
			
			img {
				width: 80%;
			}
			
		}
		
		.rhs {
			float: right;
			width: 75%;
			
			.price {
				clear: both;
				padding-top: 25px;
				font-size: 22px;
				line-height: 25px;

				.label {
					font-family: $demibold-font-stack;				
				}
				.saving {
					color: $red;
				}
			}

			.searchDescription {
				h3 {
					span.search-match {
						background: none;
						padding: 0;
					}
				}
				span.search-match {
					background: #6ADA52;
					padding: 3px 0;
				}

				p {
					padding: 5px 0;
				}
			}
		}
	}
	
	.magazine:not(.outOfStockOrder) {
		order: 0;
	}
	.magazine.outOfStockOrder {
		order: 1;
	}
}
@media screen and (max-width: 650px) {
	.search-results {
	
		.magazine {
			padding: 25px 5px;
			
			.lhs {
				width: 40%;
				display: inline-block;
				
				img {
					width: 80%;
				}
				
			}
			
			.rhs {
				width: 58%;
				display: inline-block;
			
				.spec {
					border-top: 0px solid;
					margin-top: 0px;
					line-height: 17px;
					
					.frequency, .perfect-for {
						border-right: 0px solid;
						padding: 2px 0;
						float: none;
					}
				}
				.price {
					padding-top: 0;
					
					.label, .saving {
						display: inline-block;
					}
				}
				
			}
		}	
	}
}