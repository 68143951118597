.branded-header {
	height: 45px;
}
.standalone-header {
    padding: 28px 20px;
    position: relative;
}
.subsTab_container.Bundle_container {
	
	.topTabs_tab {
		width: 100%;
		font-size: 14px;
		margin-top: -6px;
		margin-bottom: 10px;
		display: inline-block;
		
		.view-subsTab {
			text-align: left;
			display: inline-block;
			padding: 0;
			position: relative;
			box-sizing: border-box;
			cursor: pointer;
			margin: 10px 5px 0;
			overflow: visible;
			
			p {
				font-size: 15px;
			}
		}
		
		.view-subsTab.active {
			background: none!important;
			
			p {
				color: $red;
			}
		}
	}
	
	.subsTab {
		overflow: hidden;
		padding: 38px 9%;
		padding: 18px 0 30px;
	}
	
	.choose-subsTab.active {
		display: block !important;
		border: 0px solid $grey-border-colour;
		position: relative;
	}
}

@media screen and (max-width: 650px) {
	.subsTab_container.Bundle_container {

		.container, #preheader-wrapper {
			width: 100% !important;
		}
	}
}