.tesco, .single_issue_create, .paypal#cascadeaccordion-container, .prepaidCheckout {
	
	footer.container {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
	}
	
	.cascadeaccordion {
		padding: 10px!important;
		width: 98%;
		
		input[type="text"]:not(.country-field) {
			display: block;
			width: 100%;
			padding: 0.375rem 0.75rem;
			font-size: 1rem;
			font-weight: 400;
			line-height: 1.5;
			color: #212529;
			background-color: #fff;
			background-clip: padding-box;
			border: 1px solid #ced4da;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			transition: border-color .15s
		}
		
		.panel-detail {
		
			p {
				margin: 13px 0;
			}
		}
		
		.panel-continue {
			float: none;
			margin: 0 auto;
		}
		
		.panel-detail-footer {
			width: 100%;
		}
		
		.panel-title {
			font-size: 22px;
			padding: 9px 0;
		}
	}
}