header, nav, .mobileShow {
	display: none!important;
}
.home-link {
	text-decoration: none;
}
.campaign-header {
	.top-layer {
		width: 100%;
	}
	.sold-count {
		width: 100%;
	}
}
.bundle-container-main {
	
	#pdpPromCode {
		margin-top: 40px;

		.large.read-more {
			display: none;
		}
	}

	.feefo_header {
		line-height: 35px;
		text-align: center;
		font-size: 40px;
		padding: 50px 0 30px;
		margin-top: 0px;
	}
	
	#desktop_banner {
		margin-top: 40px;
		margin-bottom: 10px;
	}
	hr + .lhs {
		padding-top: 35px;
	}
	
	.magazine-header:not(.standaloneFooter) {
		padding: 13px 13px 0;
		border: 0px solid;
		border-color: #c0c0c0;
		height: auto;
	}
	.country-select-holder {
		.country-select {
			
			.flag {
				height: 20px;
			}
		}
		.country-field {
			height: 30px!important;
			width: 193px !important;
			font-size: 14px !important;
			background: url(/images/selectbg.webp) no-repeat right #fff;
		}
	}
	.standalone-product {
		
		.standaloneHeadline {
			padding-bottom: 20px;
		}
		
		.standaloneTextRow {
			td {
				background: $light-background-colour-block;
			}
		}
			
		.includeText {
			width: auto;
			height: 315px;
			background: $light-background-colour-block;
			
			&:before {
				border-bottom-color: $light-background-colour-block;
			}
			
			p {
				text-align: left;
			}
			
			#product_descriptionsubtext {
				p {
					img {
						height: auto;
						width: 100%;
					}
				}
			}
		}
		
		.includeText.digitalInside {
			height: 395px!important;
			
			li {
				padding: 10px 0;
			}
		}
		.includeText.whySubscribe {
			height: 365px!important;
		}
	}
	#whySubscribe-tabs, #insideMagDigital-tabs, #rowtab_1, #rowtab_3 {

		.standaloneFooter, .standalone-product {
			
			.includeText {
				background: $white;
				
				&:before {
					border-bottom: 15px solid $white;
				}
			}
		}
	}
	#rowtab_1, #rowtab_2, #rowtab_3 {
	
		.includeText {
			min-height: 315px;
			height: auto;
		}
	}
	.choose-subsTab.rowtab {
		margin-top: 30px;
	}
	.standaloneFooter {
		margin-bottom: 0px;
	}
	.magazine-header {
		h2, h3, h3 + div, p {
			width: 100%;
			text-align: center;
		}
		h2 {
			padding: 10px 9px;
		}
		h2, h2 div {
			line-height: 30px;
		}
		p {
			color: $black;
		}
		.subheaderBundle {
			h4, h5, h6, p, div {
				font-family: $demibold-font-stack;
				font-size: 18px;
				line-height: 22px;
				text-align: center;
			}
		}
	}
	#pageheadline, #brandedHeader {
		padding: 20px 0 0;
	}
	#singleIssueHeadline, #pageheadline.standaloneHeadline {
		padding: 0px;
	}
	#pageheadline, #brandedHeader, #singleIssueHeadline {
		color: $black;
		width: 100%;
		text-align: center;
	}
	#subscriptioncontainer {
		position: relative;
		background: none;
		
		.mysubscriptionpanel.offerNoShow {
		
			.rhs p, .lhs, .compareSubs {
				display: none;
			}
			
			h2 {
				padding-top: 50px;
			}
			.body {
				min-height: 350px;
			}
		}
		
		.mysubscriptionpanel {
			
			.large{
				font-size: 30px;
				position: relative;
				
				#rhs-mag-cover, .rhs-mag-cover{
					width: 45%;
					height: auto;
					margin: 0 auto 20px;
					display: inline-block;
					vertical-align: bottom;
					
					&:nth-child(1) {
						width: auto;
						max-height: 170px;
					}
					
					&:nth-child(2) {
						width: 39%;
					}
				}
				.bubble {
					position: absolute;
					width: 60px;
					height: 60px;
					border-radius: 34px;
					color: #fff;
					z-index: 2;
					display: table;
					table-layout: fixed;
					padding: 4px;
					background-color: #db071a;
					top: 95px;
					left: 151px;
					
					p {
						font-size: 18px;
						line-height: 20px;
						padding-top: 9px;
					}
				}
			}
		
			.red-text.ppiText {
				font-size: 20px;
				font-weight: 600;
			}
			
			.billedAt {
				font-size: 14px;
			}
			
			.top-part {
				background-color: $white;
				border-bottom: none;
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
				overflow: visible;
				width: 100%;
				padding-top: 25px;
				float: left;
				
				.centred {
				
					p.centred {
						min-height: 75px;
						margin-top: 5px;

						strong.redText {
							font-size: 14px;
						}
					}
				}
				
				#product_ticks-digital {
					margin-bottom: 20px!important;
				}
				#product_ticks-bundle {
					margin-bottom: 19px!important;
				}
				#product_ticks_si {
					visibility: hidden;
				}
				.editable_ticks.print-ticks {
    
					ul {
						li {
							
							&::before {
								content: "\f058";
								font-family: "Font Awesome 6 Free";
								font-weight: 900;
								color: #009e11;
								padding-right: 7px;
							}
						}
					}
				}
				.editable_ticks.digital-ticks {
    
					ul {
						li {

							&::before {
								content: "\f058";
								font-family: "Font Awesome 6 Free";
								font-weight: 900;
								color: #009e11;
								padding-right: 7px;
							}
						}
					}
				}
				
				.tick_box {
					margin: 5px auto 20px;
					display: none;
					min-height: 220px;
    
					ul {
						li {
							padding: 9px 0px 0px 5px;
							background-size: 15px!important;
							background-position: 0px 13px!important;
							text-align: left;
						}
					}
    
					ul.print-ticks {
						li {

							&::before {
								content: "\f058";
								font-family: "Font Awesome 6 Free";
								font-weight: 900;
								color: #009e11;
								padding-right: 7px;
							}
						}
					}
    
					ul.digital-ticks {
						li {

							&::before {
								content: "\f058";
								font-family: "Font Awesome 6 Free";
								font-weight: 900;
								color: #009e11;
								padding-right: 7px;
							}
						}
					}
				}
			}	
			
			.asa, .compareSubs, .seeInside {
				clear: both;
				background: $white;
				padding: 12px 0;
				border-top: none;
				border-top-left-radius: 0;
				border-top-right-radius: 0;
				cursor: pointer;
			}
			.seeInside, .compareSubs {
				width: 40%;
				display: inline-block;
			}
			.asa {
				width: 100%;
			}
			img.compareImage {
				transition: 0.15s ease-in-out all;
				height: 13px;
				vertical-align: middle;
				padding-left: 3px;
			}
			img.rotateCompare {
				transform: rotate(180deg);
				margin-left: 3px;
				margin-top: -4px;
			}
			img.seeInsideImage {
				padding-left: 3px;
			}
			.body {
				padding: 25px 25px 0;
				overflow: visible;
				min-height: 450px;
	
				.productRating {
					position: relative;
					right: 0;
					top: 0;
					display: none;
				}
			} 
			.rhs.gift-yes {
				img {
					width: 80%;	
				}	
			}
		}

		#mysubscriptionpanel-bundle {
		
			#rhs-mag-cover, .rhs-mag-cover {
			
				&:nth-last-child(1) {
					margin-top: 16px;
					width: 25%;
				}
			}
		}
	}
	.bundle-container {
		background: $very-light-background-colour;
		padding: 20px 10px;
		margin: -33px 10px 35px!important;
		
		p.bestValue {
			margin-bottom: 10px;
		}
	}
			
	#pricingblock, #pricingblock-bundle, #pricingblock-digital {
		form {
			display: none;
		}
		.pricing-container {
			margin: 10px 0 ;
			position: relative;
			z-index: 6;
		}		
		.pricing-block {
			background-color: $white;
			cursor: default;
			width: 100%;
			
			.block {
				text-align: left;
				border: 1px #c2c2c2 solid;
				padding: 5px 35px 5px 9px ;
				background: url('/images/selectbg-large3.webp') no-repeat right;
			
				.pricing {
					p {
						strong {
							span {
								display: block;
							}
						}
					}
					.price-statement, .price-per {
						.PricingBlockHide {
							display: none;
						}

						span.badge {
							font-size: 14px;
						}
					}
				}
			}
		}	
		.pricing-block.expanded {
		
			.block {
				border-radius: 0;
				border-top: none ;
				background: none;
				
				&:first-child {
					border-top: 1px #c2c2c2 solid ;
				}	
				
				&:hover {
					background-color: #f2f2f2;
				}
			}
			.block.chosen {
				background: url('/images/tick.webp') no-repeat 95% 50%;		
			}
		}
	}
	.subscription {
		text-align: center;
		padding-top: 0px;
		
		h3 {
			font-size: 16px;
		}
		.subscription-column {
			width: 30%;
			display: inline-block;
			padding-top: 12px;
			margin: 0 auto 35px;
			vertical-align: top;
		}
		.print-container, .si-container {
			float: left;
		}
		.digital-container {
			float: right;
		}
	}
	.ourPromise {
		margin-top: 25px;
		margin-bottom: 35px;
	}
	.smallerPromises {
		height: auto;
		margin-top: 0px;
		text-align: center;
		background: $white;
		
		.promise {
			width: 24%;
			padding-top: 0;
			float: none;
			display: inline-block;
			background: none!important;
			
			h3 {
				width: 100%;
				font-size: 18px;
				text-align: center;
				padding: 16px 0;

				i {
					font-size: 35px;
					display: inline-block;
					vertical-align: middle;
					text-align: center;
					color: $red;
					width: 40px;
				}

				i.promiseCost {
					font-size: 26px;
				}

				i.promiseCancel {
					font-size: 37px;
				}

				span {
					display: none;
				}
			}
		}
	}

	#ourPromise-tabs {
		.smallerPromises {
			display: block;
			width: 99.8%;
		}
	}
	.promise {
		.tt-info, .tt-info:hover {
			width: auto;
			height: auto;
			background: none!important;
			border: 0px solid;
		}
		
		.tt-info:hover .more-info {
			width: 200px;
			left: 12%;
			top: -65px;
		}
	}
	
	.faqs_container {
		margin-top: 40px;
	
		.FAQ_header {
			line-height: 35px;
		}
		.view-faq.active {
			background: $light-background-colour-block;
			
			.hider {
				background: $light-background-colour-block;
			}
		}
		.faqs {
			background: $light-background-colour-block;
		}
	}
}
@media screen and (max-width: 1100px) {
	.bundle-container-main {
		
		.manage_subs, .mediumHide {
			display: none!important;
		}
		.navigationevent {
			text-transform: capitalize;
		}
		.search-box {
			margin-top: 13px!important;
		}
		.navigation {
			padding: 0 5px;
			
			ul {
				border-left: 0px solid;
				
				li {
					padding: 10px 13px;
					font-size: 15px;

					.megamenu {
						width: 100%;
						height: auto;
						padding: 32px 0px;
						
						.category-box, .atoz-mega-box {
							margin: 0 0 25px 25px;
							
							.wholebox {
								line-height: 18px;
								font-size: 18px;
							}
						}
						.category-box.white, .atoz-mega-box.white {
							width: 158px!important;
							height: 104px!important;
						}
					}
					#atoz-mega-menu {
						.atoz-mega-box.white {
							width: 100%;
							height: 60px;
						}
					}
				}
			}
		}
		.site-header {
			padding: 0 0px !important;
			
			img {
				margin-top: 10px;
			}
			
			button {
				img {
					margin-top: 0px;
				}
			}
		}
		.sitemap {
			padding: 35px;
		}
		.full-width {
			width: 95%;
		}
		.ui-accordion .ui-accordion-icons {
			padding-left: 0px;
		}
		
		.faqs_container {
			margin-top: 25px!important;
			
			.faqs {
				width: 90%;
				padding: 38px 4%;
			}
		}
		.red-text.price-statement {
			padding-right: 29px;
		}
		.country-select-holder {
			p {
				display: none!important;
			}
		}
		.most-popular, .people-liked, .you-viewed {
			overflow: hidden;
			padding: 18px 10px 0;
		}
		.FAQ_header {
			padding-bottom: 15px!important;
			line-height: 45px;
		}
		.faqs_container .choose-faq.active .accordion .ui-state-active .ui-icon, 
		.faqs_container .choose-faq.active .accordion .ui-state-default .ui-icon {
			left: 94% !important;
		}
		.standalone-product {
			
			.standaloneIncludeText {
				width: 90%!important;
			}
			
			.standaloneInclude {
				td {
					vertical-align: inherit;
					max-width: 238px;
				}
			}
			.includeText {

				&:before {
					left: 44%!important;
				}
			}
			.includeText.digitalInside, .includeText.includeText.whySubscribe {
				height: 515px!important;
			}
		}
		.subsTab_container.Bundle_container {

			.subsTab_tab.footerNav {
				width: 98%;
				
				.view-subsTab {
					margin: 0 1%;
				}
			}
		}
		#subscriptioncontainer {
			
			.bundle-container {
				margin-top: -17px!important;
				
				.mysubscriptionpanel {

					.top-part {
						border: 0px $black solid;
						padding-top: 8px;
						
						.large {
							font-size: 24px;
						}
					}
							
					.compareSubs {
						border: 0px $black solid;
					}
					.body {
						padding: 25px 10px 0;
						
						.large {
							
							#rhs-mag-cover, .rhs-mag-cover {
								width: 44%;
							}
						}
					}
				}
			}
			
			.subscription-column {
				
				.mysubscriptionpanel {
					.body {
						padding: 25px 10px 0!important;
						
						.large {
							
							#rhs-mag-cover, .rhs-mag-cover {
								&:nth-child(1) {
									max-height: initial;
									width: 51.5%;
								}
							}
						}
					}
						
					.top-part {
						.tick_box {
							height: 735px;
						}
					}
				}
			}
		}
				
		#pricingblock, #pricingblock-bundle, #pricingblock-digital {
					
			.pricing-block {
				
				.block {
					font-size: 14px;
					padding: 5px 9px ;
				}
			}
		}
		.subscription {
			
			h3 {
				padding: 10px 0;
			}
			.subscription-column {
				width: 28%;
			}
		}
		.ourPromise {
			margin-top: 5px;
		}
		.promise {
			
			h3 {
				
				span {
					margin-right: 10px;
				}
			}
			
			.tt-info:hover .more-info {
				width: 150px;
				left: 1%;
				top: -58px;
			}
		}
	}
		.our-promises {
			.full-width {
				width: 100%;
	
				.promise {
					width: 30%;
					
					p, h3 {
						width: auto!important;
					}
				}
			}
		}
	.most-popular, .people-liked, .you-viewed {
		padding: 50px 25px;
		
		.categoryblock {
			width: 100%;
			text-align: center;
			
			.magazine {
				float: none;
				margin: 0 25px;
				display: inline-block;
			}
		}
	}
}
@media screen and (max-width: 1100px) and (orientation: portrait) {
	.red-text.price-statement {
		margin: 0 auto;
		width: 75%!important;
		padding-right: 0!important;
	}
}
@media screen and (max-width: 750px) {
	.container {
		width: 100%;
	}
	body:not(.bootstrap) {
		.bundle-container-main {
			.container {
				width: 100%!important;
			}
		}
	}
	.standalone-product {
		.labelled {
			padding-left: 0px;
			padding-right: 0px;
			margin-bottom: 0px;
			
			.add-to-basket-standalone {
				text-align: center;
				
				.button {
					margin-left: auto;
					margin-right: auto;
				}
			}
				
			p {
				width: auto;
			}
				
			.toggle-next {
				width: auto;
			}
			p.smallerPrice {
				strong {
					padding-left: 5px;
				}
			}
			
			.smallprint {
				margin: 0 auto;
				width: 93%;
			}
			
			#product_boxadditionalwords {
				background-color: $light-background-colour;
			}
		}
	
		.includeText {
			min-height: auto!important;
		}
		
		.standaloneCovers {
			margin: 8px 0;
			padding: 0;
		
			.large {
				width: 100%;
				float: none;
				margin: 0 auto;
			
				#mag-cover {
					height: 85%;
					width: 85%;
					display: block;
					margin: 0 auto;
				}
			}
		
			.large.one-offImage {
				width: 100%! important;
				float: none! important;
				margin-left: 0! important;
			}
			
			.choose-offer {
				float: none;
				width: 100%;
				margin-left: 0px;
				border: 1px solid;
				margin-top: 20px;
				
				.price {
					line-height: 35px;
					font-size: 35px;
				}
			}
		}
	}
	header, nav {
		display: block;
	}
	nav {
		height: 52px;
		width: 100%!important;
		border: 1px solid #B4B4B4;
		
		.navigation {
			overflow: visible;
			position: relative;
			padding: 0px;
			
			.search-box {
				position: relative ;
				
				#magnify, #navbar-toggler {
					position: absolute;
				}
				#magnify {
					top: 18px;
					right: 37px;
				}
				
				#navbar-toggler {
					top: 12px;
					left: 12px;
					z-index: 1;
					
					span {
						color: #767676;
						font-size: 14px;
						font-weight: 500;
					}
					span, img {
						vertical-align: middle;
					}
				}
				
				input {
					border: 1px solid $grey-border-colour;
					padding: 0 30px 0 25px;
					margin: 7px 12px;
					width: 94%;
					height: 38px;
					outline: none;
					float: right;
				}
				input.menu-open {
					border-bottom: 0px;
				}		
			}
		
			ul.collapsableMenu {
				display: none;
				position: absolute;
				background: $white;
				box-shadow: -5px 5px 5px 0 $black;
				width: 100%;
				z-index: 5;
				top: 0px;
				right: 0px;
				border-right: 0px solid;
				max-height: 550px;
				overflow: scroll;
				
				li {
					border-left: 1px solid $grey-border-colour;
					padding: 10px 9px;
					float: none;
					
					&:nth-child(1) {
						border-left: 0px solid $grey-border-colour;
					}
					
					.expandImage {
						float: right;
						transition: 0.15s ease-in-out all;
					}
					.rotateExpand {
						transform: rotate(90deg);
					}
					
					ul {
						display: none;
						float: none;
						padding-top: 3px;
						margin: 7px -10px 0;
						border-top: 1px solid $grey-border-colour;
						background: $light-background-colour-block;
						box-shadow: inset 1px -5px 10px 0px #08080824;
						
						li {
							border-left: 0px solid;
							padding-left: 20px;
					
							&:nth-child(1) {
								border-left: 0px solid;
							}
						}
					}
				}
			}
		}
	}
	header {
		height: 70px;
		background: $very-light-background-colour url(/images/mobile-nav-bg.webp) no-repeat;
		
		.site-header {
			padding: 0;
		}
		
		.your-basket, .your-basket-mobile, .search {
			float: right;
		}
		
		.your-basket, .your-basket-mobile {
			padding: 20px 12px 0 0;
			
			a {
				text-decoration: none;
				color: $red;
				font-size: 16px;
			}
		}
	}
	.search-autocomplete {
		li {
			height: 60px;
			clear: both;
			line-height: 20px;
		}
	}
	.top-header.bundle-container-main, .nav.bundle-container-main {
		display: none;
	}
	.bundle-container-main {

		.magazine-header:not(.standaloneFooter) {
			padding: 0;
		}

		.navigation {
			
			ul {
				border-left: 0px solid;
			
				li {
					padding: 10px 17px;
					
					#gift-mega-menu {
						height: 60px;
					}

					.megamenu {
						width: 94%;
						padding: 32px 10px;
						
						.category-box, .atoz-mega-box {
							width: 100px;
							height: 60px;
							margin: 0 0 15px 15px;
							
							.wholebox {
								line-height: 17px;
								font-size: 17px;
							}
						}
						.category-box.white, .atoz-mega-box.white {
							width: 88%;
							height: 35px;
						}
					}
					#atoz-mega-menu {
						.atoz-mega-box.white {
							width: 60%!important;
							height: 60px!important;
						}
					}
					&:nth-last-child(3) {
						border-right: 0px solid;
					}
				}
			}
		}
		#pageheadline, #singleIssueHeadline {
			font-size: 33px;
			line-height: 36px;
		}
		.search-box {
			
			input {
				padding-left: 9px!important;
				width: 175px!important;
				float: left!important;
				font-size: 14px!important;
			}
			
			button {
				right: 0px!important;
			}		
		}
		.az-mega-menu.hidden, .mediumHide, .mobileShow {
			display: block!important;
			margin: 0 auto;
		}
		.standaloneImages, .smallerHide, .your-basket {
			display: none!important;
		}
		.subsTab_container.Bundle_container {
			.subsTab_tab.smallerHide.footerNav {
				width: 94%!important;
				text-align: center!important;
				display: block!important;
				right: 0;
				left: 0;
				margin: 0 auto !important;
				
				.view-subsTab {
					margin-top: 0px !important
				}
				
				span {
					float: none!important;
					display: block;
					
					span {
						display: inline-block;
					}
				}
				.footerNavHide {
					display: block!important;
				}
				.footerNavShow, label.footerNavHide, #digitalInside {
					display: none!important;
				}
			}
		}
		.site-header {
			
			a {
				background: url(/mobile/images/mobile-nav-bg.webp);
				width: 208px;
				height: 60px;
				float: left;
				cursor: pointer;
				margin-left: -8px;
			}
		}
		.sitemap {
			height: auto;

			.column, .column.double {
			
				ul {
					height: auto;
				}
			}
			.column.double {
				width: 100%!important;
				
				ul {
				
					li {
						width: 49%!important;
					}
				}
			}
			.column.three {
				display: none;
			}
		}
		.red-text.price-statement {
			padding-right: 30px;
		}
		.country-select-holder.pull-right {
			float: none!important;
		}
		.country-select-holder {
			p {
				display: block!important;
				text-align: center;
				font-weight: bold;
			}
			.country-select {
				margin: 0 7%;
			}
			.country-field {
				width: 245px!important;
			}
		}
		.standalone-product {
			
			.standaloneInclude {
				td {
					width: 100%!important;
					max-width: 100%!important;
					display: block;
					margin-bottom: 25px;		
				}
			}
			.includeText, .includeText.digitalInside, .includeText.includeText.whySubscribe {
				height: auto!important;
					
				&:before {
					display: none;
				}
			}
			.standaloneIncludeText {
				padding: 0px!important;
				margin: 0 auto;
			}
		}
		#subscriptioncontainer {
			
			.bundle-container {
				margin: 0 1.75% 35px!important;
				padding: 12px 0 0;
				border: 10px solid $very-light-background-colour;
			}
		}
			
		.subscription {
			
			h3 {
				margin-bottom: 0!important;
			}
			.subscription-column {
				width: 100%;
				float: none;
				border: 1px solid $grey-border-colour;
				
				.mysubscriptionpanel {
					float: none;
					
					.top-part {
					
						.centred {
						
							p.centred {
								height: auto!important;
								padding: 0 5px;
							}
						}
					}
					
					.body {
						min-height: 415px!important;
						
						.tick_box {
							height: auto!important;
						}
					}
				}
			}
		}
	}
	.faqs_container {
		.faqs_tab {
			.view-faq.magazine {
				padding: 18px 0 0;
				
				p {
					margin-top: -6px;
				}
			}
			.view-faq {
				p {
					font-size: 23px;
				}
			}
		}
	}
	.our-promises {
		height: auto!important;
		padding-bottom: 25px;

		.promise {
			width: 100%!important;
			float: none;
			
			h3 {
				
				span {
					float: left;
				}
			}
		}
	}
	.most-popular, .people-liked, .you-viewed {
		padding: 50px 25px;
		
		.categoryblock {
			width: 100%;
			
			.magazine {
				width: 140px;
				margin: 0 10px;
				height: 310px !important;
				overflow: visible;

				.magname {
					width: 140px !important;
				}
				
				img {
					width: 140px;
					height: 175px;
				}
				.quickview-button {
					display: none!important;
				}
			}
		}
	}
}
@media screen and (max-width: 700px) and (orientation: landscape) {
	.bundle-container-main {
		.top-header {
			height: 65px;
		}
		.site-header {
			a {
				img {
					width: 370px;
					margin-top: 14px;
				}
			}
		}
		li.smallerHide:not(.mega-menu-trigger) {
			display: none;
		}
		#subscriptioncontainer {
			.bundle-container {
				margin-top: -25px !important;
			}
			.mysubscriptionpanel {
				.top-part {
				
					.centred {
					
						p.centred {
							height: 93px;
							margin-top: 5px;
						}
					}
					.tick_box {
						height: 895px!important;
					}
					.body {
						min-height: 435px;
					}
				}
				.compareSubs {
					font-size: 15px;
				}
			}
		}
		.subsTab_container.Bundle_container {
			.subsTab_tab {
				span {
					display: none;
				}
			}
			.subsTab_tab.footerNav {
				.view-subsTab {
					margin: 0 0.8%;
				}
				span {
					display: block;
				}
			}
		}
		.standalone-product {
			.includeText {
				height: 584px !important;
			}
			.includeText.digitalInside, .includeText.includeText.whySubscribe {
				height: 652px !important;
			}
			.standaloneIncludeText {
				padding: 0px !important;
				margin: 0 auto;
			}
		}
	}
	}
	@media screen and (min-width: 700px) and (max-width: 1100px) and (orientation: landscape) {
	.bundle-container-main {
		#subscriptioncontainer {
			.bundle-container {
				margin-top: -31px !important;
				
				.mysubscriptionpanel {
					.body {
						padding-bottom: 15px!important;
					}
				}
			}
		}
	}
	.standalone-product {

		.standaloneCovers {

			.choose-offer, .large, .small {
				float: none;
				vertical-align: top;
				display: inline-block;
			}

			.choose-offer, .large {
				width: 43%;
				
				p {
					width: auto;
				}
				
				.productRating {
					top: 8px;
				}
				
				.labelled {
					padding-top: 0px;
				}
			}
			.small {
				width: 70px;
			}
		}
	}
}
@media screen and (min-width: 700px) and (max-width: 1100px) and (orientation: portrait) {
	.standalone-product {
	
		.standaloneCovers {
	
			.choose-offer, .large {
				float: none;
				margin: 5px;
				width: 47%;
				display: inline-block;
			}
			.large {
				img {
					height: 100%;
				}
			}
			.small {
				display: none;
			}
			
			.tick_box_nobg {
				
				ul {
					padding-top: 0px;
				}
			}
		}
	}
}
