$promise_tick: url('/images/red-tick.webp');
$step1: '1';
$step2: '2';
$step3: '3';
$step4: '4';
$stepBG: #DB071A;
$stepBorder: 0px solid #Db071A;
$stepColour: $white;
$print_icon: url('/images/print-icon.webp');
$digital_icon: url('/images/digital-icon.webp');
$gift_icon: url('/images/gift-icon.webp');
$giftPadding: 75px!important;
$gift_icon_checkout: url('/images/gift-icon.webp');
$promise1: url('/images/transparent/price.webp');
$promise2: url('/images/transparent/money-back.webp');
$promise3: url('/images/transparent/lorry.webp');
$promise4: url('/images/transparent/cancel.webp');
$checkoutPromise1: url('/images/transparent/checkout/price.webp');
$checkoutPromise2: url('/images/transparent/checkout/money-back.webp');
$checkoutPromise3: url('/images/transparent/checkout/lorry.webp');
$checkoutPromise4: url('/images/transparent/checkout/cancel.webp');
$countrySelect_icon: url('/images/selectbg.webp');
$countrySelectTransparent_icon: url('/images/transparent/selectbg.webp');
$countrySelectFont: $normal-font-stack;
$countrySelectText: initial;
$countrySelectColour: rgb(33, 40, 44);
$countrySelectSpacing: normal;
$blue-flash-height: 110px;
$checkout_logo: url('/images/md-and-time-logo-2.webp');
$checkout_logo_mobile: url('/images/md-and-time-logo-2.webp');
$magazineShadow: 2px 1px 3px #770000;
$borderRadius: 6px;
$tooltipBorderRadius: 10px;
$sitemap: url(/images/footer-background.webp);