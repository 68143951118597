.categories_container {
    max-width: 1500px;
    width: 100%;
    margin: 0 auto 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .category_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 45px;
        width: 100%;

        &:nth-child(odd) {
            background: $mid-grey-background;
        }

        .category_header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 45px 38px 32px;

            .category_title {
                font-optical-sizing: auto;
                font-size: 25px;
                font-style: normal;
                font-weight: 700;
                line-height: 32px;
            }

            .category_view_all {
                color: $primary-color;
                cursor: pointer;
                text-align: center;
                font-optical-sizing: auto;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                text-decoration-line: underline;
            }
        }
    }

    .product_container {
        display: flex;
        justify-content: center;
        align-items: start;

        .category_product {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 250px;
            height: auto;
            flex: 0 0 auto;

            .category_product_image {
                width: 173px;
                height: 220px;
            }

            .category_product_title {
                color: $primary-color;
                text-align: center;
                margin: 0 auto;
                width: 173px;
                font-size: 14px;
                line-height: 20px;
                text-decoration: none;
                font-weight: 500;
                height: 40px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: pre-wrap;
                margin-top: 5px;
            }

            .category_product_price {
                text-align: center;
                color: $red;
                font-size: 15px;
                font-weight: normal;
                line-height: 24px;
                font-optical-sizing: auto;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .categories_container {

        .category_header {
            padding: 32px 17px !important;

            .category_title {
                font-size: 20px !important;
                line-height: 24px !important;
            }

            .category_view_all {
                font-size: 15px !important;
                line-height: 19px !important;
            }

        }

        .product_container {

            .category_product {
                width: 50% !important;
                
                .category_product_image {
                    width: 152px !important;
                    height: 202px !important;
                }
            
                .category_product_title {
                    width: 152px;
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .categories_container {

        .product_container {

            .category_product {
                width: 33.33333333% !important;
            }
        }
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .categories_container {

        .category_container {
            padding-bottom: 0px;

            .product_container {

                .category_product {
                    width: 25%;
                }
            }
        }
    }
}

@media screen and (max-width: 1199px) {
    .categories_container {

        .category_container {

            .product_container {
                flex-wrap: wrap;
                gap: 0px;

                .category_product {
                    flex-wrap: wrap;
                    gap: 0px;
                    height: auto;
                    margin-bottom: 25px !important;
                }
            }
        }
    }
}