.homepage-page-header {
	position: relative ;
	
	img {
		display: block;
	}
	.headline {
		position: absolute;
		top: 50px;
		width: 100%;
		h1 {
			text-align: center;		
		}
	}
}

.header-promises {
	text-align: center; 
	background-color: $light-background-colour;
	padding-bottom: 4px;
	
	ul {
		display: inline-block; 
	}
	
	li {
		background: url("/images/red-tick.webp") no-repeat;
		background-position: 0 2px;
		padding: 0 22px;
		display: inline-block;
		font-size: 16px;
		line-height: 22px;
		font-family: $demibold-font-stack ;	
	}

	li.reviews, li.discount, li.delivery, li.manage {
		font-size: 13px;
		width: 210px;
		padding: 0;
		background: none !important;

		img {
			display: inline-block;
		}
		
		label {
			width: 140px;
			line-height: 16px;
			text-align: left;
			display: inline-block;
			padding-left: 7px;
		}
	}

	li.reviews {
		background: none;
		padding-right: 40px;

		img {
			display: block !important;
			width: 76px;
		}
	}

	li.discount {
		img {
			width: 23px;
		}
	}

	li.delivery {
		img {
			width: 26px;
		}
		i {
			font-size: 24px;
			vertical-align: super;
		}
	}

	li.manage {
		img {
			width: 30px;
		}
	}
	
	.feefo-badge__image {
		height: 35px;
		display: inline-block;
		vertical-align: middle;
		margin-top: -7px;
	}
}

.category-page-header {
	padding: 0 85px;
	position: relative ;
	background-color: $white;
	height: 450px;
	
	.offer-boxes {
		padding: 0 ;
	}
	.background {
		position: absolute;
		top: 0;
		left: 0;	
	}
	
	h1 {
		padding: 32px 0 24px;
		position: inherit;
	}
	
	.controls {
		position: absolute;
		bottom: 7px;
		right: 80px;
		line-height: 65px;
		cursor: pointer;
		
		select {
			float: right !important;
			width: 210px;
			margin-top: 10px;
		}
		.tab {
			width: 155px;
			float: left;
			height: 65px;
			line-height: 62px;
			text-align: center;		
		}
		
		label {
			font-family: $demibold-font-stack ;
			margin: 0 15px 0 30px;
		}
		
		#categories-filters {
			position: absolute;
			top: 47px;
			left: -557px;
			height: 80px;
			border: none;
			padding: 5px 0;
			width: 1100px;
			background: none;
			
			.toptab {
				height: 13px;
				background-color: $very-light-background-colour;
				width: 146px;
				margin-left: 562px;
			}
			
			#buttons {
				overflow: hidden;
				padding: 5px;
				background-color: $very-light-background-colour;
			}
		}
	}
}
body.bootstrap {
	
	.left-column.promises {
		width: 43%;
		padding-left: 50px;
	}
	
	.category-page-header {
		height: auto;
	
		.category-background {
			max-height: 367px;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
		}
		
		.hero_image {
			width: auto;
		}
		
		h1 {
			color: $black;
			font-size: 24px;
			line-height: 25px;
			padding: 25px 0 5px;
		}
		
		p {
			padding-bottom: 20px;
		}
		
		p:not(#browsePagesBar):not(.text-center), p span {
			text-align: left !important;
		}
		
		p.paginationText {
			font-size: 15px;
		}

		p.paginationText:not(.footerPagination) {

			.paginationPageBlockInner {
				padding: 5px 10px;

				a {
					text-decoration: none;
				}

				.currentPage {
					border: 0px solid $off-black-border-colour;
				}
			}
		}
		
		.controls {
			position: relative;
			bottom: 0;
			right: 0;
			cursor: default;
			
			.tab {
				height: auto;
				width: 100%;
				float: none;
				text-align: left;
				background-color: transparent !important;
				
				img.filter {
					height: 100%;
					vertical-align: bottom;
					filter: invert(1);
				}
				
				.filter_icon {
					float:right;
					padding-right: 5px;
					padding-top: 4px;
				}
			
				.button.white {
					width: 100%;
					height: auto;
					color: $white;
					display: block;
					cursor: pointer;
					background: $red;
					border: 0px solid;
					line-height: 25px!important;
				}
			
				#categories-filters {
					left: -535px;
					padding-bottom: 10px;
				
					.toptab {
						margin-left: 554px;
					}
				}
				#categories-filters:not(.megamenu) {
					left: 0;
					top: 35px;
					width: 100%;
					z-index: 9;
					
					#buttons {
						border: 1px solid $medium-grey-border-colour;
						background: $white;
						
						.filterTitle {
							color: $black;
							font-size: 15px;
							padding-top: 4px;
							padding-left: 30px;
							text-transform: uppercase;
						}
						
						hr {
							border-top: 1px dotted $medium-grey-border-colour;
							margin: 9px 0;
						}
						
						.categorybtn {
							font-size: 14px !important;
							font-family: $normal-font-stack;
							font-weight: 300;
							color: $black;
							background: $very-light-background-colour;
							padding: 4px 10px;
							margin: 5px;
							float: left;	
							outline: 0;
						}	
						.activeFilters {
							background: $black;
							color: $very-light-background-colour;
						}
					}
				}
			}

			.tab.a-zFiltering {

				#buttons {
					border:0px solid !important;
				
					.filterTitle {
						display: none;
					}

					.col-12:not(.filterTitle) {
						flex: 0 0 auto !important;
						width: 100% !important;
						text-align: center;

						fieldset {
							display: flex;
							flex-wrap: wrap;
							align-items: center;
							justify-content: center;
						}
					}
				}
			}
			
			select {
				float: none!important;
				line-height: 20px;
			}
		}
	}
	.best-sellers {
		.categoryblock {
			max-width: 1500px;
			width: 100%;
			
			.magazine {
				text-align: center;
				
				.magname {
					white-space: normal;
				}
			}
		}
	}
	.standalone-product {
		table.BoxOuts {
			.standaloneInclude {
				img:not(.lookInsideArrow) {
					max-width: initial;
				}
			}
		}
	}
	.magazine-categories {
		padding: 32px 0px;
		
		.category-box {
			margin: 0 0 26px 26px;
		}
	}
	.lhs {
	
		.category-box {
	
			.wholebox {
				padding: 0 5px;
			}
		}
	}
	.rhs {

		.category-box {
			width: 218px;
			height: 218px;
			margin-left: 0;
			
			&:first-child {
				margin-bottom: 25px!important;
			}
		
			a.button {
				height: 35px !important;
			}
			
			h3, p {
				color: $black;
			}
		}
	}
	.offer-boxes {
		width: 100%!important;
		padding: 32px 50px;
		
		.offer-box {
			.bottom-part {
				.magname:not(.cartwallTitle) {
					height: 72px;
				}
			}
		}
	}
}

.seo-header {
	font-family: $normal-font-stack;
	font-weight: 300;
	padding: 32px 120px 0 120px;
	overflow: hidden;

	h1 {
		color: $black;
	}	
}

.categorybutton {
	background-color: $very-light-background-colour;
	font-family: $normal-font-stack;
	font-weight: 300;
	border: 1px solid $grey-border-colour;
	color: $black;
	background-image: url(/images/cross.webp);
	background-repeat: no-repeat;
	padding-left: 32px;
	margin-bottom: 12px;
	margin-left: 12px;
	background-position: 4px;
	float: left;			
}		
.categorybutton.ticked {
	background-image: url(/images/tick.webp);
}


.best-sellers {
	padding: 32px 85px;
	overflow: hidden;

	h2 {
		text-align: center;
		padding-bottom: 32px;
	}
}


.magazine-categories {
	padding: 32px 85px;
}
.guide-categories {
	padding: 32px 75px;
}
.magazine-categories, .guide-categories {
	overflow: hidden;
	
	h2 {
		text-align: center;
		padding-bottom: 32px;
	}
	.reducedPaddingBottom {
		padding-bottom: 15px;
	}
	
	.increasedPaddingBottom {
		padding-bottom: 32px;
	}
	
	.categories {
		width: 660px;
	}
	.category-box {
		width: 180px;
		height: 120px;
	}
	.guide-boxes {
		width: 160px;
		height: 105px;
	}
	.guide-boxes:nth-child(5n+1) {
		margin-left: 0;
		clear: left;
	}
	
	.rhs {
		
		.category-box, .guide-boxes {
			width: 208px;
			height: 208px;
			margin: 0;
			padding: 15px 15px 0 15px;
			
			* {
				padding-bottom: 8px;
				line-height: 22px;
			}
		}
		
		.category-box:first-child, .guide-boxes:first-child {
			margin-bottom: 34px;
		}
	}
	
}
.look-inside {
	position: absolute;
	cursor: pointer;
	right: 0;
	bottom: 0;
	height: 40px;
	font-family:$demibold-font-stack;
	font-size: 18px;
	padding: 12px 16px 0 16px;
	@include translucentbg(#ffffff, 0.9) ;
}

.singleIssueLookInside {
	right: 0px;
	bottom: 7px;
	
	p {
		width: auto!important; 
		padding-right: 5px;
	}
	
	p, img {
		display: inline-block;
		vertical-align: middle;
	}
}
.singleIssueLookInside.mobileShow {
	right: -1px;
	top: 336px;
}

.newNormalLookInside {
	right: 60px;
    bottom: 14px;
	
	p {
		padding-top: 4px;
		line-height: 25px;
	}
	
	p, img {
		float: left;
		width: auto!important; 
		height: auto!important; 
		padding-right: 5px;
	}
}

.magazine-header {
	height: 370px;
	background-color: $white;
	position: relative;
	
	.background {
		position: absolute;
		height: 370px;
		width: 100% ;
		max-width: 1100px;
		top: 0;
		left: 0;
	}
	
	h1:not(.FAQ_header):not(.standaloneHeadline):not(#pageheadline) {
		padding: 20px 0 0 80px;
	}
	
	h1 {
		font-family: $demibold-font-stack ;
		font-weight: normal;
		color: $white;
		font-size: 40px;
		line-height: 45px;
		width: 100%;
		position: relative;
	}
	
	.covers {
		position: absolute ;
		height: 288px ;
		left: 80px;
		bottom: -90px;
		z-index: 1;

		.small {
			width: 64px;
			float: left;

			img {
				height: 87px;
				box-shadow: $magazineShadow;
			}
		}
		.arrow {
			position: absolute;
			top: 261px;
			left: -28px;
			height: 158px;
			width: 24px;
			
			img {
				height: auto!important;
				box-shadow: 0 0 0 $white!important;
			}
		}
		.arrow.right {
			left: 445px;
		}
		.large:not(.col-12) {
			float: right;
			margin-left: 10px;
			position: relative ;
			width: 220px;
			height: 275px;

			> img {
				box-shadow: $magazineShadow;
				width: 100% ;
				height: 100% ;
			}
			.feefoStars {
				margin: 0px 11px;
			}			
			
		}
		.large.col-12 {
			> img {
				width: 100% ;
			}
		}
		
	}
	
	.whatsInside {
		width: 90%;
		margin: 45px auto;
		z-index: 1;
		clear: both;
		
		.smallRight, .smallLeft {
			width: 405px;
			float: left;
			
			div {
				height: 93px;
				width: 355px;
				margin: 15px 10px;
				padding: 10px 15px;
				border: 1px solid $medium-background-colour;
				
				&:hover {
					background-color: $medium-background-colour;
				}
			}
			
			img {
				height: 87px;
				box-shadow: $magazineShadow;
			}
		}

		.largeLeft, .largeRight  {
			margin-left: 10px;
			position: relative ;
			width: 570px;
			height: 390px;
			
			> img {
				width: 100% ;
				height: 100% ;
			}
			
		}
		.largeRight {
			float: left;
		}
		.largeLeft  {
			float: right;			
		}
		
	}
	
	.blue-flash {
		@include translucentbg($blue-flash-bg, $flashOpacity) ;
		
		height: $blue-flash-height;
		position: absolute;
		bottom: 0;
		width: 100%;
		color: $white;

		#blueflashtext, #nonukblueflashtext {
			padding: 12px 0 0 390px;
		}
		
		h2 {
			font-family:$demibold-font-stack;
			font-size: 27px;
			line-height: 50px;
		}
		
		p {
			font-size: 22px;
			line-height: 30px;
		}
		
		h3 span {
			font-family:$demibold-font-stack!important;
		}
	}
}

.magazine-header.daily-deal, .magazine-header.weekly-deal {
	height: 270px;
	
	.blue-flash {
		height: 270px;
		background-color: $light-turquoise ; 
		
		#countdown {
			position: absolute;
			bottom: 30px;
			right: 0;
			width: 700px;
			li {
				float: left;
				width: 43px;
				height: 28px;
				font-family: $demibold-font-stack ;
				font-size: 30px;
				background-color: $white ;
				color: $light-turquoise; 
				text-align: center;
				margin-right: 10px;
				padding: 10px;
				line-height: 16px;
				span {
					font-size: 12px;
				}
			}
		}
	}
}

.magazine-intro {
	background-color: $medium-background-colour;
	min-height: 150px;
	overflow: hidden;
	
	.intro_text {
		padding: 12px 12px 0 390px;
	}
	
}
.read-more {
	color: $red;
}

.spec {
	border-top: 1px solid $grey-border-colour;
	margin-top: 18px;
	
	.frequency {
		border-right: 1px solid $grey-border-colour;
		padding: 18px 18px 18px 0 ;
		float: left;
	}
	.perfect-for {
		padding: 18px 0 18px 18px ;
		float: left;
	}
}


.left-column {
	float: left;
	padding-left: 80px;
	width: 270px;
}

.right-column {
	width: 620px ;
	float: right ;
	padding-top: 12px;
	padding-right: 80px;
}

h1 {
	font-family: $demibold-font-stack ;
	font-weight: normal;
	color: $white;
	font-size: 35px;
	line-height: 45px;
}


h2 {
	font-family:$demibold-font-stack;
	font-size: 27px;
	line-height: 24px;
}

h3 {
	font-family:$demibold-font-stack!important;
	font-size: 19px;
	line-height: 24px;
}

.large {
	font-size: 22px;
	line-height: 24px;
	
}

input.text {
	border-width: 1px;
	border-color: rgb(194, 194, 194);
	border-style: solid;
	height: 39px;
	margin-right: 20px;
	font-size: 16px;
	color: rgb(33, 40, 44);
	line-height: 19px;
	padding-left: 16px;  
}

.closing {
	.container {	
		.subscription {
			display: none !important;
		}
	}
	.container:before {
		content: "This magazine is closing." ;
	}
}
.mini-basket, .continueToBasket {
	width: 45% !important;
}
.mini-basket {
	background: url(/images/green_tick.webp) 0px 38px no-repeat;
	padding-left: 80px;
}
.continueToBasket {
    text-align: right!important;
}
.mini-basket-link {
	color: #ffffff!important;
	text-decoration: none!important;
}
.most-popular, .people-liked, .you-viewed {
	overflow: hidden;
	padding: 50px 85px 0 85px;
}
.MiniBasketBlock.sticky {
	position: fixed;
	top: 0;
	height: auto;
	z-index: 7;
	width: 930px;
	box-shadow: 0px 4px 7px -3px #000000;
	
	.version1 {
		
		&:nth-last-child(1) {
			display: block!important;
		}
	}
}

.MiniBasketBlock.sticky, .footerBasketTrail.footer--pinned {
	width: 100% !important;
	max-width: 1500px;
}

.MiniBasketBlock {
	border: 1px solid #B4B4B4;
	border-top: 0px solid;
	padding-top: 0px!important;
    will-change: transform;
    transition: transform 200ms linear;
	
	.version1 {
		
		&:nth-last-child(1) {
			display: block!important;
		}
		
		.continueToBasket {
			vertical-align: middle;
		}
	}
}
.headroom--pinned {
    transform: translateY(0%);
}
.headroom--unpinned {
    transform: translateY(-139%);
}
.container.RecommendedFourYou.sticky, .container.recommendedSingleProduct.sticky {
	margin-top: 105px;
}
.footerBasketTrail {
	border: 1px solid #B4B4B4;
	border-bottom: 0px solid;
    will-change: transform;
    transition: transform 200ms linear;
	position: fixed;
    bottom: 0px;
    width: 1100px;
    padding: 25px 45px!important;
    z-index: 7;
	box-shadow: 0px -4px 7px -3px #000000;
	
	.footerTrail {
		background: white;
		display: inline-block;
		margin-right: 30px;
		
		.BasketTrail {
			display: inline-block;
			background: white;
			color: #adaaaa;
			
			span {
				padding: 0 50px;
			}
			
			img {
				vertical-align: middle;
			}
		}
		
		.currentTrail {
			color: #000!important;
		}
	}
	
	.ScrollToBasketLink {
		float: right;
		cursor: pointer;
		margin-top: 5px;
	}
}
.footer--pinned {
    transform: translateY(0%);
}
.footer--unpinned {
    transform: translateY(139%);
}
.before-basket {
	overflow: hidden;
	padding: 10px 25px 0px;
	text-align: initial!important;
	width: 95%!important;
	margin-bottom: 15px;
	
	h2 {
		padding-top: 35px;
		
			&:after {
				content: '\2026';
			}
	}
	h2.rhs {
		
		&:after {
			content: '';
		}
	}
	
	h3 {
		font-family: $normal-font-stack;
		font-weight: 300;
		color:#db071a;
		padding-top: 14px;
		padding-bottom: 25px!important;
		
		.text-underline {
			background: url(/images/underline.webp) 4px 25px no-repeat;
			padding-bottom: 10px;
		}
	}
	
	.categoryblock {
		width: 100%!important;
		
		.offer-box {
			width: 47%;
			
			h2 {
				padding-top: 0px;
				
				span {
					font-size: 18px;
					font-family: $normal-font-stack;
					font-weight: 300;
				}
			}
			.top-part {
				background-color:#ff9900;
			}
			.bottom-part {
				height: 200px;
				border-color:#ff9900;
			}
			.bubble {
				background-color:#db071a;
				top: 201px;
				left: 151px;
			}
			.cover {
				top: 20px;
				left: 20px;
				width: 190px;
				height: 238px;

				img {
					box-shadow: 0 0 0 $grey-border-colour;
					@include transform(rotate(0deg));
				}
			}
			.rhs {
				text-align: center;
				width: 255px;
				padding-right: 10px;
					
				.ppi {
					padding: 25px 10px 0;
					
					.per-issue {
						float: right;
					}
				}
				.quickview-button {
					width: 100%;
					border-color:$grey-border-colour;
					background-image: none;
					margin-top: 9px;
				}
			}
		}
	}
}
.most-popular, .people-liked, .you-viewed, .before-basket {
	
	h3 {
		margin-bottom: 25px;
		border-bottom: 1px solid $grey-border-colour;
		padding-bottom: 15px;
	}
	.lhs {
		width: 450px;
		float: left;
		
		.magazine:first-child {
			margin-left: 0;
		}
	}
	.rhs {
		width: 450px;
		float: right;

		.words {
			width: 162px;
			float: right;

			p {
				line-height: 24px;
				margin-bottom: 24px;
			}
		}
	}
	.category-box:first-child {
		margin-left: 0;
	}
	
	.magazine {
		height: 350px !important;
	}
}
.most-popular {	
	.category-box {
		display: table ;
	}

	a {
		margin-top: 0 !important;
		display: table-cell;
		vertical-align: middle;
		
		img {
			display: block;
			margin: 0 auto ;
		}
	}
}

.you-viewed {
	margin-bottom: 32px;
}
.rewards {
	overflow: hidden;
	padding: 30px 85px;
	margin: 0 auto;
	
	.pull-left {
		padding-top: 15px;	
	}	
	
	.pull-right {
		width: 590px;
	}
	h2 {
		font-weight: bold;
		font-size: 30px;
		color: rgb(33, 40, 44);
		line-height: 0.8;
	}
}
.feefo_header {
    line-height: 35px;
    text-align: center;
    font-size: 40px;
    padding: 50px 0 30px;
	margin-top: -10px;
}

.faqs_container:not(#FAQ_accordion_OldStyle) {
	.faqs_tab {
		.view-faq {
			width: 33.33%;
		}
	}
}

#FAQ_accordion_OldStyle {
	.faqs_tab {
		.view-faq {
			width: 14.28%;
		}
	}

	.faqs {
		.accordion-header {
			background: $white;
		}
	}
}

.faqs_container {
	margin-top: 75px;
	
	.FAQ_header {
		text-align: center;
		font-size: 40px;
		padding-bottom: 75px;
		margin: 0 auto;
    }
	
	.faqs_tab {
		width: 100%;
		
		.view-faq {
			text-align: center;
			padding: 18px 0;
			border-width: 1px;
			border-color: $grey-border-colour;
			border-style: solid;
			position: relative;
			box-sizing: border-box;
			border-bottom: 0;
			cursor: pointer;
			margin-bottom: -2px;
			z-index: 1;
			
			p {
				font-size: 24px;
				height: 48px;
			}
		}
		
		.view-faq:not(.active) {
			border: 1px solid transparent;
		}
		
		.view-faq.renew_cancel {
			width: 33%;
		}
		
		.view-faq.active {
			background-color: $light-background-colour;
			
			.hider {
				position: absolute;
				display: none;
				bottom: -2px;
				left: -1px;
				width: 100%;
				height: 6px;
				background-color: $light-background-colour;
				border-left: 1px solid $grey-border-colour;
				border-right: 1px solid $grey-border-colour;
				z-index: 2;
			}
		}
	}
	
	.faqs {
		display: none;
		width: 81%;
		background-color: $light-background-colour;
		overflow: hidden;
		padding: 38px 9%;
		border: 1px solid $grey-border-colour;
	}
	
	.choose-faq.active {
		display: block !important;
		border: 1px solid $grey-border-colour;
		position: relative;
		
		.accordion {
		
			.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
				border: 1px solid $grey-border-colour;
				background: $white;
				font-weight: normal;
				color: $black;
			}
			
			.ui-state-active {
				border-bottom: 0px;
			}
			
			.ui-widget-content {
				border: 1px solid $grey-border-colour;
				background: $white;
				color: $black;
				border-top: 0px;
			}
			
			.ui-state-active .ui-icon, .ui-state-default .ui-icon {
				background-image: url(/stylesheets/images/ui-icons_333333_256x240.webp)!important;
				left: 97%!important;
			}

			h3, h2 {
				font: 18px $normal-font-stack !important;
				font-weight: 500 !important;
				outline: 0;
				margin-top: 10px;
			}
		}
	}
}

.container {
	.subsTab_tab, .smallerHide.footerNav, #boxesnavsetup:not(.navbar-collapse):not(.smallerHide) {
		display: inline-block !important;
		z-index: 11;
	}		
	.subsTab_tab.footerNav {
		margin: 0 auto !important;
		width: 100%;
		max-width: 1500px !important;
	}
	.subsTab_tab.footerNav.withPartsFooter {
		margin: 0 auto 0 -12px !important;
	}
	.NewNormal_container {		
		#boxesnavsetup.newnormalHide {
			
			.subsTab_tab.footerNav {
				margin: 0 auto !important;
			}

			#boxnav:not(.footerNav) {
				display: none !important;
			}
		}
	}
	
	.isDarkBG {
		a.text {
			color: white;
		}
	}
}
.container.bundle-container-main {

	.subsTab_tab.footerNav {
		margin: 0 auto 0 -25px !important;
	}
}

.subsTab_container.Bundle_container, .subsTab_container.secondaryNav_container {
		
	.subsTab_tab, #boxesnavsetup {
		
		.view-subsTab {
			text-align: left;
			display: inline-block;
			padding: 0;
			position: relative;
			box-sizing: border-box;
			cursor: pointer;
			margin: 10px 5px 0;
			overflow: visible;
			
			p {
				font-size: 15px;
			}
		}
		
		.view-subsTab.active {
			background: none!important;
			
			p {
				color: $red;
			}
		}
	}
	
	.subsTab {
		overflow: hidden;
		padding: 38px 9%;
		padding: 18px 0 30px;
	}
	
	.choose-subsTab.active {
		display: block !important;
		border: 1px solid $grey-border-colour;
		position: relative;
	}
}

.subsTab_container:not(.Bundle_container):not(.secondaryNav_container) {
	margin-top: 75px;
	
	.subsTab_tab {
		width: 100%;
		
		.view-subsTab {
			text-align: center;
			width: 22%;
			height: 80px;
			padding: 18px 0;
			border-width: 1px;
			border-color: $grey-border-colour;
			border-style: solid;
			position: relative;
			box-sizing: border-box;
			border-bottom: 0;
			cursor: pointer;
			margin: 10px 1.5% 0;
			overflow: visible;
			
			p {
				font-size: 20px;
				margin-top: 5%;
			}
		}
		
		.view-subsTab:not(.active) {
			border: 1px solid transparent;
		}
		
		.view-subsTab.renew_cancel {
			width: 33.2%;
		}
		
		.view-subsTab.active {
			background: none!important;
			height: 90px;
			margin-top: 0;
			
			p {
				color: $red;
				font-size: 24px;
			}
			
			.hider {
				position: absolute;
				display: block;
				bottom: -1px;
				left: -1px;
				width: 100%;
				height: 6px;
				background: white!important;
				border-left: 1px solid $grey-border-colour;
				border-right: 1px solid $grey-border-colour;
				z-index: 2;
			}
		}
	}
	
	.subsTab {
		width: 81%;
		background-color: $light-background-colour;
		overflow: hidden;
		border: 1px solid $grey-border-colour;
		padding: 18px 0 30px;
	}
	
	.choose-subsTab.buySub.active {
		padding-bottom: 40px;
	}
	
	.choose-subsTab.active {
		display: block !important;
		border: 1px solid $grey-border-colour;
		position: relative;
	}
}

.feefo2019{
	border: 1px solid $grey-border-colour;
	padding: 4px;
}

.feefo {
	background-color: $light-background-colour;
	overflow: hidden;
	padding: 38px 0;
	
	.feefo-box {
		margin-top: 12px;
		width: 289px;
		height: 235px;
		
		img {
			width: 100% !important;
			height: 100% !important;
		}
		.centred {
			width: 150px;
			margin: 0 auto;
			text-align: center;
		}
		
		.stars {
			background-image: url('/images/feefo-stars.webp') ;
			height: 23px;
			margin: 25px auto 15px auto ;
		}
		.stars.score1 {
			width: 26px;
		}
		.stars.score2 {
			width: 52px;
		}
		.stars.score3 {
			width: 67px;
		}
		.stars.score4 {
			width: 104px;
		}
		.stars.score5 {
			width: 133px;
		}
	}
	
	.right-column {
		h3 {
			font-size: 22px;
			color: rgb(33, 40, 44);
			font-weight: bold;
			line-height: 24px;
		}
		
		#feefotbody {
			border-bottom: 1px dotted $grey-border-colour;
			margin-top: 20px;
			color: rgb(115, 115, 115);
			line-height: 22px;
			margin-bottom: 20px;
		}
		
		.feeforow {
			padding: 6px 0;
			width: 605px;
			border-top: 1px dotted $grey-border-colour;
			clear: both;
			overflow: hidden;
		}
		.score {
			float: left;
			padding-top: 12px;
			width: 50px;
			
			.excellent {
				height: 18px ;
				background-image: url('/images/feefo-excellent.webp');
				margin: 0 auto;
			}
			
			.bad {
				height: 18px ;
				background-image: url('/images/feefo-bad.webp');
				margin: 0 auto;
			}
			.score1 {
				width: 18px ;
			}
			.score2 {
				width: 36px ;
			}
		}
		.date {
			float: left;
			padding-top: 12px;
			width: 113px;

			strong {
				display: block;
				color: $black;
				font-weight: bold;
			}
		}
		.comment {
			float: left;
			padding-top: 12px;
			width: 605px - 113px - 50px;
		}
		
		.subscript {
			margin-bottom: -5px;
		} 
	}
}

.full-width {
	clear: both;
	width: 86% ;
	margin: 0 auto;
}
	
#cross-sell-overlay {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: rgba(25, 25, 25, 0.8);
	z-index: 9999;
	display: none;
}
.cross-sell {
	overflow: hidden;
	height: auto;
	margin: 5% auto 10px auto !important;
	background-color: $white;
	padding: 0 20px 20px 20px;
	width: 1000px !important;
	
	.half-width {
		width: 50% ;
		float: left ;
		overflow: hidden;
	}
	
	.full-width {
		clear: both;
		width: 86% ;
		margin: 0 auto;
	}

	.closer {
		background-color: $red;
		float: right;
		font-family: $demibold-font-stack;
		font-size: 18px;
		color: $white;
		width: 120px;
		line-height: 50px;
		text-align: center;
		cursor: pointer;
		margin-right: -20px;
	}

	h3 {
		font-size: 22px;
		color: rgb(33, 40, 44);
		font-weight: bold;
		line-height: 24px;
		width: 100% ;
		border-bottom: 1px solid $grey-border-colour;
		padding-top: 20px;
		padding-bottom: 8px;
		text-align: center;
	}
	
	.content {
		width: 82%;
		padding-left: 5%;
	}
	
	.covers {
		margin-top: 24px;
	}
	
	.magazine {
		width: 33%;
		text-align: center;
		float: left;
		margin-bottom: 24px;
	}
	
	.magazine img {
		height: 216px;
	}
	
	.half-width.left .content {
		float: right ;
	}
	.half-width.right .content {
		float: left ;
	}
	
	.mag-box {
		border-width: 1px;
		border-color: rgb(194, 194, 194);
		border-style: solid;
		width: 160px;
		height: 103px;
		float: left;
		margin-left: 30px; 
		margin-top: 30px;
	}
	
	.mag-box.first {
		margin-left: 0 !important;
	}
}
.ui-widget {
    font-family: $normal-font-stack!important;
	font-weight: 300;
}
#country-select-error {
	
	p {
		color: $red;
		font-weight: bold;
		padding-bottom: 10px;
	}
}
.country-field.disabled {
	opacity: 0.5;
	outline: 0;
}
@media screen and (min-width: 376px) {
	.MiniBasketBlock.sticky {
		width: 1500px!important;
	}
}
@media screen and (max-width: 767px ) {
	.paginationPageBlock {
		position: relative !important;
	}
	#boxnav.footerNav.PDP-footer {
		height: 70px;

		.view-subsTab {
			display: none !important;
		}
	}
	#subscriptioncontainer #mysubscriptionpanel.newnormal_2024 .top-part .pricing-block-container.footerNav .block:not(.packageBlock):not(.rollingSubBlock):not(.fixedSubBlock) {
		background-position-x: calc(100% - 5px) !important;
		padding-top: 11px !important;
	}

	.pricing-block-container.footerNav, .pricingblock-performat.footerNav {

		p.subscriptionLabel {
			display: none;
		}
		.pricing-container, .cart-section-container {
			position: fixed;
			z-index: 999;
			width: 46% !important;
		}
		.pricing-container {
			left: 10px;
			bottom: 2px;
			
			.block {
				padding: 11px 25px 11px 13px !important;
				
				.pricing {
					p {
						&:nth-child(n+2) {
							display: none;
						}

						.rollingExtra {
							display: none !important;
						}
					}
				}
			}
		}
		.cart-section-container {
			right:  10px;
			bottom: 5px;

			#rhsbenefit-print, #rhsbenefit-digital, #rhsbenefit-bundle {
				display: none;
			}
		}
		#giftblock {
			display: none;
		}
	}
	.pricingblock-performat.footerNav {
		position: fixed;
    	z-index: 999;
		width: 100% !important;
		left: 3px;
		bottom: 5px;

		#addtobasket {
			bottom: 13px;
			position: fixed;
			z-index: 999;
			width: 46% !important;
			border-radius: 8px !important;
		}
		.pricing-container {
			left: 10px;
			bottom: -2px;

			.pricing-block {
				border-radius: 8px !important;
				
				.block {
					padding: 8px 16px 7px 0px !important;
					background-image: url(/images/drop-down.webp) !important;
					background-size: 20px 20px !important;
					background-position-x: calc(100% - 2px) !important;
					text-align: center;
					border-radius: 8px !important;
					background-repeat: no-repeat !important;
					background-position-y: center !important;
				}
			}
			
		}
	}
}
@media screen and (max-width: 1024px) {
	body.bootstrap {
	
		.lhs {
			.category-box {
				width: 27.5%;
				margin: 0 13px 26px;
			}
		}
		.category-page-header {
			
			.controls {

				#categories-filters {
					left: -179px;
					width: 750px;

					.toptab {
						margin-left: 199px;
					}
				}
				
				label {
					margin: 0 5px 0 0px;
				}
				select {
					width: 135px;
				}
			}
		}
	}
}
@media screen and (min-width: 650px) and (max-width: 1100px) {
	body.bootstrap {
		.magazine-categories:not(.container), .guide-categories:not(.container) {
			padding: 32px 15px 32px 15px !important;
		}
		.container {
			width: 100%;
			max-width: 1100px;
		}

		.faqs_container {
			margin: 10px auto 75px;
			border: 1px solid #c2c2c2;
			background-color: #EDEDED;

			.FAQ_header {
				font-size: 21px !important;
				width: 100%;
				text-align: center;
				padding-top: 10px;
				padding-bottom: 15px;
			}

			.faqs_tab {
				display: none;

				.view-faq {
					padding: 18px 0 9px !important;

					p {
						margin-top: -6px;
					}
				}

				.view-faq.active {

					.hider {
						bottom: -12px !important;
						height: 12px !important;
					}
				}
			}

			.choose-faq.faqs, .choose-faqs.active {
				width: 87%;
				margin: 0 auto;
				padding: 15px 4%;
				display: block !important;
				background: none;
				border: 0px solid !important;

				p.text {
					font-size: 24px !important;
					padding-bottom: 10px;
					display: block !important;
				}

				.accordion {

					.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
						padding-left: 9px;
						background: #fff;
						border: 1px solid #c2c2c2;
						font: 15px $normal-font-stack !important;
						font-weight: 500 !important;
						color: #000;
						margin: 7px 0 0;
					}

					.ui-state-active .ui-icon, .ui-state-default .ui-icon {
						left: 94% !important;
						background-image: url(/stylesheets/images/ui-icons_333333_256x240.webp) !important;
					}

					h3, h2 {
						font: 15px $normal-font-stack !important;
					}

					.ui-widget-content {
						padding: 1em;
						border-top: 0px;

						p {
							font-size: 15.4px !important;
						}
					}
				}
			}
		}
	}
}
@media screen and (max-width: 750px) {
	body.bootstrap {
		header {
			.home-link {
			width: 200px;
			height: 60px;
			float: left;
			text-decoration: none;
			overflow: hidden;
			}
		}
		.category-page-header {
		
			.hero_image {
				width: auto;
			}	
			
			.controls {

				#categories-filters:not(.top-0) {
					top: 50px!important;
					left: 0px;
					width: 390px;

					.toptab {
						margin-left: 0px;
					}
				}

				.tab {
					width: 49%;
					display: inline-block;
					
					.button {
						font-size: 15px !important;
					}
					
					.mobileFilterFooter {
						width: 100%;
						background: $white;
						margin: 0px!important;
						
						.col {
							padding: 0 6px;
							
							.w-50 {
								width: 100%!important;
								font-size: 15px;
							}
						}
					}
				}
				
				label {
					margin: 0 5px 0 9%;
				}
				.sorter {
					width: 49%;
				
					select {
						width: 100%;
					}
				}
			}
		}
		
		.best-sellers {
			
			.categoryblock {
				
				.magazine {
					height: auto;
					margin-bottom: 25px!important;
					
					img {
						width: 152px;
						height: 202px;
					}
					p {
						margin: 0 auto;
						max-width: 152px;
					}
				}
			}
		}
		.magazine-categories {
			padding: 32px 25px;
		}
		.lhs {

			.category-box {
				width: 30%;
				height: 82px;
				margin: 0 5.5px 15px;
				
				.wholebox {
					line-height: 18px;
					font-size: 16px;
					padding: 0px;
				}
			}
		}
		.rhs {
			height: auto;

			.category-box {
				width: 100%!important;
				height: auto!important;
				padding: 10px!important;
				margin-left: 0;
				
				&:first-child {
					margin-bottom: 10px!important;
				}
				
				h3 {
					font-size: 17px;
					line-height: 17px;
					padding-bottom: 4px;
				}
				
				p {
					padding-bottom: 0px!important;
				}
				
				a.button {
					display: none;
				}

				img {
					float: left;
					padding: 0 12px 25px 0;
				}
			}
		}
		.footerPromises {
		
			.our-promises {
			
				.full-width {
					
					.promise {
					
						&:nth-child(3) {
							padding-top: 0px;
						}
					
						h3 {
							padding: 16px 0;
						}
					}
				}
			}
		}
		
		.bundle-container-main {
			#subscriptioncontainer {
			
				.bundle-container {
					margin: 0px auto 35px!important;
				}
			}
			.subsTab_container.Bundle_container {
				.subsTab_tab.smallerHide.footerNav {
					width: 100% !important;
					margin: 0 auto!important;
				}
			}
		}
		.container {
			.NewNormal_container {
				.subsTab_tab.smallerHide.footerNav {
					width: 100% !important;
				}
			}
		}
	}
	
	.offer-boxes {
		padding: 32px 0px 0px!important;
		
		.offer-box {
			height: 290px!important;
			width: 100%!important;
			display: block;
			flex: auto;
			margin-left: 14px!important;
			margin-right: 14px!important;
			
			.top-part {
				height: 72px!important;
			}
			
			.rhs {
				width: 180px!important;
			}
			
			h2 {
				font-size: 25px!important;
				float: right!important;
			}
			
			h2.rhs {
				width: 100%!important;
			}
			
			.bubble {
				top: 193px!important;
				left: 127px!important;
			}
			.bottom-part {
				height: 193px!important;
				
				.magname {
					line-height: 24px!important;
					font-size: 20px!important;
					padding: 11px 0 8px 0!important;
				}
				
				.per-issue {
					font-size: 14px!important;
					margin: 5px 5px 5px 20px!important;
					height: auto!important;
				}
				.price {
					font-size: 30px!important;
				}
			}
			.cover {
				top: 82px!important;
				left: 26px!important;
			}
		}
	}
}
@media screen and (max-width: 350px) {
	body.bootstrap {
	
		.category-page-header {
			
			.controls {
				
				label {
					display: none;
				}
				select {
					float: right!important;
					margin-right: 5px;
				}
			}
		}
	}
}