.feefo-product-stars-widget {
	display: none !important;
}

body {
	font: 15px $normal-font-stack;
	font-weight: 300;
	color: $primary-color;
	line-height: 24px;
}

p, #magazinesorter {
	font-family: $normal-font-stack;
	font-weight: 300;
}
p {
	font-size: 15px;
}
#magazinesorter {
	font-size: 16px;
}
.text-start {
	p {
		text-align: left!important;
	}
}
.text-end {
	p {
		text-align: right!important;
	}
}
.strike-through {
	text-decoration: line-through;
}

.container {
	width: 100% ;
	margin: 0 auto ;
	position: relative;
}

body.iframe .container {
	max-width: 900px;
	width: auto;
}

nav.container {
	width: 1098px ;
}
.nav-item {
	.nav-link {
		color: #0000ee!important;
		text-decoration: underline;
		
		&:hover {
			color: #0000ee!important;
		}
	}
}
.ScrollToCrossSell {
	height: auto !important;
	border-color: #db071a !important;
	text-decoration: none;
	background: $white;

	i {
		height: 25px;
		width: 25px;
	}
}

strong {
	font-family: $demibold-font-stack ;
}

.inline {
	display: inline !important;
}

.outOfStockCover {
	filter: brightness(0.75);
	opacity: 0.5;
}

@media screen and (max-width: 750px) {
	.mobileTable {
		td {
			display: block;
		}
	}
}
.top-header {
	height: 82px;
	background-color: $light-background-colour ;
}

.top-header.checkout {
	background: none;
	
	.site-header {
		border-bottom: 1px solid $medium-grey-border-colour;
	}
}
#LI-embed {
	z-index: 6 !important;
}
.outOfStock, .outOfStockDisabled, .outOfStock:hover, .outOfStockDisabled:hover {
	background: lightgray;
    padding: 5px;
    font-weight: bold;
}
.outOfStock {
    color: #fff !important;
}
.outOfStock.disabled, .outOfStockDisabled {
    color: #656565  !important;
}
.nav {
	height: 44px;
	border: 1px solid #B4B4B4;
	
	button {
		&:focus {
			outline: none;
		}
		span {
			&:focus {
				outline: none;
			}
		}
	}
}

.hidden {
	display: none !important;
}

#product_descriptionsubheader {
	h2 {
		line-height: 32px;
	}
}
.printOnly,.digitalOnly,.bundleOnly,.printInStockOnly,.printOutOfStockOnly {display: none;}
body.digital, body.bundle {
	.variationselect.printOnly {
		display: none !important;
	}
}
body.print .printOnly, body.digital .digitalOnly, body.bundle .bundleOnly { display: initial; }
body.print .printOutOfStock .printOutOfStockOnly { display: initial; }
body.print .printInStock .printInStockOnly { display: initial; }
div.quickview.print .printOnly, div.quickview.digital .digitalOnly, div.quickview.bundle .bundleOnly { display: initial; }
div.quickview.print .printOutOfStock .printOutOfStockOnly { display: initial; }
div.quickview.print .printInStock .printInStockOnly { display: initial; }
body.digital {
	.header-promises {
		li.delivery.digital {
			display: inline-block !important;
		}
		li.delivery.print {
			display: none;
		}
	}
}

.notvisible {
	visibility: hidden;
}

.orange {
	background-color: $orange!important;
}

.redText, .now {
	color: $red!important;
}

.red {
	background-color: $red;
}

.button.white {
	background-color: $white;
	color: $black;
	border: 1px solid $medium-grey-border-colour;
	display: inline;
}

a.button {
	display: inline-block;
	text-decoration: none;
	height: 25px!important;
	padding: 8px;
}

.btn {
	border-radius:0px !important;
}

.black {
	background-color: $black;
}


.red-text {
	color: $red!important;
}

.no-underline {
	text-decoration: none;
}

.pull-left {
	float: left !important;	
}

.pull-right {
	float: right !important;
}

.clear-both {
	clear: both;
	overflow: hidden;
}

.clearfix, .clear {
	clear: both!important;
}

.lineThrough {
	text-decoration: line-through!important;
}

.formatUnavailableMessage {
	font-size: 14px!important;
}

.loading:not(.offcanvas-body) {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: #F0F0F0;
		
	img {
		position: absolute;
		left: 50%;
		top: 50%;
		display: block;
	}
}

.pageoverlay {
	position: fixed;
	height: 2500px;
	width: 1100px;
	background-color: rgba(25, 25, 25, 0.8);
	z-index: 8;
}

.more-info {
	display: none;
	z-index: 99;
	text-align: left;
	p {
		color:#494949;
		text-decoration: none !important;
		text-align:left;
	}
	ul {
		list-style: disc;
		list-style-position: outside;
				
		li {
			margin-left: 15px;
		}
	}
	.closer {
		display: none;
	}
}

.formatIconBlock {
	background: #ffffffab;
	padding: 10px 0;
	display: none;
}

.fomratIcons, .formatIcons {
	padding: 0 3px;
	font-size: 24px;
	color: $red;
	display: none;
}	
.popup.bottom {
	display: none;
	position: fixed;
    bottom: 10px;
    right: 10px;
    background: $light-background-colour;
    padding: 10px 15px;
    border: 1px solid $grey-border-colour;
    width: 0px;
    height: 0px;
    z-index: 99;
    text-align: left;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
    
    img {
        width: 75%;
		margin-left: -6px;
        display: inline-block;
    }
    
	.closer {
        display: inline-block;
        padding: 0px 5px;
		cursor: pointer;
	}
	
	.giftQuestionBlock {
		
		li {
			&:nth-last-child(1) {
				margin-top: 10px;
			}
		}
	}
	
	.giftQuestionBlock, #giftAnswer {
		padding-bottom: 20px;
	}
}
	
.popup.bottom.showPopUp {
	display: block;
    width: 350px;
    height: 225px;
    box-shadow: 1px 1px 10px $black;
}	
.promCodePopUp {
	text-align: center;
    width: 575px;
    margin: 50px auto 0;
    background: $white;
    padding: 25px;
    border: 1px solid $grey-border-colour;
    z-index: 99;
	
	h3 {
		margin-bottom: 20px;
	}
	
	p, h3 {
		color: $primary-color;
		display: inline-block;
		
		span {
			color: $red;
			text-transform: uppercase;
		}
	}
	.ErrorMessage {
		margin-top: 20px;
	}
	.closer {
		float: right;
		border: 2px solid;
		padding: 3px;
		line-height: 14px;
		font-size: 20px;
		font-weight: bold;
		cursor: pointer;
	}
	
	form {
		margin: 25px auto 5px;
		width: 75%;
		
		input {
			width: 75%;
		}
		
		button {
			float: right;
		}
    }
}
.FullBtn {
	width: 100%;
}
@media screen and (max-width: 650px) {	
	.promCodePopUp {
		width: 330px;
		padding: 15px;
		
		h3 {
			font-size: 20px;
		}
		
		form {
			width: 100%;
			input {
				width: 69%;
			}
		}
	}

	.intro_moreMobile {
		display: none;
	}
}

.tt-info {
	cursor: pointer;			
	text-decoration: none !important;
	height: 22px;
	display: inline-block;
	margin:0 2px 0 2px;
	position:relative;
	background-color: $black;
	color: $white !important;
	border: 1px solid $black;
	border-radius: 12px;
	text-align: center;
	width: 22px;
	
	&:hover {
		background-color: $white !important;
		color: $black !important ;
		
		.more-info {
			color: $black !important ;
			border: 1px solid $medium-grey-border-colour ;
			border-radius: 0px !important;
			font-size: 13px;
			display: block;
			padding: 14px 9px 9px 9px;
			position: absolute;
			text-align: left;
			left: -150px;
			top: -16px;
			width: 422px;
			z-index:399;
			background-color: $white !important;
			
			&.bottom {
				top: auto !important;
				left: -138px!important;
				bottom: 17px !important;
				height: auto;
			}
			&.left {
				top: auto !important;
				bottom: -100px !important;
				height: 175px;
				left: -270px !important;
			}
			&.right {
				top: auto !important;
				bottom: -100px !important;
				height: 175px;
				right: -270px !important;
			}
			
			p {
				font-size: 13px;
			}
		}
	}
}
.asatoleft .more-info.bottom {
	top: auto !important;
	left: auto !important;
	right: 25px !important;
	bottom: 8px !important;
}
	
.overlay {
	position: fixed;
	top: 0;
	left: 0;
	height: 100% ;
	width: 100% ;
	background-color: rgba(25,25,25,0.8);
	z-index: 9;
	
	.sampler-caroussel {
		height: 100%;
		
		.sampler-page  {
			margin: 0 auto;
			height: 90%;
			width: 68vh;
			position: relative;
			
			.closer {
				position: absolute;
				right: 31px;
				top: -50px;
				background-color: $red;
				font-family: $demibold-font-stack ;
				font-size: 18px;
				font-weight: normal;
				color: $white;
				width:120px;
				height: 50px;
				line-height: 50px;
				text-align: center;
				cursor: pointer;
				img {
					margin: 11px 15px 0 -3px;
					float: right;
				}
			}
			
			.left-arrow {
				position: absolute;
				padding-top: 64%;
				cursor: pointer;
			}
			
			.right-arrow {
				position: absolute;
				right: 0;
				top:0;
				padding-top: 64%;
				cursor: pointer;
			}
			.covercontainer {
				margin: 50px 30px 0 30px;
				#currentcover {
					width: 100%; 
				}
			}
			
		}
	}
	
	.closed-banner {
		margin: 50px auto;
		display: block;
		width: 400px;
	}
}
.stop-scrolling {
	height: 100%;
	overflow: hidden;
}

.cookie-box {
        background:$white;
        bottom:10px;
        border:1px solid #ccc;
        -webkit-box-shadow:0 0 6px rgba(0, 0, 0, 0.2);
		-moz-box-shadow:0 0 6px rgba(0, 0, 0, 0.2);
		box-shadow:0 0 6px rgba(0, 0, 0, 0.2);
        left:10px;
        opacity:0;
        padding:10px;
        position:fixed;
        visibility:hidden;
        width:400px;
        z-index:100;
        -webkit-transition:opacity 1s ease, visibility 0s ease 1s;
		-moz-transition:opacity 1s ease, visibility 0s ease 1s;
		-ms-transition:opacity 1s ease, visibility 0s ease 1s;
		-o-transition:opacity 1s ease, visibility 0s ease 1s;
		transition:opacity 1s ease, visibility 0s ease 1s;
                
	.icon-close:before,
	.icon-close:after {
		background:$white;
		border-radius:50%;
		content:"";
		display:block;
		height:27px;
		left:0;
		position:absolute;
		text-indent:0;
		top:0;
		width:27px;
	}
	.icon-close:after {
		background:#6C6C6C;
		color:$white;
		content:"X";
		font:bold 12px/23px Verdana, Geneva, sans-serif;
		height:23px;
		left:2px;
		text-align:center;
		top:2px;
		width:23px;
		-webkit-transition:background .3s ease;
		-moz-transition:background .3s ease;
		-ms-transition:background .3s ease;
		-o-transition:background .3s ease;
		transition:background .3s ease;
	}
	.icon-close:hover:after {
        background:#B10019;
	}
	p {
        line-height:18px;
        margin:0; 
	}
	a {
        color:#B10019;
	}
	a:hover {
        text-decoration:underline;
	}
                
	.icon-close {
		cursor:pointer;
		height:27px;
		position:absolute;
		right:-10px;
		top:-10px;
		width:27px;
		z-index:101;
		text-indent:-999em;
	}
}
.cookie-box-active {
        opacity:1;
        visibility:visible;
        -webkit-transition-delay:0s;
		-moz-transition-delay:0s;
		-ms-transition-delay:0s;
		-o-transition-delay:0s;
		transition-delay:0s;
}

.site-header {
	padding: 28px 70px;
	position: relative;
	
	.overlay-image {
		position: absolute;
		top: 0;
		left: 0;
		pointer-events: none;
	}
	
	.manage_subs {
		float: right;
		width: 105px;
		line-height: 16px;
		position: relative;
		margin-left: 18px;
    
		a {
			text-decoration: none;
			color: $red;

			i {
				vertical-align: super;
			}
			div {
				vertical-align: sub;
			}
		}
	}
	.search-box.search_variant0 {
		float: right;

		.search-words {
			float: right !important;
			width: 290px !important;
		}
	}
	.search-box {
		margin-top: -4px;
		position: relative;
		
		button {
			position: absolute;
			top: 8px;
			right: 11px;
			border: none;
			background: none;
		}
		
		input {
			height: 38px;
			outline: none; 
			padding-left: 16px;
		}
		
		input.menu-open {
			border-bottom: 0px;
		}
	}
}

.fa-caret-right.text-danger {
	transform: rotate(45deg);
	vertical-align: middle;
	margin-left: 3px;
}

.mega-menu-indicator {
	background-image: url(/images/red-triangle.webp) ;
	width: 8px;
	height: 8px;
	display: inline-block;
}

.selected {
	.mega-menu-indicator {
		background-image: url(/images/red-triangle-open.webp);
	}

	.fa-caret-right.text-danger {
		transform: rotate(225deg);
	}
}

.navigation {
	padding: 0 30px;
	overflow: visible;
	position: relative;

	ul {
		border-left: 1px solid $grey-border-colour;
		overflow: hidden;	
		float: left;
	}

	.giftingLink {
		a {
		color: $red;
		font-weight: bold;
		}
	}

	li {
		border-right: 1px solid $grey-border-colour;
		float: left ;
		padding: 10px 19px;
		z-index: 12;

		#atoz-mega-menu-2022, #categories-mega-menu-2022, #guides-specials-mega-menu-2022, #single-issues-mega-menu-2022 {
			left: auto!important;
			padding: 10px 0;
			width: auto;
			height: auto;
			box-shadow: 5px 5px 5px 0 #000;
		}
		
		#atoz-mega-menu-2022 {
			margin-left: -32px;
		}
		#categories-mega-menu-2022, #guides-specials-mega-menu-2022, #single-issues-mega-menu-2022 {
			margin-left: -20px;
		}

		ul.menu-row {
			list-style: none;
			border: 0px solid;
			padding: 0px;

			li {
				float: none;
				border: 0px solid;
				padding: 0px;

				a {
					display: block;
					padding: 0px 27px;
				}

				&:hover {
					background: #db071a30;

					.redText {
						color: #21282c !important;
					}
				}
			}
		}
	}	
	
	li.selected {
		border-bottom: 0px solid;
    }
	
	a {
		text-decoration: none;
		color: rgb(33, 40, 44);
	}
	
	.your-basket {
		padding: 10px 0;
		float: right;

		img {
			margin: 3px 6px;
		}
	}

	#your-basket-menu {
		top: 34px;
		width: 375px
	}

	#basket-message {
		top: 52px;
		width: 430px;

		#basket-message-body {
			background-color: $white;
			padding: 18px;
			clear: both;
			border-radius: 4px;
			filter: drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.12));

			#message-closer {
				margin-top: -8px;
			}
		}
	}

	#your-basket-menu, #basket-message {
		position: absolute;
		right: 4px;
		z-index: 12;
		
		form {
			float: right;
		}
		
		#your-basket-menu-body {
			padding: 18px;
			clear: left;
			background-color: $very-light-background-colour;
			border-right: 1px solid $grey-border-colour;
			border-bottom: 1px solid $grey-border-colour;
			border-left: 1px solid $grey-border-colour;		

			button.message-closer {
				border-radius: 4px !important;
			}
		}
		
		.your-basket-item {
			overflow:hidden;
			border-bottom: 1px dotted $grey-border-colour;
			margin-bottom: 8px;
			padding: 8px 0;
			line-height: 70px;
			
			img {
				height: 70px;
				float: left;
				margin-right: 8px;
			}
			p {
				float: left;
				width: 55%;
				line-height: 25px;
				margin-top: 4%;
			}
			a {
				display: inline-block;
				font-size: 14px;
				color: $red;
			}
		}
		
		#your-basket-menu-footer {
			clear: both;
			line-height: 40px;
			font-family: $demibold-font-stack;

			a {
				color: $red;
				text-decoration: underline;
			}
		}
	}
}
.categoryblock {
	margin: 0 auto;
	width: 966px;
	overflow: hidden;

	.magazine {
		height: 350px;
		
		button {
			margin: 0 auto;
			display: block;
		}
		img {
			width: 173px;
			height: 220px;
		}
		a {
			text-decoration: none;
			position: relative;
			color: $black;
		}
		.magname {
			text-align: center;
			color: $black;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			height: 40px;
			width: 173px;
			margin: 0 auto;
			line-height: 20px;
		}
		.saving {
			text-align: center;
			color: $red;
		}
	}
}
.corporate-page {
	h1 {
		padding: 32px 0;
		color: $black;
	}
	h1, h2, h3, p, ol {
		width: 100%;
	}

	ol {
		list-style: auto;
		padding-left: 40px;
	}
	b {
		font-family: $demibold-font-stack;
	}
}

.sitemap {
	height: 300px;
	background-color: #b71c22;
	padding: 56px 0 0 87px;

	.column {
		color: #ffffff;
		
		h2 {
			font-family:$demibold-font-stack;
			font-size: 16px;
			line-height: 32px;
		}		
		ul {
			list-style-type: none;	
			font-size: 14px;
			line-height: 29px;
		}
		
		a {
			color: #ffffff;
			text-decoration: none;
		}
	}
	.column.double {
		width: 300px;

		ul {
			height: 200px;
		
			li {
				display: block;
				float: left;
				width: 150px;
			}
		}
	} 
	
	.column.three {
		margin-left: 44px;
	}

	.column.four {
		margin-left: 93px;
	}
	
	.column.five {
		margin-left: 61px;
	}
	
}
.sitemap.standalone {
	height: 140px;
	padding: 25px 0 0 87px;

	.column {
		width: 450px;
		
		ul {
			width: 222px;
			float: left;
		}
	}
}


.copyright {
	height: 85px;
	padding: 40px 0 0 80px;
	background-image: url(/images/dark-bar-bg.webp);
	background-color: #21282c;
	color: $white;
	background-position-x: right;
	background-repeat: no-repeat;
}

.copyright.footerNav {
	height: 139px;
}

.copyright.checkout {
	background-image: url(/images/dark-bar-bg.webp);
}

.green-highlight, .pink-highlight, .yellow-highlight, .white-highlight {
    padding-left: 3px;
}

.green-highlight {
    background: #99FFCC;
}

.green-highlight::selection, .green-highlight::-moz-selection {
    background: #99CCCC;
}

.pink-highlight {
    background: #FFCCFF;
}

.pink-highlight::selection, .pink-highlight::-moz-selection {
    background: #FF99FF;
}

.yellow-highlight {
    background: #FFFFCC;
}

.yellow-highlight::selection, .yellow-highlight::-moz-selection {
    background: #FFFF66;
}

.white-highlight {
    background: #FFFFFF;
}


.white-highlight::selection, .white-highlight::-moz-selection {
    background: #FFFFFF;
}

#desktop_banner {
	overflow: hidden ;
	text-align: center;
	padding: 5px 0 ;
	
	&.plain {
		padding: 0;
		
		p {
			line-height: 0;
		}
	}
	
	& > div {
		display: inline-block;
		vertical-align: middle;
	}	
}
.barPrice {
	width: 100%;
	text-align: left;
	border: 1px solid $tableHeaderGrey;
	
		thead {
			background: $tableHeaderGrey;
			font-weight: bold;
		}
		
		tr {
			&:nth-child(even) {
				background: $tableGrey;
			}
			
			th, td {
				padding: 3px 5px;
			}
		}
}
	.subsTab_tab {
		.country-field {
			height: 30px!important;
			width: 193px !important;
			font-size: 14px !important;
			background: url(/images/selectbg.webp) no-repeat right $white;
			
			.flag {
				height: 20px;
			}
		}
	}
	.headerNav, .navSticky {
		position: fixed;
		left: 0;
		right: 0;
		z-index: 9;
	}
	.desktopHeader.headerNav {
		top: 0px;

		.promcodeContainer {
			display: none;
		}
	}

	#dropdowns-2022.headerNav, #dropdowns-old.headerNav {
		top: 94px;
		background: $white;
		z-index: 10;
	}

	.subsTab_container.secondaryNav_container {
		.subsTab_tab {
			margin-top: -6px;
			font-size: 14px;
		}
		.choose-subsTab.active {
			border-top: 1px solid $grey-border-colour;
			border-right: 0px solid;
			border-left: 0px solid;
			border-bottom: 0px solid;
		}
		.subsTab_tab.footerNav {
			position: fixed;
			bottom: 0;
			background: $white;
			z-index: 7;
			width: 1078px;
			margin: 0 auto 0 -14px;
			padding: 10px;
			border: 1px solid;
			text-align: center;
			box-shadow: 0px -4px 7px -3px $black;
			
			.view-subsTab {
				margin: 0 1%;
			}
			#digitalInside, .footerNavShow {
				display: inline-block!important;
			}
			.footerNavHide {
				display: none!important;
			}
			.tick_box {
				margin: 5px auto 20px;
				display: none;
				height: 293px;
				
				ul {
					height: 293px;
					display:table-cell;
					vertical-align:middle;
				}
			}
		}
	}
@media screen and (max-width: 650px) {
	.mobileHeader.headerNav {
		top: 0px;
	}

	.mobileNav.headerNav {
		top: 60px;
		background-color: $white;
		box-shadow: 0px 4px 7px -3px #000000;
	}

	.mobileTableResizing {
		max-width: none !important;
		td {
			display: table-caption;
		}
	}
	
	.subsTab_container {
		.subsTab_tab.footerNav {
			width: 95% !important;
			margin-left: 0px !important;
			right: 0;
			
			span {
				display: block;
				text-align: center;
				float: none !important;
				
				span {
					display: inline-block;
				}
			}
		}
	}
	#boxesnavsetup.show {
		.navbar-nav {
			display: block!important;
		}
	}
	#boxesnavsetup:not(.show) {
		.navbar-nav {
			display: none!important;
		}
	}
	.secondary-navbar:not(.sticky) {
		display: none!important;
	}
	.secondary-navbar.sticky {
		#boxesnavsetup:not(.show) {
			.navbar-nav {
				display: block!important;
				width: 90%;
			}
		}
	}
}

/* BOOTSTRAP TWEAKS FOR RESPONSIVENESS WITHIN MD STYLES */
@media (min-width: 820px) and (max-width: 1250px) {
	.container {
		max-width: 1100px!important;
	}

	#subscriptioncontainer {
		#mysubscriptionpanel {
			margin-top: 0;
		}
	}
	
	.left-column.promises {
		width: 30% !important;
	}
	
	.MiniBasketBlock.sticky, .footerBasketTrail.footer--pinned {
		width: 1100px !important;
	}
}
@media (min-width: 750px) and (max-width: 820px) {

	#subscriptioncontainer {
		.right-column {
			margin: 0 auto;
			float: none;
		}

		#mysubscriptionpanel {
			margin-top: 0;
		}
	}

	.left-column.promises {
		width: 100% !important;
	}
}
@media (min-width: 1250px) {
	.container {
		max-width: 1500px !important;
	}
}
@media (min-width: 1000px) and (max-width: 1305px) {
	.nav.nonBranded {
	
		.navigation {
		
			ul {
				
				li {
					padding: 10px 12px;
				}
			}

			.giftingHide {
				display: none;
			}
		}
	}
	.header-promises {
	
		ul {
			
			li {
				padding: 0 7px 0 22px;
				font-size: 15px;
			}
		}
	}
	.offer-boxes {

		.offer-box {

			.bottom-part {
			
				.cover {
					left: 13px;
				}
			}
		}
	}
}
	
@media (min-width: 1000px) and (max-width: 1400px) {

	.offer-boxes {
	
		.offer-box {
			margin: 0 1%!important;
		}
	}
}
@media (max-width: 1000px) {
	.nav.nonBranded {
		padding: 0;
	
		.navigation {
			padding: 0 11px;
		
			ul {
				padding: 0;
				
				li {
					padding: 10px 8px;
				}
			}
			
			.your-basket {
				width: 70px!important;
				
				span.smallerHide {
					display: none;
				}
			}

			.giftingHide {
				display: none;
			}
			
			.giftingNav + .your-basket {
				width: 49px !important;
			}

			.giftingNav {
				li {
					padding: 10px 7px;
				}
			}
		}
	}
	.header-promises {

		div {
			padding-left: 0px !important;
			padding-right: 0px !important;
	
			ul {
				padding-top: 5px!important;
				
				li {
					padding: 0 7px 0 22px;
					font-size: 15px;
					line-height: 13px;
				}

				li.reviews, li.delivery {
					width: 175px !important;
					padding-right: 0px !important;
				}

				li.reviews {
					img {
						display: inline-block !important;
					}
				}
			}
		}
	}
	.offer-boxes {
		padding: 32px 0px!important;

		.offer-box {
			width: 469px!important;
			height: auto!important;
			margin: 0 14px!important;
			
			&:first-child {
				margin-left: 0;
			}
			
			&:last-child {
				margin-right: 0;
			}
		
			.top-part {
				height: 70px!important;
				
				h2 {
					float: left;
					text-align: center;
					width: 100%;
					margin-right: 0;
				}
			}
			
			.bubble {
				top: 209px!important;
				left: 105px!important;
			}
			
			.bottom-part {
			
				.cover{
					width: 130px;
					height: 165px;
					top: 92px;
					left: 15px;
				}
				
				.rhs {
					width: 170px;
				}
			}
		}
	}
}