.alert-success {
	background: #e5f5e7;
}
.sliderText {
	font-size: 14px!important;
}
.sliderAlsoLikes {
	
	img {
		max-height: 160px;
	}
}