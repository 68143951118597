.newsletter-signup {
	height: 162px;
	border-top: $medium-grey-border-colour;
	
	.never-miss {
		width: 375px;
		float: left;
		h3 {
			font-size: 22px;
			color: rgb(33, 40, 44);
			font-weight: bold;
			line-height: 24px;
			padding: 44px 0 14px 0;
		}
	}
	
	.signup {
		float: left;
		padding: 80px 0 0 25px;
		width: 390px;
		.text {
			width: 260px;
		}
		button.red {
			float: left;
		}
	}
	
	.feefo-summary {
		background:rgb(255,0,0);
		background: transparent\9;
		background:rgba(255,0,0,0.0);
		margin: 42px 0 0 0;
		float: left;
		width: 150px;
		height: 50px;
		text-align: center;
	}
}