#subscriptioncontainer {
	position: relative;
		
	.large{
		font-size: 36px;
		line-height: 50px;
	}

	#mysubscriptionpanel.newnormal_2024 {

		.top-part {
			border: 4px $red solid;
			border-radius: 15px;

			.lhs {
				width: 100%;

				.top-container {
					width: 100%;
					display: flex;
					row-gap: 20px;
					column-gap: 55px;
					justify-content: space-between;
				
					.subContainer {
						display: flex;
						flex-direction: column;
						row-gap: 20px;

						.subscribeTop {
							position: relative;
							top: -24px;
							margin-bottom: -24px;

							h2 {
								padding-bottom: 12px;
							}

							.subheading {
								font-size: 14px !important;
								font-style: normal;
								font-weight: 500;
								line-height: 19px;
								padding-left: 0px !important;
								padding-right: 10px;
								text-wrap: pretty;
							}
						}

						.definitionterm, div.variationselect {
							display: flex;
							align-items: center;
							flex-wrap: wrap;
							padding-top: 0px;

							p, strong {
								width: 30%;
								padding-left: 0px;
							}

							#published, #simple-inline, #format-select, .country-select, #magazinesize-select, select.variationselect, #giftOption {
								display: block;
								max-width: 288px;
								width: 70%;
							}

							select.variationselect {
								margin-bottom: 0px;
							}

							.country-select {

								input {
									width: 100% !important;
									max-width: 288px;
									float: right;
									border-radius: 8px;
								}
							}
						}
					}

					.large {
						width: max-content;
						height: max-content;

						#rhs-mag-coverImage {
							width: 172px;
							height: 216px;
							box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.25);
						}

						#rhs-productImage {
							margin-top: -35px;
							width: auto;
							height: 290px;
						}
					}
				}

				#pricingblock:not(.packagePricing) {
					display: flex;
					flex-direction: column;
					row-gap: 20px;

					
					.pricing-block-container {
						display: flex;
						flex-wrap: wrap;
						row-gap: 10px;
						
						p {
							margin-top: 17px;
							padding: 0;
							width: 18.8%;
						}

						.pricing-container {
							width: 515px;

							.pricing {
								p {
									margin-top: 0px;
									width: auto;
									font-size: 14px;
									font-style: normal;
									font-weight: 500;
								}
								.red-text {
									font-weight: 600;
								}
							}
						}
					}
				}

				#giftblock {
					display: flex;
					align-items: center;
					justify-content: start;
					overflow-y: hidden;
					height: max-content;
					flex-wrap: wrap;
					padding: 0;
					row-gap: 10px;

					p {
						margin-top: 0px !important;
					}

					.form-group.labelled {
						height: max-content;
						width: 288px;
						padding: 0;

						fieldset {
							display: flex;
							justify-content: start;
							align-items: center;
							column-gap: 10px;
							flex-direction: row-reverse;

							label {
								width: 88px;
								border-radius: 8px;
								height: 42px;
								font-size: 14px;
								font-weight: 500;
								margin: 0;
								display: flex;
								justify-content: center;
								align-items: center;
							}
						}
					}
				}
			}

			.cart-section-container {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				column-gap: 55px;

				.cart-section {
					width: 100%;
					margin-left: 18.8%;

					.cart-container {
						display: flex;
						width: 288px;
						flex-direction: column;
						align-items: center;

						#addtobasket {
							width: 288px;
							border-radius: 8px !important;
							margin-bottom: 6px;
							height: 47px !important;
							font-size: 16px !important;
							font-weight: 700;
						}
					}
				}
			}
			
			input, select {
				color: #101828;
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				appearance: none;
				padding: 4px 14px !important;
			}

			.block:not(.packageBlock):not(.rollingSubBlock):not(.fixedSubBlock) {
				padding: 17px 40px 17px 17px;
			}

			input, select, .block:not(.packageBlock):not(.rollingSubBlock):not(.fixedSubBlock) {
				border-radius: 8px;
				background-image: url(/images/drop-down.webp) !important;
				background-size: 20px 20px !important;
				background-position-x: calc(100% - 14px) !important;
				background-repeat: no-repeat !important;
				background-position-y: center;
			}
		}

		.asa {
			display: none;
		}
	}

	#mysubscriptionpanel.newnormal_2024.GiftSubscription_yes {

		.top-part {

			.lhs{

			#pricingblock.variationPricingBlock {

				.pricing-block-container {

					.pricing-container {
						width: 50%;
					}
					
					.package-pricing-block-container {
						width: 50%;
						margin: 10px 0px;
					}
				}
			}
				#pricingblock {
				
					.pricing-block-container {
			
						.pricing-container {
							width: 50%;
						}

						.giftbox {
							width: 11.5%;
							position: absolute;
							right: 108px;

							p {
								width: auto;
								padding: 0 5px;
								margin-top: 0px;
							}

							img {
								width: 172px;
							}
						}
					}
				}
			}
		}

	}
}
#mysubscriptionpanel {
	margin-top: -139px;
		
	.top-part {
		border: 2px #000 solid;
		background-color: #fff;
		border-bottom: none;
		overflow: visible;
		width: 100%;
		padding-top: 25px;
		float: left;
	}
		
	.asa {
		clear: both;
		width: 100%;
		text-align: center;
		padding: 12px 0;
		border: 2px #000 solid;
		border-top: none;
		background: $white;
	}
	.body {
		margin-top: 5px;
		padding: 25px 30px 30px 30px;
		overflow: visible;
		float: left;
	}
	
	.lhs, .rhs {
		width: 50%;
		float: left ;
		background-color: #fefefe;
	}
	.rhs {
		float: right ;
		width: 48%;
	}
	.rhs.gift-yes {
		
		img {
			width: 80%;	
		}
	}
}		
#odd-arrow {
	position: absolute;
	top: 135px;
	left: 20px;
}
#rhsprintbenefit + div {
	display: contents;
}
		.subscription .country-select .country-field {
			width: 82%;
			margin-right: 0;
		}
		#rhs-mag-cover, #rhs-mag-coverImage{
			margin: 0 auto;
			display: block;
			width: 100%;
		}
		
		.centred {
			margin: 0 auto;
			text-align: center;
		}
		.simple-inline {
			display: inline;
			font-size: 15px;
			width: 135px;
			float: none;
			height: 40px;
			margin: 2px 0;
			background-color: #ffffff;
		}
		#magazinesize-select, #format-select {
			width: 250px;
		}
		#magazinetype {
			padding-top: 4px;
		}
		#giftheadline {
			font-weight: bold;
		}
		#gifttext, #giftheadline {
			text-align: center;
		}
		#gifttext {
			margin-bottom: 0!important;
		}
		.giftbox {
			margin-top: 13px;
		}
		.giftbox img {
			display: block;
		}
		.giftbox:not(.mobileShow) img {
			width: 50%;
			margin: 0 auto;
		}
		.giftbox.mobileShow img {
			margin: 9px auto;
		}
		.giftlightbox img {
			width: initial!important;
		}
		#giftblock label {
			width: 120px;
		}
		#giftblock .labelled {
			margin: 0;
			padding: 0;
			background: none;
			height: 40px;
		}
		#gifterror {
			visibility: hidden; 
			display: none;
		}
		.gifterror.bottomerror {
			display: none;
		}
		.gifterror.bottomerror.error {
			display: inline-block;
			margin: 10px 15px;
			text-align: center;
			width: 95%;
		}
		.gifterror.bottomerror p {
			padding: 3px;
			border: 1px solid #db071a;
		}
		.infoerror {
			color: #fff;
			background: #db071a;
			width: 23px;
			height: 23px;
			line-height: 12px;
			margin-right: 10px;
			font-weight: bold;
			font-size: 19px;
			box-sizing: border-box;
			display: inline-block;
			padding: 6px;
		}
		#giftblock.error p, #giftblock.error label {
			color: #db071a;
		}
		#giftblock.error label {
			border-color: #db071a;
		}
		
		#giftblock.error #gifterror {
			visibility: visible; 
		}
		.subscription .country-select {
			margin-bottom: 20px;
		}
		.pricing p strong span {
			display: block;
		}
		.pricing-container {
			margin: 10px 0 ;
			position: relative;
			z-index: 6;
		}
		
		#pricingblock form {
			display: none;
		}
		
		.pricing-block {
			background-color: #fff;
			width: 100%;
		}
		.pricing-block, .country-field {
			cursor: default;
		}
		.subscription .country-select .country-field, .pricing-block .block {
			background: url('/images/selectbg-large3.webp') no-repeat right;
		}
		.pricing-block .block {
			border: 1px #c2c2c2 solid;
			padding: 0 40px 0 5px ;
			background-size: contain;
		}

		.pricing-block.expanded .block {
			border-top: none ;	
			background: none;
		}
		.pricing-block.expanded .block:first-child {
			border-top: 1px #c2c2c2 solid ;
		}
		.pricing-block.expanded .block:hover {
			background-color: #ccc;
		}
		.pricing-block.expanded .block.chosen {
			background: url('/images/tick.webp') no-repeat 95% 50%;
			
		}
		
		#intro_more.fold-out, #intro_more_readMore.fold-out {
			margin-bottom: 100px;
		}
		
		#rhsgifttext img {
			max-width: 100px;
			max-height: 100px;
			float: right;
		}
		.right-column {
			width: 52%;
			padding-right: 20px;
		}
		.faqs_container .faqs {
			width: 81.75%;
		}
		.faqs_container {
			margin: 10px auto 75px;
		}
		body.bootstrap {
			.faqs_container .faqs {
				width: 100%;
			}
		}

@media (max-width: 1224px) {
	#mysubscriptionpanel.newnormal_2024, #mysubscriptionpanel.newnormal_2024.GiftSubscription_yes {

		.top-part {

			.lhs {

				#pricingblock {

					.pricing-block-container {

						.pricing-block-container:not(.footerNav) {

							.pricing-container{
								width: 100% !important;
							}
						}
					}
				}
				
				#pricingblock.variationPricingBlock {
	
					.pricing-block-container:not(.footerNav) {
	
						.pricing-container {
							width: 50% !important;
						}
					}
				}
			}
		}
	}
}

@media (max-width: 750px) {
	#mysubscriptionpanel.newnormal_2024, #mysubscriptionpanel.newnormal_2024.GiftSubscription_yes {

		.top-part {

			.lhs {
				
				#pricingblock.variationPricingBlock {
					
					.pricing-block-container:not(.footerNav) {
						
						p:not(.packagePricingTotal) {
							width: 30% !important;
						}
						
						.pricing-container, .package-pricing-block-container {
							width: 100% !important;
							
							p {
								width: auto!important;
							}
						}
					}
					
					.pricing-block {
						
						.block {
							padding-top: 17px !important;
						}
					}
					
					#giftblock {
	
						p {
							width: max-content !important;
						}
					}
				}
			}
			.cart-section-container {
				
				.cart-section {
					margin-left: 0px !important;

					.cart-container {
						width: 100% !important;

						#addtobasket {
							width: 100% !important;
						}
					}
				}
			}
		}
	}
}

@media screen and (min-width: 751px) {
	#subscriptioncontainer {

		.right-column {
			min-width: 660px;

			#mysubscriptionpanel {
				min-width: 675px;
			}

		}
	}
}		
@media screen and (max-width: 750px) {
	body:not(.bootstrap) {
		.container, #preheader-wrapper {
			width: 90%!important;
		}
	}
	body.bootstrap {
		.MiniBasketBlock.sticky {
			width: 100% !important;
		}
		.container, #preheader-wrapper {
			width: 100% !important;
		}
		.faqs_container {
			.choose-faq.faqs {
				p.text {
					font-size: 19px !important;
					line-height: 10px !important;
					text-align: center;
				}
			}
		}
	}
	.container, #preheader-wrapper {
		.smallerHide {
			display: none !important;
		}
	}
	#subscriptioncontainer {
		width: 99% !important;
		position: relative;
		background-color: #f8f8f8;

		.subscription {
			padding-top: 0;

			#messagebox {
				padding: 0 10px;
			}
		}

		.right-column {
			width: auto;
			padding: 0;
			float: none;

			#mysubscriptionpanel {
				padding-bottom: 20px;
				width: 95%;
				margin: 0 auto;

				.top-part {
					padding: 25px 10px 0;
				}

				.asa {
					padding: 12px 10px;

					.tt-info {
						float: left;
						width: 20px;
						height: 20px;

						&:hover {

							.more-info.bottom {
								left: -13px !important;
								width: 298px;
							}

						}
					}
				}

				.top-part,
				.asa {
					width: 93%;

					.centred {
						p.large {
							font-size: 26px !important;
							margin-top: -15px;
							line-height: 50px !important;
							color: initial;
						}

						p.centred {
							width: auto !important;
						}
					}

					.body {
						padding: 25px 0px 30px;

						.lhs {
							width: 100%;
							float: none;

							.simple-inline {
								height: 30px;
							}

							.country-select .country-field, .pricing-block .block:not(.chosen):not(.visible):not(#fixedPricing):not(#rollingPricing) {
								background: url(/images/selectbg-large3.webp) no-repeat right !important;
							}

							.country-select {
								.flag {
									height: 23px !important;
								}

								.country-field {
									width: 83% !important;
									height: 30px !important;
									padding-left: 9px !important;
								}
							}

							#giftblock {
								label {
									font-size: 14px;
									height: 30px;
									padding: 4px;
									margin: 0px;

									&:nth-child(1) {
										margin-right: 10px;
									}
								}
							}

							.pricing-block {

								.block {
									padding-top: 4px;

									.pricing {

										p {
											line-height: 22px !important;
										}
									}
								}
							}

							#rhsprintbenefit {
								margin: 15px auto 0 !important;
							}
						}

						.rhs {
							display: none;
						}
					}
				}
			}
		}

		.left-column {
			float: none;
			width: 100%;
			padding: 32px 10px;

			.headline {
				width: auto;
			}
		}

		.promisesbox {
			padding-left: 20px;
		}
	}
	.asa {
		font-size: 12px !important;
		line-height: 18px !important;
	}
	.nav.container, .top-header.container, .feefoStars {
		display: none !important;
	}
	nav.mobileShow:not(.nonBranded), .text.mobileShow, header.mobileShow:not(.nonBranded), .feefoMobile.mobileShow, br.mobileShow {
		display: block !important;
	}
	.cover-top {
		height: auto;
		background-color: gray;
		background-image: -webkit-linear-gradient(dimgray, white);
		background-image: linear-gradient(dimgray, white);

		h1 {
			color: #fff !important;
			font-size: 30px;
			line-height: 30px;
			text-align: center;
			width: auto;
			padding: 25px 5px;
			
			span {
				font-size: 66%;
				line-height: 66%;
				margin-top: 2px;
				display: block;
				text-transform: lowercase;
			}
		}
		.covers {
			position: relative;
			left: 0;
			bottom: 0px;
			height: auto;
			width: 100%;
			z-index: 4;
			
			.small {
				display: none;
			}
			
			.large {
				float: none !important;
				margin: 0 auto !important;
				height: auto !important;

				.look-inside {
					bottom: 6px;
				}
			}
		}
		.blue-flash {
			position: relative;
			text-align: center;
			line-height: 17px;
			height: auto;
			padding-bottom: 10px;
			padding-top: 5px;

			#blueflashtext, #nonukblueflashtext {
				padding: 0px 2px;
			}
			
			h3 {
				font-size: 17px;
				line-height: 17px;
				padding-bottom: 4px;
				
				&:nth-child(1) {
					padding-top: 10px;
				}
			}
			
			div {
				font-size: 14px;
			}
		}
	}
	.magazine-intro {
		min-height: 70px;

		.intro_text {
			padding: 12px;
			margin-bottom: 20px;
		}

		.right-column {
			padding: 12px 10px!important;
		}

		#intro_more, #intro_more_readMore {
			margin-bottom: 30px!important;
			padding-top: 15px;
		}
	}
	.right-column {
		width: auto;
		float: none;
		padding: 0 10px;
	}
	.bundle-container-main {
		#boxessetup {
			.subsTab_tab.smallerHide.footerNav {
				width: 84% !important;
				text-align: center !important;
				display: inline-table !important;
				
				.view-subsTab {
					display: block;
					text-align: center;
				}
				span, .view-subsTab {
					line-height: 18px;
				}
			}
		}
	}
	.standalone-product .standaloneHeadline {
		width: 85%;
		margin: 0 auto;
	}
	.standalone-product .standaloneHeadline, #message_headline {
		font-size: 27px;
		line-height: 30px;
	}
	.faqs_container {
		margin: 10px auto 75px;
		border: 1px solid #c2c2c2;
		background-color: #EDEDED;

		.FAQ_header {
			font-size: 21px !important;
			
			width: 100%;
			text-align: center;
			padding-top: 10px;
			padding-bottom: 15px;
		}
		.faqs_tab {
			display: none;
			.view-faq {
				padding: 18px 0 9px!important;
				
				p {
					margin-top: -6px;
				}
			}
			.view-faq.active {

				.hider {
					bottom: -12px!important;
					height: 12px!important;
				}
			}
		}
		.choose-faq.faqs, .choose-faqs.active {
			width: 87%;
			margin: 0 auto;
			padding: 15px 4%;
			display: block!important;
			background: none;
			border: 0px solid!important;
			
			p.text {
				font-size: 24px!important;
				padding-bottom: 10px;
			}
			
			.accordion {
			
				.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
					padding-left: 9px;
					background: #fff;
					border: 1px solid #c2c2c2;
					font: 15px $normal-font-stack !important;
					font-weight: 500 !important;
					color: #000;
					margin: 7px 0 0;
				}
				
				.ui-state-active .ui-icon, .ui-state-default .ui-icon {
					left: 94%!important;
					background-image: url(/stylesheets/images/ui-icons_333333_256x240.webp) !important;
				}

				h3, h2 {
					font: 15px $normal-font-stack!important;
				}
				.ui-widget-content {
					padding: 1em;
					border-top: 0px;
					
					p {
						font-size: 15.4px!important;
					}
				}
			}
		}
	}
	.most-popular, .people-liked, .you-viewed, .before-basket {
		h3 {
			margin-bottom: 0;
			border-bottom: 0px solid #c2c2c2;
			padding-bottom: 15px;
			font-size: 17px;
			line-height: 17px;
		}
	}
	.feefoMobile {
		padding: 16px 0;
		margin-left: 35px;
	}
	.feefo_header {
		text-align: center;

		font-size: 27px!important;   
		font-family: $demibold-font-stack;
		font-weight: normal;
	}
	.feefo2019 {
		border: 0px solid #c2c2c2;
	}
	.people-liked {
		padding: 50px 0px 0 !important;
		text-align: center;
		
		.categoryblock {
			.magazine {
				
				.magname {
					overflow: hidden;
				}
			}
		}
	}
	.our-promises {
		h2 {
			font-size: 17px;
			line-height: 24px;
			padding: 22px 0 10px 0;
		}
		.full-width {
			.promise {
				margin: 15px 0px 0px 0px !important;
				text-align: center;
				float: none!important;
				padding-top: 34px;
				background-size: 11% !important;
				background-repeat: no-repeat !important;
				background-position: center top !important;
    
				img {
					display: none;
				}
				
				&:nth-child(2) {
					background: $promise1;
				}
				&:nth-child(3) {
					background: $promise2;
					padding-top: 40px;
					background-size: 8%!important;
				}
				&:nth-child(4) {
					background: $promise3;
				}
				h3 {
					font-size: 14px;
					padding: 0 0 4px;
				}
				p {
					width: 83%!important;
				}
			}
		}
	}
	.sitemap.standalone {
		
		.column {
			width: 155px;
			
			ul {
				width: auto!important;
			}
		}
	}
	.sitemap, .sitemap.standalone {
		padding: 10px;
		height: auto;
		
		.column {
			margin-left: 0px !important;
			float: none;
			display: inline-block;
		}
		.column.three {
			display: none;
		}
		.column.four, .column.five {
			ul {
				li {
					&:nth-child(n+3) {
						display: none;
					}
				}	
			}
		}
	}
	.copyright {
		background-image: url(/images/mobile-dark-bar-bg.webp);
		background-repeat: no-repeat;
		background-position-x: right;
		background-size: contain;
		color: #fff;
		text-align: center;
		height: 123px;
		padding: 0px;
		
		p {
			margin-top: 75px;
		}
    }
}