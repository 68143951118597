.quickview {
	.oneOffFormat {
		#format-select {
			width: 310px;
			margin-left: 0px;
		}
	}
}
.oneOffFormat {
	#format-select {
		width: 335px;
	}
}
.standaloneLogo {
	width: 300px;
}
.productRating {
	position: absolute;
    right: 6px;
    top: 28px;
    z-index: 1;
    cursor: pointer;
    width: 40%;
    overflow: hidden;
    display: none;
}

.star_rating {
	color: $star_rating;
}
.zoomPDP {
	text-align: center;
    width: 170px;
    margin: 10px auto;
    padding: 2px 0px;
    font-size: 16px;
    border: 1px solid;
    line-height: 24px;
    cursor: pointer;
}
.secondary-navbar {
	font-size: 12px;
	
	.nav-container {
		text-align: left;
	
		ul {
			.nav-item {
				display: inline-block;
			}
		}
	}
	
	.country-select-container {
		text-align: right;
	}
	
	.country-field {
		height: 34px;
		width: 240px;
		font-size: 12px;
	}
}
.secondary-navbar.sticky {
	position: fixed;
	bottom: 0;
	z-index: 9;
	background: $white;
	box-shadow: 0px 4px 7px -3px $black;
	
	button.navbar-toggler {
		display: none;
	}
	.collapse:not(.show) {
		display: block;
	}
	.navbar-nav {
		text-align: center;
		max-width: 100%;
		flex: 0 0 100%;
		margin-left: auto;
		display: inline-block!important;
		
		li {
			display: inline-block;
		}
	}
	
	.country-select-holder {
		display: none!important;
	}
}
a.look-inside {
	text-decoration: none;
	font: 15px $normal-font-stack;
	line-height: 55px!important;
	color: initial;
	padding: 2px 7px;
    border: 1px solid;
    background: none;
}

.standalone-product {
	&.magazine-header	{
		height: initial ;
		overflow: visible;
	}
	.standaloneHeadline {
		color: $black;
		width: 100%;
		text-align: center;
		padding: 20px 0 0 0px;		
	}
	.standaloneSubHeadline {
		color: $red;
		text-align: center;
		width: 100%;
		padding-top: 5px;
	}
	.thirdparty.standaloneSubHeadline {
		padding: 5px 0 10px;
	}
	.pleaseSelect {
		border: $red;
		background: rgba(214, 9, 9, 0.5);
	}
	.giftNotSelected {
		color: $red;
		padding: 10px;
		font-weight: 600;
	}
	.standaloneCovers {
		position: relative;
		height: initial;
		overflow: visible;
		bottom: initial;
		left: initial;
		margin: 8px;

		.country-field {
			border-width: 1px;
			border-color: rgb(194, 194, 194);
			color: $countrySelectColour;
			letter-spacing: $countrySelectSpacing;
			text-transform: $countrySelectText;
			font-family: $countrySelectFont;
			font-weight: 300;
			background: $countrySelect_icon no-repeat right #fff!important;
		}
		
		.small {
			width: 89px;	

			img {
				margin-bottom: 5px;
				box-shadow: 2px 1px 3px #c2c2c2;
			}
		}
		.large.one-offImage {
			text-align: center;
			
			img {
				width: auto;
				height: 100%;
			}
		}
		.large:not(.col-12) {
			height: initial;
			width: 435px;
			float: left;
			display: block;
			position: relative;
			text-align: center;
			margin-top: 20px;

			img {
				height: 100%;
				max-height: 400px;
				width: auto;		
				box-shadow: 2px 1px 3px #fff;
			}
			
			img.oneOffPriceImg {
				max-width: 289px;
			}
			
			.bubble {
				position: absolute;
				background-color: $red;
				text-align: center;
				width: 84px;
				height: 40px;
				color: $white;
				top: 20px;
				right: 20px;
				z-index: 2;
				display: table;
				table-layout: fixed;
				padding: 25px 5px;
			}
		}
		.large.col-4 {
			width: 33.33333333% !important;
				
				img.oneOffPriceImg {
					max-width: 400px;
					max-height: 100%;
				}
		}
		
		.choose-offer:not(.col-12):not(.labelled) {
			display: block;
			float: left;
			border: 0px solid #a0a0a0!important;
			margin-left: 30px;
			width: 477px;
			position: relative;
			
			.price {
				font-family: $demibold-font-stack;
				font-size: 45px;
				color: #db071a;
				line-height: 45px;
			}
			
			.smallerPrice {
				line-height: 32px;
				font-size: 30px;
				padding: 4px 0 0px;
				color: #db071a;
				margin: 5px;
			}
			
			.thirdparty.saving {
				font-weight: 600;
				font-size: 18px;
			}
			
			.smallprint {
				padding: 5px 0px 0px;
				overflow: hidden;
			}
		}

		.choose-offer.col-7 {
			width: 58.33333333% !important;
		}
		
		.tick_box {
			background-color: $light-background-colour;
			position: relative;
			
			&:before {
				content: '';
				position: absolute;
				top: -15px;
				left: 197px;
				border-bottom: 15px solid $light-background-colour;
				border-left: 15px solid transparent;
				border-right: 15px solid transparent;
			}
			
			ul {
				padding: 10px 10px 10px 20px;
				display: inline-block;
				
				li {
					padding: 9px 0 10px 5px;
					
					&::before {
						content: "\f058";
						font-family: "Font Awesome 6 Free";
						font-weight: 900;
						color: #009e11;
						padding-right: 7px;
					}
				}
			}
		}
		.bundle_2022_tick.tick_box {
			background: none;
			text-align: left;
			margin-left: -10px;
			width: 110%;
			display: none;

			&:before {
				display: none;
			}

			ul {
				padding: 10px 0px;

				li {
					padding: 3px 0;
				}
			}
		}
		
		.addToBasketBox.one-offImage {
			background: none;
		}
		.addToBasketBox {
			background-color: $light-background-colour;
			padding: 10px 5px 20px;
			
			.singleIssueOptions {
				padding: 4px 9px!important;
			}
			
			.tt-info {
				width: 15px;
				font-size: 13px;
				line-height: 18px;
				height: 15px;
				
				&:hover .more-info {
					top: -30px;
				}
			}
		}
		
		.tick_box_nobg.thirdparty {
				margin-bottom: 3px;
		}
		.tick_box_nobg {
			position: relative;
			
			ul {
				padding: 10px;
				display: inline-block;
				
				li {
					padding: 9px 0 0px 5px;

					&::before {
						content: "\f058";
						font-family: "Font Awesome 6 Free";
						font-weight: 900;
						color: #009e11;
						padding-right: 7px;
					}
				}
			}
		}
	}
		
	label {
		background: $white;
		box-sizing: border-box;
		display: block;
		float: left;
		margin-left: 10px;
		border: 1px solid $grey-border-colour;
		padding: 6px;
		width: 46%;
		text-align: center;
		
		&.double {
			height: 86px;
			font-size: 15px;
		}
	}
		
	label.thirdparty {
		width: 94%;
	}
	
	label.variation {
		box-sizing: border-box;
		display: inline-block;
		margin-left: 10px;
		border: 1px solid $grey-border-colour;
		padding: 6px;
		width: 120px;
		text-align: center;
		
		
	}
	label:hover .tooltip {
		opacity: 1;
			visibility: visible;
	}
	
	.tooltip {
		position: absolute;
		left: 20px;
		right: 20px;
		bottom: 108px;
		z-index: 10;
		opacity: 0;
		visibility: hidden;
		padding: 10px 15px;
		background: $light-background-colour;
		box-shadow: 0px 2px 3px 0px $black;
		transition: opacity 0.3s, visibility 0.3s;
		
		&:before {
			content: '';
			position: absolute;
			top: 68px;
			left: 253px;
			border-top: 15px solid $light-background-colour;
			border-left: 15px solid transparent;
			border-right: 15px solid transparent;
		}
	}
		
	label.checked {
		border-color: #333;
		background: $medium-background-colour;
	}
	.labelled {
		position: relative;
		margin-bottom: 8px;
		padding: 20px 5px 0;
		
		input[type="radio"] {
			display: none;
		}
		
		.gift {
			float: none;
			display: inline-block;
			vertical-align: middle;
			
			img {
				padding-right: 11px;
			}
			
			h3 {
					float: none;
				display: inline-block;
				vertical-align: middle;
			}
		}
		
		h3 {
			padding: 9px;
		}
		
		.form-group {
			vertical-align: middle;
			box-sizing: border-box;
			overflow: hidden;
		}
		
		img:not(.flag) {
			float: left;
			padding-right: 12px;
			margin-top: -5px;
		}
		
		.button {
			border-radius: 0px!important;
			width: 99%;
			margin-top: 20px;
			margin-left: 5px;
		}
		
		.button.SingleIssueBtn {
			width: -webkit-fill-available;
			margin: 20px 9px 0;
		}
		
		.button.thirdparty {
			width: 97%;
		}
	}
		
	.standaloneIncludeText {
		text-align: center;
		padding: 20px 153px;
		font-size: 20px;
	}
	table.BoxOuts {
		border-collapse: separate;
		-webkit-border-horizontal-spacing: 20px;
		
		.standaloneInclude {
			
			img:not(.lookInsideArrow) {
				width: 100%;
				max-width: 340px;
				margin: 0;
			}
			
			&:not(.standaloneImages) {
				td {
					border: 1px solid $grey-border-colour;
					
					.includeText {
						border: 0px solid;
						margin: 0;
						height: auto!important;
					}
				}
			}
		}
	}
	table.alternateBG {
		td {
			background: $light-background-colour-block;
		}
	}
	
	.standaloneInclude {
		
		vertical-align: top;
		text-align: center;
		margin-top: 50px!important;
		
		td {
			width: 33% ;
			max-width: 459px;
			vertical-align: top;
			position: relative;
		}
		
		.standaloneProductInclude {
			width: 91%;
			display: inline;
		}
		
		.includeText {
			margin: 0 4.5%;
			background: $light-background-colour;
			border: 1px solid $grey-border-colour;
			position: relative;
			padding: 20px;
			height: 210px;
			text-align: left;
		
			&:before {
				content: '';
				position: absolute;
				top: -15px;
				border-bottom: 15px solid $light-background-colour;
				border-left: 15px solid transparent;
				border-right: 15px solid transparent;
				left: 0;
				right: 0;
				width: fit-content;
				margin: 0 auto;
			}
			
			.includeProduct {
				font-family: $demibold-font-stack;
				font-size: 24px;
				padding-bottom: 10px;
				min-height: 57px;
			}
		}
	}
}
		
.standaloneFooter {
	height: auto;
	margin-bottom: 30px;
	clear: both;	
	
	.add-to-basket-standalone {
		text-align: center;
		
		.button {
			border-radius: 0px !important; 
			width: 400px;
			margin: 50px auto 10px;
			text-align: center;
			border: 1px solid $grey-border-colour;
		}
	}
}

.recommendedSingleProduct {
	.container {
		width: 100% ;
	}
	
	.before-basket {
		width: 97% !important;
		h3 {
			padding: 10px 9px 9px 9px;
			font-size: 22px;
			line-height: 24px;			
			border: none;
			margin-bottom: 0;	
		}
	}
	.yellow-flash {
		background-color: #FA841D ;
		position: relative ;
		.text {
			width: 440px;
			float: right ;
			padding: 0 0 12px 0;
			h1 {
				font-size: 40px;
				line-height: 55px;
			}
			h2 {
				font-size: 25px;
				padding: 0 0 12px 0;
				color: #ffffff;
			}
		}
		.small, .large {
			margin-top: -120px;
		}
		.articlebox {
			display: none;
		}
	}
	.detailslink {
		display: block !important ;
	}
	.standaloneHeadline, .standaloneSubHeadline, .smallprint {
		display: none;
	}
}
.country-select.thirdparty {
		overflow: hidden;
		margin-bottom: 21px;
		position: relative ;
		
		.country-field {
			float: left;
			border-width: 1px;
			border-color: rgb(194, 194, 194);
			border-style: solid;
			width: 289px;
			height: 39px;
			margin-right: 20px;
			font-size: 16px;
			line-height: 19px;
			padding-left: 16px;
			color: $countrySelectColour;
			letter-spacing: $countrySelectSpacing;
			text-transform: $countrySelectText;
			font-family: $countrySelectFont;
			font-weight: 300;
			background: $countrySelect_icon no-repeat right #fff!important;
		}
		.country-field:focus {
			background: none;
		}
		.country-field[disabled] {
			background-color: rgb(241, 241, 241);
		}
	}
body.bootstrap {
	.singlePricingContainer {	
		button.viewOffers_btn {
			display: initial!important;
		}
	}
}
@media screen and (max-width: 650px)  {
	
	.standalone-product {
		
		.standaloneCovers {
		
			.large.one-offImage {
					
				img {
					height: auto;
					width: auto;
				}
			}

			.bundle_2022_tick.tick_box {

				ul {
					padding: 10px;
				}
			}
		}
	}
}