.receipt {
	h1, h2, h3, h4, h5, h6, strong {
		font-family: $demibold-font-stack;
	}

	h1 {
		width: 405px;
		float: left;
	}
		h2, h3 {
			font-size: 22px;
		}
		h5 {
			font-size: 1.25rem;
		}
	
	.blue-flash h1 {
		width: 705px;
	}
	.order-summary {
		clear: both;
		padding: 24px;
		background-color: $light-background-colour;
	
		.giftcard {
			padding: 10px;
			border: 2px solid #db071a ;
			border-radius: 10px;
			overflow: hidden;
		
			img {
				padding-right: 10px;
				float: left;
				display: none;
			}
			
			.words:not(.paypalGift) {
				float: left;
			}
			.words {
				width: 200px;
				background: $gift_icon_checkout;
				background-repeat: no-repeat!important;
				background-position: top left!important;
			}
			
			h4 {
				font-family: $demibold-font-stack ;
				padding-left: 48px !important;
				margin-bottom: 13px;
			}
		}
		
		h2 {
			font-size: 16px;
		}
		
		table {
			th {
				text-align: left;
				padding-bottom: 20px;
				border-bottom: $grey-border-colour 1px solid;
			}
			td {
				padding-top: 22px;
			}
		}
		div.responsiveDiv {
			display: inline-block;
			vertical-align: top;
				
		}
		.responsiveDiv {
				/* one item */
				&:first-child:nth-last-of-type(1) {
					width: 99%;
				}
				
				/* two items */
				&:first-child:nth-last-of-type(2),
				&:first-child:nth-last-of-type(2) ~ .responsiveDiv {
					width: 49%;
				}
				
				/* three items */
				&:first-child:nth-last-of-type(3),
				&:first-child:nth-last-of-type(3) ~ .responsiveDiv {
					width: 32.3%;
				}
				
				/* four items */
				&:first-child:nth-last-of-type(4),
				&:first-child:nth-last-of-type(4) ~ .responsiveDiv {
					width: 24%;
				}
				
				/* five items */
				&:first-child:nth-last-of-type(5),
				&:first-child:nth-last-of-type(5) ~ .responsiveDiv {
					width: 19%;
				}
		}
			.col-mag {
				width: 318px;
				.cover {
					float: left;
					height: 93px;
				}
			
				.info {
					padding-left: 10px;
					overflow: hidden;
				}
			}
			
			.col-delivery {
				width: 225px;
			}
			.col-extras {
				width: 190px;
			}
			.col-payment-method {
				width: 350px;
			}
	}
	.singleissue {

		&:before {
			content: 'example';
			display: block;
			transform: rotate(-52deg);
			position: absolute;
			font-weight: bold;
			color: $off-black-border-colour;
			font-size: 22px;
			top: 35px;
			left: -14px;
			z-index: 1;
		}

		img {
			filter: opacity(0.75);
		}
	}

	.order-info {	
		padding: 24px 0 24px 50px;
		overflow: hidden;
		
		.lhs {
			clear:left ;
			font-size: 18px;
			width: 576px;
			float: left;
			> p {
				padding-bottom: 30px ;
			}
			
			h2 {
				line-height: 30px;
			}
			
			h5 {
				font-family: $demibold-font-stack ;
				font-size: 16px;
				line-height: 24px;
				color: #000001;
				padding: 10px 0;
			}
			
			.giftcard {
				margin-top: 26px;
				padding: 10px;
				border: 2px solid #db071a ;
				border-radius: 10px;
				overflow: hidden;
				img {
					padding-right: 10px;
					float: left;
				}
				
				.words {
					float: left;
					width: 200px;
				}
				h4 {
					font-family: $demibold-font-stack ;
				}
			}
				
			.welcomegift {
				margin-top: 26px;
				padding: 26px;
				border: 1px solid $grey-border-colour ;
				border-radius: 10px;
				overflow: hidden;
				img {
					padding-right: 26px;
					float: left;
				}
				
				.words {
					float: left;
					width: 350px;
				}
			}
		}
		
		ul {
			list-style: disc;
			
			ul, ol {
				padding-left: 40px;
			}
			
			ol {
				
				li {
					padding: 0;
					font-size: 13px;
					list-style: decimal;
				}
			}
			
			ul {
				
				li {
					font-size: 16px;
					padding: 3px 0;
				}
			}
		}
		
		li {
			padding: 5px 0;
		}
		
		p {
			font-size: 16px;
			margin-bottom: 8px;
			font-family: $normal-font-stack ;
			font-weight: 300;
		}
		
		h4 {
			font-family: $demibold-font-stack ;
			padding: 15px 0 10px;
		}
				
		.rhs {
			background-color: $light-background-colour;
			padding: 25px;
			margin-left: 30px;
			float: left;
			width: 369px;
			overflow: hidden;
		}
	}
	.also-bought {
		display: inline-block;
		width: 20%;
		margin: 0 2%; 
		text-align: center;
		
		img {
			width: 100%!important;
			max-width: 234px;
		}
		
		p {
			padding: 10px 0;
		}
	
		a {
			text-decoration: none;
			color: $white;
			background: $red;
			padding: 5px 40px;
			text-align: center;
			margin: 0 auto;
		}
	}
	
}
@media screen and (max-width: 650px) {
	body.receipt {
		
		div.logo.customBrandedDomain {
			margin: 0 auto;
		}
		
		.magazine-header {
			
			h1 {
				width: 100%;
				font-size: 20px;
				line-height: 25px;
				padding-left: 20px;
				padding-right: 20px;
			}
			
			.blue-flash {
				height: 40px;
				
				h1 {
					font-size: 20px;
				}
				
			}
		}
		h1.pull-left {
			float: none!important;
			padding-left: 0px;
			text-align: center;
			width: 100%;
			font-size: 38px;
			line-height: 40px;
		}
		.order-summary {
			div.responsiveDiv {
				display: block!important;
				width: 100%!important;
			}
			div.col-delivery {
				padding-top: 15px;
				clear: both;
			}
			div.col-extras {
				p {
					padding-left: 22px;
				}
			}
		}
		.order-info {
			padding: 24px 0px;
			
			.lhs, .rhs {
				float: none;
				width: 100%;
				clear: both;
				margin-left: 0px;
			}
			.rhs {
				padding: 15px;
			}
		}
		
		.also-bought {
			width: 45%;
			margin: 11px 2%;
			
			img {
				width: 100%!important;
			}
		
			a {
				white-space: nowrap;
			}
		}
		
		form {
			.button {
				font-size: 15px !important;
			}
		}
	}
}
@media screen and (max-width: 1025px) {
	.singleissue {

		&:before {
			left: 0px !important;
		}
	}
}