.combined-products {
	
	.subsTab_container.secondaryNav_container {
	
		.subsTab_tab.footerNav {
			margin: 0 auto 0 -14px !important;
		}
	}
	
	.magazine-header {
		#product_pageheadlinesubhead ~ h2, #product_pageheadlinesubhead ~ h3, 
		#product_pageheadlinesubhead ~ h4, #product_pageheadlinesubhead {
			text-align: left!important;
			padding-left: 0px!important;
			padding-top: 0px!important;
		}
	}
	
	.standalone-product {
		
		.standaloneTextRow {
			td {
				background: white !important;
			}
		}
		.includeText {
			background: white !important;
			
			&:before {
				border-bottom-color: white !important;
			}
		}
	}
}
.rhs-promises {
	border: 1px solid $grey-border-colour;
	margin-top: 20px;
	
	.ourPromise {
		margin-top: 10px!important;
		margin-bottom: 10px!important;
    
		.container {
			padding: 0 12px!important;
		
			.our-promises {
				margin-top: 0px!important;
				margin-bottom: 0px!important;
				
				.promise {
					width: 100%!important;
					text-align: left!important;
					background-repeat: no-repeat!important;
					background-position: center top!important;
					
					.tt-info {
						text-align: left!important;
						
						.more-info {
							line-height: 25px;
						}
						
						h3 {
							font-size: 17px!important;
							text-align: left!important;
							width: 100%!important;
							padding: 10px 0;
						}
						
						h3.promiseGuarantee {
							padding-top: 0px;
						}
					}
				}
			}
		}
	}
}

.pricingModule {
	border: 1px solid $grey-border-colour;
	
	#country-select-error {
		max-width: 100%!important;
	}
	
	.customBranded-border, .border {
		border-color: $light-background-colour;
	}
	
	.customBrandedBG {
		background: $light-background-colour!important;
	}
	
	.active {
		.customBrandedBG {
			background: $red!important;
			color: $blue-flash-text;
			
			&:before {
				border-color: $red transparent;
			}
		}
		.border {
			border-color: $red!important;
		}
	}
	.customBranded-border.active {
		border-color: $red!important;
		border-left: 1px solid $red !important;
	}
	#subscriptionTypeContainer {
		.card {
		
			&:nth-child(2) {
				border-left-width: 1px !important;
				margin-left: 5px;
				margin-right: 5px;
			}
		}
	}
	#pricingblock {
		.pricing-block {
			.block:not(.chosen) {
				p.changePlan {
					display: none;
				}
			} 
			.block {
				margin-top: 0.5rem!important;
				margin-bottom: 0.5rem!important;
				border: 1px $grey-border-colour solid !important;
			}
			.block.chosen {
				border: 1px solid $red !important;

			}
		}
		
		.pricing-block.expanded {
		
			.block {
				
				&:hover {
					background-color: #db071a1c!important;
				}
			}
		}
	}
	
}

@media screen and (min-width: 768px) and (max-width: 992px)  {
	.combined-products {
		.magazine-header {
			#product_pageheadlinesubhead+h2, #product_pageheadlinesubhead {
				font-size: 28px!important;
			}
		}
		.large {
			width: 33% !important;
		}
		.choose-offer {
			width: 100% !important;
		}
		.standalone-product {
		
			.standaloneHeadline {
				font-size: 30px;
				line-height: initial;
			}
			
			ul.list-inline {
				font-size: 14px;
				line-height: initial;
			}
			
			.rhs-promises {
				border: 0px solid;
				
				.ourPromise {
				
					.container {
				
						.our-promises {
				
							.promise {
								width: 24% !important;
								
								.tt-info {
									h3 {
										font-size: 14px !important;
										
										span {
											padding: 16px;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 650px)  {
	.combined-products {
	
		.magazine-header {
			#product_pageheadlinesubhead+h2, #product_pageheadlinesubhead {
				line-height: 27px!important;
				font-size: 20px!important;
			}
		}

		.zoomPDP {
			width: 75%!important;
		}
		p:not(.includeProduct):not(#lefthandside_headline):not(.feefo_header) {
			line-height: 24px !important;
			
			span.followedBy {
				font-size: 12px;
				line-height: 12px;
			}
		}	
		
		h3.chooseSub {
			font-size: 24px;
		}
		.row {
			margin-left: 0px!important;
			margin-right: 0px!important;
		}
		.standalone-product {
		
			.standaloneHeadline {
				font-size: 30px;
				line-height: initial;
			}
			
			ul.list-inline {
				font-size: 14px;
				line-height: initial;
			}
			
			.standaloneCovers {
			
				.large {
					padding: 0;
					
					img {
						width: 75%
					}
				}
				
				.choose-offer {
					border: 0px solid;
					padding: 0px;
				}
				
				.pricingModule {
				
					.pricingHeader {
						text-align: center;
						height: auto !important;
					}
				}
			}
		}
	}
	#subscriptionTypeContainer {
		.card {
		
			&:nth-child(2) {
				margin-left: 0px!important;
				margin-right: 0px!important;
			}
		}
	}
}