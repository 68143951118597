body.modulesInBasket {
	button.addCourse {
		span {
			&:before {
				content: 'Upgrade';
			}
		}
	}
}
body:not(.modulesInBasket) {
	button.addCourse {
		span {
			&:before {
				content: 'Add to basket';
			}
		}
	}
	.showIfModulesInBasket {
		display: none!important;
	}
}
.withPartsContainer {
	button.button:not(.addCourse) {
		span {
			&:before {
				content: 'Add module to basket';
			}
		}
	}
	button.button:disabled {
		background-color: $grey-border-colour !important;
		
		&:hover {
			background-color: $grey-border-colour !important;
		}
	
		span {
			&:before {
				content: 'Added';
			}
		}
	}
}
.withparts-header, .withparts.midpage {
	background-color: #ededed;

	.was.price {
		text-decoration: line-through;
	}
}
.withparts-header {
	h1 {
		color: black;
	}
}
.withparts-description {
	ul {
		list-style-type: disc;
		list-style-position: inside;
	}
	.zoomPDP {
		width: 15em;
		margin: 10px 0;
	}
}
#midpage-pricing.footerNav {
	position: fixed;
	bottom: 0;
	left: 0;
	margin: 0;
	z-index: 9;
	width: 100%;
}
	
#withparts {
	.responsiveATB {
		position: absolute;
		bottom: 0;
		right: 15px;
		text-align: right;
	}
}
@media screen and (max-width: 768px) {
	#withparts {
		.responsiveATB {
			position: relative;
			margin-top: 15px;
			right: 0px;
			text-align: left;
		}
	}
	
	#midpage-pricing.footerNav {
	
		button {
			display: block!important;
			width: 100%;
		}
	}
	
	#subscriptioncontainer.withparts-header {
		width: auto!important;
	}
}