.magazine-guides {

    h2 {
        font-size: 25px;
        letter-spacing: -0.5px;
        padding-bottom: 11px;
    }

    p {
        line-height: 19px;
        margin-bottom: 22px;
    }

    .guides-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        max-width: 1200px;
        
        .guides-logos {
            width: 11%;
            margin: 0px 5.5px;
            padding-bottom: 35px;

            .guides-logos-link {
                text-decoration: none;

                .guides-img {
                    display: flex;
                    justify-content: center;
                    background-color: rgba(219, 63, 73, 0.10);
                    width: 100px;
                    height: 100px;
                    align-items: center;
                    border-radius: 50%;
                    margin: 0px auto 15px;

                    img {
                        height: 65px;
                        width: 65px;
                    }
                }

                .guides-text {
                    color: $primary-color;
                    text-align: center;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    max-width: 113px;
                    width: 100%;
                    margin: auto;
                }
            }
        }
    }
}

@media only screen and (max-width: 991px) and (orientation: landscape) {
    .magazine-guides {
        
        .guides-container {
            
            .guides-logos {
                width: 11%;
                margin: 0px 13px;
            }
        }
    }
}

@media only screen and (max-width: 991px) and (orientation: portrait) {
    .magazine-guides {
        
        .guides-container {
            
            .guides-logos {
                margin: 0px 13px;
            }
        }
    }
}

@media screen and (max-width: 750px) {
    .magazine-guides {
        margin: 32px 0px;

        .browserGuidesContainer {
            overflow: auto;

            .guides-container {
                width: 1057px;
                margin-left: 0 !important;
                margin-right: 0 !important;
                justify-content: start;

                .guides-logos {
                    width: 132px;
                    margin: 0px;
                }
            }
        }
    }
}