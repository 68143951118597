.subscription {
	overflow: hidden;
	padding-top: 50px;
	
	#gifttext {
		margin-bottom: 35px;
	}
	
	.promises .headline {
		text-transform: uppercase;
		font-weight: bold;
		font-size: 16px;
		line-height: 22px;
	}
	
	.promises ul {
		list-style: "\f00c";
		font-family: "Font Awesome 6 Free";
		font-weight: 900;
		font-size: 20px;
		color: $red;
	}
	
	.promises li {
		padding-top: 25px;
		padding-left: 10px;

		h5, strong {
			font-family:$demibold-font-stack;
			color: initial;
			font-size: 16px;
			line-height: 22px;
		}

		p {
			color: initial;
			font-weight: normal;
			padding-left: 0px;
		}
	}
	
	p {
		padding-left: 10px;
	}
	
	h2 {
		clear: both;
		padding-bottom: 24px;
	}

	h3 {
		padding: 20px 0;
		text-align: left;
	}
	
	.double-no {
		h3 {
			width: 170px;
			padding-top: 0;
			margin-top: -4px;
		}
	}
	.double-xforx {
		h3 {
			width: 170px;
		}
		.price-statement {
			height: 72px !important;
		}
	}
	.underline {
		width: 100% ;
		border-bottom: 1px solid $grey-border-colour;
	}
	
	.country-select-holder, .payment-title  { 
		padding-left: 65px; 
	}
	
	.country-select-holder, .offers-holder, .payment-title, .gift-block  { 
		position: relative;
		
		&:before {
			position: absolute;
			left: 0;
			box-sizing: border-box;
			padding: 8px 0;
			width: 45px;
			height: 45px;
			text-align: center;
			background: $stepBG;
			border: $stepBorder;
			color: $stepColour; 
			font: 23px $demibold-font-stack;
		}
		
		h2, h3 {
			font-family: $demibold-font-stack;
			font-size: 22px;
			line-height: 24px;
		}
	}

.offers-holder {
	
	h2 {
		padding-left: 65px;
	}
}
	
	.country-select {
		overflow: hidden;
		margin-bottom: 31px;
		position: relative ;
		
		.country-field {
			float: left;
			border-width: 1px;
			border-color: rgb(194, 194, 194);
			border-style: solid;
			width: 289px;
			height: 39px;
			margin-right: 20px;
			font-size: 16px;
			line-height: 19px;
			padding-left: 16px;
			color: $countrySelectColour;
			letter-spacing: $countrySelectSpacing;
			text-transform: $countrySelectText;
			font-family: $countrySelectFont;
			font-weight: 300;
			background: $countrySelectTransparent_icon no-repeat right, linear-gradient(90deg, $white 0%, $white 81%, $red 81%) !important;
		}
		.country-field:focus {
			background: none;
		}
		.country-field[disabled] {
			background-color: rgb(241, 241, 241);
		}
	}
	
	.offers {
		overflow: visible;
		padding-bottom: 0;
		
	}
	
	.offer.active {
		.hider {
			position: absolute;
			display: block;
			bottom: -3px;
			left: 0;
			width: 100%;
			height: 6px;
			background-color: $white;
			z-index: 2;
		}
	}
	.offer {
		border-width: 1px;
		border-color: rgb(194, 194, 194);
		border-style: solid;
		padding-top: 32px;
		height: 212px;
		position: relative ;
		box-sizing: border-box;
		border-bottom: 0;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		width: 50%;
		min-height: 265px;
		cursor: pointer;
		
		&:not(.active) {
			border: 1px solid transparent;
		}
		
		.definition {
			font-size: 22px;
			color: rgb(33, 40, 44);
			font-weight: bold;
			line-height: 24px;
		}

		.call-to-action {
			position: relative;
			padding: 0 12px;
			text-align: center;
			font-family: $demibold-font-stack;
			
			&:before {
				content: '';
				position: absolute;
				top: -10px;
				left: 37%;
				right: 37%;
				display: block;
				height: 1px;
				background: $medium-background-colour;
			}
    
			p {
				font-size: 22px;
				color: $red;
				padding: 0 8px 0 0;
				display: inline-block;
				vertical-align: middle;	
			}
			.from {
				display: block;
				margin-bottom: 5px;
			}
			button {
				display: none;
				vertical-align: middle;	
			}
		}
		
		.right-side {
			margin-top: 15px;
			height: 100px;
		}
		
		.left-side, .right-side {
			float: none;
			width: 100%;
			text-align: center;
		}
		
		.icon {
			margin: 0 auto;
		}
		
		.text:before {
			font-size: 22px;
			color: rgb(33, 40, 44);
			font-weight: bold;
			line-height: 24px;
			display: block;
			padding-bottom: 12px;
		}
		
	}
	.selected {
		opacity: 1;
	}
	.selected:after {
		display: block;
		background-image: url('/images/subs-box-pointer.webp');
		width: 42px;
		height: 13px;
		position: absolute;
		bottom: -13px;
		left: 128px;
		content: '';
	}
	
	.not-selected {
		opacity: 0.5;
	}
	
	.offer.print {
		.icon {
			background-image: $print_icon;
			width: 87px;
			height: 55px;
		}
		.text:before {
			content: 'Print subscription';
		} 	
	}
	
	.offer.digital {
		.icon {
			background-image: $digital_icon;
			width: 67px;
			height: 56px;
		}
		.text:before {
			content: 'iPad + iPhone subscription';
		} 	
	}
	
	.offer.digital.XWP {
		.text:before {
			content: 'iPad subscription';
		} 	
	}
	#messagebox {
		margin-top: 30px;
		padding-left: 70px;
		padding-right: 55px;
	}
	.choose-offer.active {
		display: block !important;
		border: 1px solid $grey-border-colour;
		padding-top: 0;
		position: relative;
	}
	.choose-offer.active.print {
		border-top-left-radius: 0;
	}
	.choose-offer.active.digital {
		border-top-right-radius: 0;
	}
	.choose-offer, .country-select-holder {
		overflow: hidden;
		padding: 4px 0 0 0;
		width: 100%;
	}
	
	.choose-offer {

			
		.bottomerror {
			padding: 3px;
			border: 1px solid $red;
			margin: 10px 15px;
			text-align: center;
			display: none;
			clear: both;
			
			.infoerror {
				color: $white;
				background: $red;
				border-radius: 50%;
				width: 23px;
				height: 23px;
				line-height: 12px;
				border: 0px;
				margin-right: 10px;
				font-weight: bold;
				font-size: 19px;
			}
		}
		
		label {
			box-sizing: border-box;
			display: inline-block;
			margin-left: 10px;
			border: 1px solid $grey-border-colour;
			padding: 6px;
			width: 46%;
			text-align: center;
		}
		label:hover .tooltip {
			opacity: 1;
			visibility: visible;
		}
		
		.tooltip {
			position: absolute;
			left: 20px;
			right: 20px;
			bottom: -68px;
			z-index: 10;
			opacity: 0;
			visibility: hidden;
			border-radius: $tooltipBorderRadius;
			padding: 10px 15px;
			background: $medium-background-colour;
			box-shadow: 0px 2px 3px 0px $black;
			transition: opacity 0.3s, visibility 0.3s;
			
			&:before {
				content: '';
				position: absolute;
				top: -15px;
				left: 253px;
				border-bottom: 15px solid $medium-background-colour;
				border-left: 15px solid transparent;
				border-right: 15px solid transparent;
			}
		}
		
		label.checked {
			border-color: #333;
			background: $medium-background-colour!important;
		}
		.gift-block {
			&:before {
				content: $step3;
				top: 7px;
				left: 5px;
			}
			&:after {
				content: $step4;
				top: 91px;
				position: absolute;
				left: 5px;
				box-sizing: border-box;
				border-radius: 50%;
				padding: 8px 0;
				width: 45px;
				height: 45px;
				text-align: center;
				background: $stepBG;
				border: $stepBorder;
				color: $stepColour; 
				font: 23px $demibold-font-stack;
			}
		}
		.labelled {
			position: relative;
			overflow: visible;
			padding: 20px;
			margin-bottom: 25px;
			height: 25px;
			background: $gift_icon;
			background-repeat: no-repeat!important;
			background-position: 57px 13px!important;
			
			input[type="radio"] {
				display: none!important;
			}
			
			.gift {
				float: none;
				display: inline-block;
				vertical-align: middle;
				
				img {
					padding-right: 11px;
				}
				
				h3 {
					float: none;
					display: inline-block;
					vertical-align: middle;
				}
			}
			
			h3 {
				padding: 0;
			}
			
			h3.pull-left {
				padding-left: $giftPadding;
			}
			
			.form-group {
				float: right;
				vertical-align: middle;
				box-sizing: border-box;
				width: 65%;
			}
			
			img {
				float: left;
				padding-right: 5px;
				margin-top: -5px;
				padding-left: 39px;
				display: none;
			}
			.gifterror {
				visibility: hidden; 
			}
			&.error {
				label {
					background: rgba(214, 9, 9, 0.5);
					border-color: #ff0000;
				}
				.gifterror {
					visibility: visible; 
					color: $red;
				}	
			}
		}
		
		.blocks-container {
			text-align: center;
		}
		
		.print-pricing-blocks-container {
			.print-pricing-block {
				display: none;
				text-align: center;
				&:first-child {
					display: block;
				}			
			}
		}
		
		.offer-box {
			padding: 0 0 12px 0;
			display: inline-block;
			text-align: left;
			
			h3 {
				text-align: center;
				border-top: 6px solid $medium-background-colour;
				padding-top: 10px;
				padding-bottom: 5px;
			}
			h3.rolling {
				display: none;
			}
			.smaller {
				font-size: 18px;
			}
			.even-smaller {
				font-size: 15px;
				display: block;
			}
			.asa {
				font-size: 15px;
				height: 42px;
				line-height: 20px;
				margin-top: 8px;
			}
			.price-point {
				float: right;
				width: 162px;
				margin: 0 18px;
			}
			.price-point.rolling {
				width: 400px;
				margin: 0 auto;
				text-align: center;
			}
			.price-point:first-child {
				float:left !important;
			}
			
			.price-statement {
				line-height: 24px;
				height: 48px;
			}
			.price-offer {
				font-size: 22px;
				color: $red;
				padding-bottom: 27px;
			}
			.price-point.ppi-yes .price-offer {
				font-size: 20px;
			}
			.p-26 {
				padding-bottom: 26px;
			}		
		}
		.offer-box.double-yes-no, .offer-box.double-yes, .offer-box.double-yes-yes {
			width: 400px !important;
			margin: 0 auto;
			float: none; 
		}
		.offer-box.ccc {
			h3.fixed:nth-child(2) {
				visibility: hidden;
			}
			h3.rolling {
				display: block;
				position: absolute;
				width: 400px;
			}
		}
		.offer-box.underprice {
			text-align: center;
		}
	}	
	
	.editor-message {
		padding-top: 24px ;
		p {
			padding-bottom: 24px;
		}
		
		.img-circle {
			border-radius:50% ;
			width: 80px;
			border: 1px solid $grey-border-colour;
		}
	}	
}
.offer-box > .asa {
	display: none;
}
.ccc {
	.price-point .asa {
		display: none ;
	}
	> .asa {
		display: block ;
	}
	
	.more-info.bottom {
		left: initial;
		right: 29px;
		height: 140px !important;
	}
}
.quickview {
	.subscription {
							
		.country-select {
			margin-bottom: 15px;
		}
	}
}

