body.bootstrap {
	.tesco, .paypal#cascadeaccordion-container, .prepaidCheckout {

		h1, h2, h3, h4, h5, h6, strong {
			font-family: $demibold-font-stack;
		}

		.pca {
			padding: 0 12px;
			box-sizing: inherit;
		}
		
		label {
			font-size: 15px;
			float: none;
			width: 100%;
			text-align: left;
		}
		
		.checkout {
			.cascadeaccordion {
				min-height: 425px !important;

				.panel-detail {
					overflow: visible;
				}
				.panel-title {

					.panel-name {
						font-size: 19px;
					}
				}
			}
		}
		
		.covers {
			left: 45px;
		}
	}
	.voucher_checkoutForm {
		
		&:after {
			display: block;
			content: 'Please apply your voucher first';
			text-align: center;
			color: #db071a;
			font-weight: bold;
		}
		.voucher_CheckoutBTN {
			background: gray !important;
			border-color: gray !important;
			opacity: 0.5 !important;
		}
	}
}

@media screen and (max-width: 650px) {
	body:not(.bootstrap) {
		
			.container.tesco, .container.paypal#cascadeaccordion-container, .prepaidCheckout {
				width: 90%!important;
				
				.site-header {
					padding: 20px 5px;
				}
				
				.basket {
					padding: 10px;
				
					table {
				
						th {
							display: none;
						}
						
						td {
							display: block;
							width: auto;
						}
						
						.startdatebox, .col-payment-methods {
							border-bottom: 0px;
						}
						.line.voucher {
							display: none;
						}
					}
				}
				
				.checkout {
					padding: 0;
					
					li {
						padding: 4px 0;
						
						label {
							text-align: left;
							float: none;
						}
					}
					
					.panel-title {
						width: 100%;
					}
					
					.extratext {
						float: none;
					}
					.cascadeaccordion {
						input[type=text] {
							padding: 4px 6px;
							margin: 5px 0;
							width: 95%;
							height: 40px;
						}
					}
				}
				
				.paynow {
					width: 100%!important;
					text-align: center;
					
					button.pull-right {
						float: none!important;
					}
				}
			}
		}
	body.bootstrap {
		.tesco, .paypal#cascadeaccordion-container, .prepaidCheckout {

			.checkout {
				padding: 0;

				.cascadeaccordion {

					#panel1 {
						max-height: 80px;
					}
				}
			}
		
			.covers {
				left: -2px;
			}
		}
	}
}