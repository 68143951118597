.promCodeBanner {
	z-index: 99;
	padding: 5px 9px;
	text-align: center;
	position: relative;
	background-color: $light-background-colour ;
	border-bottom: 0.5px solid $grey-border-colour;
	
	label {
		color: $primary-color;
		font-size: 16px !important;
		
		span, a.promCodeChange {
			color: $red;
		}

		span {
			text-transform: uppercase;
		}
	}
	.tt-info {
		margin-left: 10px;
		
		&:hover {
				i {
					filter: invert(0);
				}
			}

		i {
			filter: invert(1);
		}

		.more-info {
			box-shadow: 0px 4px 7px -3px $black;
		}
	}
}
.promcodeContainer.navSticky, .basketContainer.navSticky, .promiseBullets.navSticky {
	margin-top: 138px;
}
.promiseBullets.navSticky {
	box-shadow: 0px 4px 7px -3px #000000;
}

.headerText {
	font-family: $demibold-font-stack;
	color: $off-black-text;
	font-weight: 700;
	width: 700px;
	font-size: 14px !important;
	margin-top: -15px;
	margin-bottom: 5px;
	padding-left: 11px;
	
	.officialStrike {
		position: relative;
		
		&:after {
			content: '';
			position: absolute;
			bottom: -6px;
			left: 0;
			width: 100%;
			height: 6px;
			background-repeat: no-repeat;
			background-image: url(/images/official_strike.svg);
		}
	}
}

@media screen and (max-width: 900px) {
	.promcodeContainer.navSticky, .basketContainer.navSticky {
		margin-top: 112px;
	}
	.promiseBullets.navSticky {
		display: none;
	}

	nav.mobileNav.navSticky {
		box-shadow: 0px 4px 7px -3px #000000;
	}

	.promCodeBanner {

		.tt-info {
			margin-left: 0px;

			&:hover {
				.more-info {
					left: -329px;
					width: 350px;
				}
			}
		}
	}
	.home-link {
		overflow: unset !important;
		
		.headerText {
			width: 370px;
			font-size: 11px !important;
			margin-top: 38px;
			margin-bottom: 0px;
			padding-left: 1px;
		}
	}
}