.category-box, .guide-boxes {
	background-color: #525252;
	
	img.bg {
		width: 100%;
		height: 100%;
		top: 0;
	}
	
	.wholebox {
		color: $white;			
	}

}
.atoz-mega-box {
	overflow: hidden;
	
	&:hover {
		background-color: $black;
	
		img.bg {
			opacity: 0.7!important;
		}
	}
	
	img.bg {
		width: 100%;
		height: 100%;
		opacity: 1!important;
		transition: opacity 1s;
	}
	
	.wholebox {
		color: $white;
		text-shadow: 3px 3px 3px $black;			
	}

	.redText {
		color: $red;
		text-shadow: 0 0 0 $red;
	}
	
}
.atoz-mega-box.whiteBG {	
	
	.wholebox {
		color: $black;
		text-shadow: 3px 3px 3px #bfbebe;			
	}
}
.atoz-mega-box.white {
	
	.wholebox {
		text-shadow: 0 0 0 $white;			
	}
	
}
.guide-boxes, .atoz-mega-box {	
	margin: 0 0 30px 38px;
}
.category-box {	
	margin: 0 0 30px 38px;
}

.category-box, .atoz-mega-box, .guide-boxes {
	width: 160px;
	height: 104px;
	color: $white;
	float: left;
	position: relative;
	display: table ;
	
	img.bg {
		position: absolute;
		border: none;
	}
	
	.wholebox {
		width: 100%;
		height: 100%;
		display: table-cell;
		vertical-align: middle;
		text-align: center;
		position: inherit;
		line-height: 25px;
		font-size: 22px;
		text-decoration: none;
		text-shadow: 2px 2px 1px $black;
	}
}

.category-box.hidecategory, .guide-boxes.hidecategory {
	display: none ;
}
.category-box.white, .atoz-mega-box.white, .guide-boxes.white {
	border: 1px solid $grey-border-colour;
	width: 158px;
	height: 102px;
	background-color: $white;
	color: $black;
	a {
		font-size: 16px;
		margin-top: 22px;
		line-height: 28px;
		color: $red;
		text-decoration: underline;
	}
}

.category-box.whiteonred, .guide-boxes.whiteonred {
	border: 1px solid $grey-border-colour;
	width: 158px;
	height: 102px;
	background-color: $white;
	color: $black;
	a {
		font-size: 16px;
		line-height: 28px;
		color: $white;
		text-decoration: none;
	}
}
	
.megamenu {
	padding: 32px 24px;
	width: 994px;
	height: 240px;
	position: absolute;
	left: 0;
	top: 45px;
	border-left: 1px solid $grey-border-colour;
	border-right: 1px solid $grey-border-colour;
	border-bottom: 1px solid $grey-border-colour;
	background-color: $white;
	z-index: 12;
	
	.menu-row {
		clear: both;
	}
}
.megamenu.atoz-mega-menu {
	height: auto;
}
.megamenu.category-mega-menu {
	height: auto;
}
#gift-mega-menu {
	height: 120px;
}

.offer-boxes {
	padding: 32px 85px;
	overflow: hidden;
	clear: both;
	width: 964px;
	text-align: center;
	
	.offer-box {
		width: 450px;
		height: 332px;
		display: inline-block;
		position: relative ;
		margin: 0 8%;
		
		.cover {
			position: absolute;
			width: 172px;
			height: 215px;
			top: 32px;
			left: 32px;
			
			img {
				width: 100%;
				height: 100%;
				box-shadow: 4px 4px 10px #770000;
				@include transform(rotate(-2deg));
			}
		}

		a.offerBtn {
			height: 37px !important;
			width: auto !important;
			padding: 10px;
		}
		
		.bubble {
			position: absolute;
			background-color: #ddd;
			width: 60px;
			height: 60px;
			border-radius: 34px;
			color: $white;
			top: 210px;
			left: 160px;
			z-index: 2;
			display: table;
			table-layout: fixed;
			padding: 4px;
			
			span {
				display: table-cell;
				vertical-align: middle;
				font-size: 14px;
				line-height: 18px;
				text-align: center;
			}
		}
		
		.top-part {
			background-color: #ddd;
			height: 105px;
			color: $white;
			border-bottom: none;
			
			h2 {
				margin-right: 6px;
			}
		}

		.bottom-part {
			background-color: $white;
			height: 225px;
			border: 1px solid $grey-border-colour;
			border-top: none;
			
			.magname {
				padding: 16px 0 8px 0;
				height: 48px ;			
				font-size: 20px ;
				text-overflow: ellipsis;
			}
			
			.ppi {
				.price {
					float:left;
					font-family: $demibold-font-stack;
					font-size: 33px;
					color: $red;
					line-height: 45px;
				}
				.per-issue {
					float:left;
					height: 45px;
					color: $red;
					margin: 5px;
					line-height: 16px;
				}
				
				.chars7 {
					font-size: 42px;
				}
				.chars8 {
					font-size: 36px;
				}
				.chars9 {
					font-size: 33px;
				}
				
			}
			.saving {
				clear: both;
				font-size: 18px;
				font-weight: bold;
				margin-bottom: 10px;
			}
			
		}

		h2 {
			line-height: 32px;
			margin-top: 20px;
			a {
				text-decoration: none;
				color: $white;
			}
		}
		
		.rhs {
			width: 227px ;
			float: right;
		}
	}
	
	.blue {
		.bubble, .top-part {
			background-color: $turquoise;
		}
	}
}