.iframe {
	.container {
		width: 100%;
	}
	.subsTab_tab, button.viewOffers_btn {
		display: none !important;
	}

	.magazine-header {
		position: relative;

		.background {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		h1 {
			width: calc(100% - 80px);
			font-size: 3vw;
			line-height: 2.9vw;
			margin-left: auto;
			margin-right: auto;
		}

		.covers {
			bottom: -45px;
		}

		.look-inside {
			display: none;
		}
		
		.blue-flash {
			img {
				margin-top: -36px !important;
			}
		}
		.feefoStars {
			margin: 10px 11px 0;
		}
	}
	.quickview:not(.modal) {
		display: none;
	}
	
	.our-promises .full-width {
		width: 100% ;
	}

	.basket, .checkout {
		padding: 10px;
	}
	
	.checkout {
		#panel5 {
			.rhs {
				width: 320px!important;
			}
		}
		.review-dd {
			.lhs {
				width: 57%;
			}
			.rhs {
				width: 40% !important;
				margin-left: 2%
			}
		}
	}
	.our-promises {
		.promise {
			h3, p {
				width: 90%;
			}
		}
	}
	
	&.receipt {
		.order-summary {
			padding: 24px 10px;
		}
		.order-info {
			padding: 24px 0;
			.lhs, .rhs {
				width: 44%;
			}
			.rhs {
				float: right;
			}
			
			.lhs .welcomegift {
				padding: 8px;
				img {
					padding-right: 8px;
				}
				.words {
					width: 85%;
				}
			}
		}
		.row.newsletter-signup {
			display: none;
		}
	}
}
.iframe.newnormal {
		#subscriptioncontainer {
			position: relative;
			background-color: #f8f8f8;
		}
		#subscriptioncontainer .large{
			font-size: 36px;
			line-height: 50px;
		}
		#mysubscriptionpanel {
			position: absolute;
			top: 39px;
			right: 25px;
			padding-top: 20px;
		}
		#mysubscriptionpanel .top-part {
			border: 2px #000 solid;
			background-color: #fff;
			border-bottom: none;
			overflow: visible;
			width: 100%;
			padding-top: 25px;
			float: left;
		}
		
		#mysubscriptionpanel .asa {
			clear: both;
			width: 100%;
			text-align: center;
			background: #ddd;
			padding: 12px 0;
			border: 2px #000 solid;
			border-top: none;
		}
		
		#odd-arrow {
			position: absolute;
			top: 135px;
			left: 20px;
		}
		
		#mysubscriptionpanel .body {
			margin-top: 5px;
			padding: 25px 30px 30px 30px;
			overflow: visible;
			float: left;
		} 
		#mysubscriptionpanel .lhs, #mysubscriptionpanel .rhs {
			width: 50%;
			float: left ;
			background-color: #fefefe;
		}
		#mysubscriptionpanel .rhs {
			float: right ;
			width: 48%;
		}
		#rhsprintbenefit + div {
			display: contents;
		}
		.subscription .country-select .country-field {
			width: 84%;
			margin-right: 0;
		}
		#rhs-mag-cover, #rhs-mag-coverImage{
			margin: 0 auto;
			display: block;
			width: 100%;
		}
		
		#mysubscriptionpanel .rhs.gift-yes img {
			width: 80%;	
		}
		
		.centred {
			margin: 0 auto;
			text-align: center;
		}
		.simple-inline {
			display: inline;
			font-size: 15px;
			width: 105px;
			float: none;
			height: 40px;
			margin: 2px 0;
			background-color: #ffffff;
		}
		#magazinesize-select {
			width: 190px;
		}
		#magazinetype {
			padding-top: 4px;
		}
		#giftheadline {
			font-weight: bold;
		}
		#gifttext, #giftheadline {
			text-align: center;
		}
		#gifttext {
			margin-bottom: 0!important;
		}
		.giftbox {
			margin-top: 13px;
		}
		.giftbox img {
			display: block;
			margin: 0 auto;
			width: 50%!important;
		}
		#giftblock label {
			width: 120px;
		}
		#giftblock .labelled {
			margin: 0;
			padding: 0;
			background: none;
			height: 40px;
		}
		#gifterror {
			visibility: hidden; 
			display: none;
		}
		.gifterror.bottomerror {
			display: none;
		}
		.gifterror.bottomerror.error {
			display: inline-block;
			margin: 10px 15px;
			text-align: center;
			width: 95%;
		}
		.gifterror.bottomerror p {
			padding: 3px;
			border: 1px solid #db071a;
		}
		.infoerror {
			color: #fff;
			background: #db071a;
			width: 23px;
			height: 23px;
			line-height: 12px;
			margin-right: 10px;
			font-weight: bold;
			font-size: 19px;
			box-sizing: border-box;
			display: inline-block;
			padding: 6px;
		}
		#giftblock.error p, #giftblock.error label {
			color: #db071a;
		}
		#giftblock.error label {
			border-color: #db071a;
		}
		
		#giftblock.error #gifterror {
			visibility: visible; 
		}
		.subscription .country-select {
			margin-bottom: 20px;
		}
		.pricing p strong span {
			display: block;
		}
		.pricing-container {
			margin: 10px 0 ;
			position: relative;
			z-index: 8;
		}
		
		#pricingblock form {
			display: none;
		}
		
		.pricing-block {
			background-color: #fff;
			width: 100%;
		}
		.pricing-block, .country-field {
			cursor: default;
		}
		.subscription .country-select .country-field, .pricing-block .block {
			background: url('/images/selectbg-large3.webp') no-repeat right;
		}
		.pricing-block .block {
			border: 1px #c2c2c2 solid;
			padding: 0 40px 0 5px ;
			background-size: contain;
		}
		
		.pricing-block.expanded .block:first-child {
			border-top: 1px #c2c2c2 solid ;
		}

		.pricing-block.expanded .block {
			background: none;
		}
		.pricing-block.expanded .block:hover {
			background-color: #ccc;
		}
		.pricing-block.expanded .block.chosen {
			background: url('/images/tick.webp') no-repeat 95% 50%;
			
		}
		
		#intro_more.fold-out {
			margin-bottom: 100px;
		}
		
		#rhsgifttext img {
			max-width: 100px;
			max-height: 100px;
			float: right;
		}
		.messagebox.yesGift  {
			margin-top: 735px!important;
		}
		.messagebox.noGift {
			margin-top: 695px!important;
		}
		.messagebox.error {
			margin-top: 705px!important;
		}
		.right-column {
			width: 52%;
			padding-right: 20px;
		}
		.faqs_container .faqs {
			width: 81.75%;
		}
		.faqs_container {
			margin: 75px auto;
		}
}
@media screen and (min-width: 751px) {
	#subscriptioncontainer {

		.right-column {
			min-width: 660px;

			#mysubscriptionpanel {
				min-width: 675px;
			}
		}
	}
}
@media only screen and (max-width: 800px) {
	.iframe.newnormal {
		.container .subscription .country-select .country-field, .pricing-block .block {
			background: url('/images/selectbg-large3.webp') no-repeat right ;
		}
		.pricing-block .block {
			border: 1px #c2c2c2 solid;
			padding: 0 40px 0 5px ;
			background-size: contain;
		}
	}
	.feefoStars {
		margin: 10px 1px 0 !important;
		width: 100%;
	}
	.iframe.newnormal, .quickview:not(.modal) {
		margin: 0;
		width: auto;
	
		.container {
			width: 90%;
			font-size: 14px;
			line-height: 18px;
		}
		
		#subscriptioncontainer {
			position: relative;
			background-color: #f8f8f8;
			
			.subscription {
				padding-top: 0px;
			}
		}
		#subscriptioncontainer .large{
			font-size: 26px;
			margin-top: 10px;
			line-height: 50px;
		}
		#mysubscriptionpanel {
			padding-top: 20px;
			padding-bottom: 20px;
			position: relative;
			width: 100%;
			right: 0;
			top: 0;
		}
		#mysubscriptionpanel .top-part {
			border: 2px #000 solid;
			background-color: #fff;
			border-bottom: none;
			overflow: visible;
			width: 100%;
			padding-top: 5px;
			float: left;
		}
		
		#mysubscriptionpanel .asa {
			clear: both;
			width: 100%;
			text-align: center;
			background: #ddd;
			padding: 12px 0;
			border: 2px #000 solid;
			border-top: none;
		}
		
		#mysubscriptionpanel .body {
			padding: 25px 13px 30px;
			overflow: visible;
		} 
		#mysubscriptionpanel .lhs,
		#mysubscriptionpanel .rhs {
			background-color: #fefefe;
			float: none;
			width: 100%;
		}	
		.magazine-intro {
			padding: 32px 10px 0 10px;
			padding-bottom: 15px;
			min-height: 100px;
		}
		.subscription .country-select .country-field {
			width: 83%!important;
			margin-right: 0!important;
			height: 30px!important;
			font-size: 14px!important;
			padding-left: 9px!important;
		}
		.container .subscription .country-select {
			padding-top: 2px;
		}
		.flag {
			height: 23px;
			margin: 3px 4px 0 0;
		}
		#rhs-mag-cover, #rhs-mag-coverImage {
			margin: 0 auto;
			display: none;
			width: 100%;
		}
		
		#mysubscriptionpanel .rhs.gift-yes img {
			width: 80%;	
		}
		
		.centred {
			margin: 0 auto;
			text-align: center;
			width: 90%!important;
		}
		.simple-inline {
			display: inline;
			font-size: 14px;
			width: 105px;
			float: none;
			height: 30px;
			background-color: #ffffff;
		}
		#magazinetype {
			padding-top: 9px;
			padding-bottom: 8px;
		}
		#giftheadline {
			font-weight: bold;
		}
		#gifttext, #giftheadline {
			text-align: center;
		}
		#gifttext {
			margin-bottom: 0!important;
		}
		.giftbox {
			margin-top: 13px;
		}
		.giftbox img {
			display: block;
			margin: 0 auto;
			width: 50%!important;
		}
		#giftblock {
			margin-top: 6px;
		}
		#pricingblock {
			margin-top: 17px;
		}
		#giftblock label {
			width: 110px;
		}
		.subscription .choose-offer label {
			box-sizing: border-box;
			display: inline-block;
			border: 1px solid #c2c2c2;
			padding: 4px;
			text-align: center;
			margin-left: 0px;
		}
		.subscription .choose-offer label:nth-child(1) {
			margin-right: 10px;
		}
		.subscription .choose-offer label.checked {
			border-color: #333;
			background: #DEDEDE !important;
		}
		.subscription .choose-offer label input {
			display: none;
		}
		#giftblock .labelled {
			margin: 0;
			padding: 0;
			background: none;
			height: 40px;
		}
		#gifterror {
			visibility: hidden; 
		}
		.gifterror.bottomerror {
			display: none;
		}
		.gifterror.bottomerror.error {
			display: inline-block;
			margin: 10px 15px;
			text-align: center;
			width: 95%;
		}
		.gifterror.bottomerror p {
			padding: 3px;
			border: 1px solid #db071a;
		}
		.infoerror {
			color: #fff;
			background: #db071a;
			width: 23px;
			height: 23px;
			line-height: 12px;
			margin-right: 10px;
			font-weight: bold;
			font-size: 19px;
			box-sizing: border-box;
			display: inline-block;
			padding: 6px;
		}
		#giftblock.error p, #giftblock.error label {
			color: #db071a;
		}
		#giftblock.error label {
			border-color: #db071a;
		}
		
		#giftblock.error #gifterror {
			visibility: visible; 
		}
		.pricing p strong span {
			display: block;
			padding-top: 0px!important;
		}
		.container .subscription .block .pricing p {
			line-height: 22px;
			padding-left: 5px;
			font-size: 14px!important;
		}
		.pricing-container {
			margin: 10px 0 ;
			position: relative;
			z-index: 8;
		}
		
		#pricingblock form {
			display: none;
		}
		.container .subscription .pricing-container {
			padding: 0px;
			border: initial;
		}
		.container .subscription .pricing-container .block {
			border-bottom: #c2c2c2 1px solid;
			padding: 4px 0;
		}
		.container .subscription .block .pricing {
			width: auto;
		}
		.pricing-block {
			background-color: #fff;
			width: 100%;
		}
		.pricing-block, .country-field {
			cursor: default;
		}
		.pricing-block.expanded .block {
			border-top: none ;
			background: none;
		}
		.pricing-block.expanded .block:first-child {
			border-top: 1px #c2c2c2 solid;
		}
		.container .subscription .pricing-container .block:last-child {
			border-bottom: 1px #c2c2c2 solid;
		}
		.pricing-block.expanded .block:hover {
			background-color: #ccc;
		}
		.pricing-block.expanded .block.chosen {
			background: url('/images/tick.webp') no-repeat 95% 50%;
			
		}
		#intro_more.fold-out {
			margin-bottom: 10px;
		}
		
		#rhsgifttext img {
			max-width: 100px;
			max-height: 100px;
			float: right;
		}
		.promises {
			width: auto;
			padding-top: 0px;
			padding-left: 40px;
		}
		.subscription #messagebox {
			margin-top: 30px!important;
		}
		#lefthandside_text h5 {
			display: none;
		}
		.blue-flash {
			width: 100%;
			height: auto;
			padding: 10px 0px;
			text-align: center;
			
			h3 {
				line-height: 25px;
				padding: 4px;
			}
		}
		.right-column {
			width: 95% !important;
			padding: 0px;
			float: none;
			margin: 0 auto;
		}
		#blueflashtext, #nonukblueflashtext {
			width: 78%!important;
			float: right;
		}
		.product-panel {
			.prices {
				float: none;
			}
		}
		.faqs_container {
			display: none;
		}
	}
}
@media only screen and (max-width: 990px) and (min-width: 801px) {
	.iframe.newnormal {
	
		.left-column {
			padding-left: 30px;
			width: 22%;
			width: auto;
			padding-top: 0px;
			float: right;
			
			.promisesbox {
				width: 95%;
			}
		}
	}
}
@media only screen and (min-width: 801px) and (max-width: 990px) {
	.iframe {
		.copyright {
			background-image: none;
		}
		.magazine-header {
			.covers {
				width: 20%;
				height: auto;
				
				.small {
					display: none;
				}
				.large {
					width: 100%;
					height: auto;
					float: left;
					margin: 0;
				}
			}
		}
		.right-column {
			width: 67%!important;
			padding-right: 25px!important;
		}
		.left-column {
			padding-left: 30px;
			width: 22%;
		}
		
	}
}

@media only screen and (min-width: 701px) and (max-width: 1100px) and (orientation: portrait) {
	.iframe.newnormal {
		#mysubscriptionpanel {
			.body {
				float: none;
				
				.lhs, .rhs {
					float: none;
					display: inline-block;
					vertical-align: top;
				}
				.lhs {
					width: 50%;
				}
				.rhs {
					width: 48%;
					
					#rhs-mag-cover, #rhs-mag-coverImage {
						display: block;
						width: 65%;
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 1100px) and (orientation: landscape) {
	.iframe {
		
		.magazine-header {
			.covers {
				left: 14px;
			}
		}
		
		.left-column {
			padding-left: 50px;
			width: 245px;
		}
	}
}
@media only screen and (min-width: 661px) and (max-width: 800px) {
	.iframe {
		font-size: 15px;
		width: 1100px;
		
		.our-promises {
			display: none ;
		}
		.copyright {
			background-image: none;
		}
		
		.magazine-header {
			overflow: visible;
			h1 {
				font-size: 4vw;
				padding-left: 10px;
			}
			.covers {
				height: auto;
				left: 10px;
				width: 20%;
				bottom: 30px;
    
				.small {
					display: none;
				}
				.large {
					width: 100%;
					height: auto;
					float: left;
					margin: 0;
				}				
			}
			.blue-flash h2, .blue-flash h2 *
				{
				font-size: 22px;
				line-height: 30px;
			}
			.blue-flash #countdown {
				bottom: 90px;
				left: 240px;
				right: initial;
			}
		}
		.right-column {
			width: 70%;
			padding-right: 0;
		}
		.left-column {
			padding-left: 30px;
			width: 22%;
		}
		.subscription {
			.promises .headline {
				width: 100%;
			}
			.offer {
				width: 48%;
				padding-top: 15px;
				
				.text:before {
					font-size: 20px;
				}
			}
			#rhsbenefit-print, #rhsbenefit-digital, #rhsbenefit-bundle {
				margin: 10px 5px 10px 5px
			}
			.choose-offer {
				label {
					width: 45%;
				}
				.offer-box {
					width: 29% !important;
					padding: 10px;

					.price-point {
						margin: 0 !important;
					}
					.smaller {
						font-size: 17px;
					}
				}
				.offer-box.double-yes, .offer-box.double-yes-yes {
					width: 60% !important;
				}
				.offer-box.pull-left .price-point {
					float: left !important;
				}
			}
		}
	}
}

@media only screen and (max-width: 660px) {
	.iframe.newnormal {
		#blueflashtext, #nonukblueflashtext {
			width: 95% !important;
		}
	}
	.iframe {
		.our-promises {
			display: none ;
		}
		.copyright {
			background-image: none;
		}
		.blue-flash {
			height: auto;
			position: relative!important;
			clear: both!important;

			 * {
				text-align: center;
				line-height: normal;
			}
			#countdown {
				position: relative !important;
				margin: 0 auto;
				bottom: auto !important;
				right: auto !important;
				width: auto !important;
				overflow: hidden;
				display: table;
			}
		}
		.magazine-header {
			height: auto;
			padding-bottom: 0;
			
			h1 {
				font-size: 22px;
				line-height: 25px;
				width: 100%;
				text-align: center;
				margin: 15px 0;
				padding: 0;
			}
			.covers {
				position: relative;
				width: 60%;
				height: auto;
				margin: 0 auto;
				left: 0;
				bottom: 0;
				
				.small {
					display: none;
				}
				.large {
					width: 100%;
					height: auto;
				}
			}
		}
		.spec {
			.frequency {
				border-right: none;
			}
			.frequency, .perfect-for {
				margin: 0 auto;
				text-align: center;
				padding: 5px 0;
				float: none;
			}
		}
		.left-column {
			display: none;
		}
		.right-column, #blueflashtext, #nonukblueflashtext {
			width: 95% !important;
			float: none!important;
			margin: 0 auto;
			padding: 0;
		}
		.notmobile {
			display: none;
		}
		.quickview:not(.modal) {
			display: block;
			width: 100%;
			margin: 0 !important;
			padding: 0 !important;
			
			.choose-country {
				display: block !important;
			}
			.product-panel {
				.prices {
					float: none;
					margin: 0 auto;
					
					.mobile {
						width: 100%;
					}
				}
			}
		}
	}
}
