body.bootstrap.singleIssue {
	.headerBar {
		border-bottom: 1px solid $grey-border-colour;
		padding: 0 0 10px;
		
		.container {
			padding: 0;
			
			#subscriptioncontainer {
				margin: 0;
			
				h1.standaloneHeadline {
					font-size: 35px;
					margin: 6% auto;
				}
				
				.standaloneCovers {
					padding: 0;
					margin: 0;
				}
			}
		}
		.singleIssueLookInside {
			top: -40px;
			position: relative;
			margin: 0 0 -30px;
		}
	}
	.standalone-product {
	
		.standaloneCovers {
		
			.labelled.choose-offer {
				text-align: center;
				
				#format-select {
					width: 290px;
					margin-left: 0px;
				}
				
				#countryName {
				
					.flag {
						display: none!important;
						float: none;
						margin: 4px 4px -8px;
					}
					
					.flag, #country-field-input {
						display: inline-block;
					}
				}

				.smallprint {
					text-align: center!important;
				}
			}
		}
	}
}
@media screen and (max-width: 650px) {
	body.bootstrap.singleIssue {
	
		.secondaryNav_container.Bundle_container {
			margin: 0;
			display: block;
			flex-wrap: nowrap;
			
			.row.subsTab_container {
				margin: 0px;
				
				.singleIssueLookInside.mobileShow {
					right: 0px;
					top: -40px;
					position: relative;
					margin: 0 0 -30px;
				}
			}
		}
		.headerBar {
			
			.container {
			
				#subscriptioncontainer {
					width: 100% !important;
			
					h1.standaloneHeadline {
						font-size: 25px;
						line-height: 30px;
					}
				}
			}
		}
	.standalone-product {
	
		.standaloneCovers {
		
			.labelled.choose-offer {
				border: 0px solid;
				padding-top: 0;
				margin-top: 0px;
				
				.price {
					font-size: 25px;
				}
				
				.smallprint {
					padding-top: 15px;
				}
			}
		}
	}
	}
}