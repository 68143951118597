
.our-promises {
	height: 365px;
	background-color: $light-background-colour;
	margin-top: 15px;

	h2 {
		font-size: 30px;
		color: rgb(33, 40, 44);
		line-height: 0.8;
		text-align: center;
		padding: 60px 0 40px 0;
	}
	
	.promise {
		width: 33%;
		text-align: center;
		float: left;
		padding-top: 43px;
		background-repeat: no-repeat!important;
		background-position: center top!important;
			
		h3 {
			font-size: 22px;
			color: rgb(33, 40, 44);
			font-weight: bold;
			line-height: 24px;
			text-align: center;
			width: 254px;
			padding: 32px 0 16px 0;
			margin: 0 auto;
		}
		p {
			width: 254px;
			margin: 0 auto;
		}
	
		img {
			display: none;
		}
		
		&:nth-child(2) {
			background: $promise1;
		}
		&:nth-child(3) {
			background: $promise2;
		}
		&:nth-child(4) {
			background: $promise3;
		}
	}
}

body.thirdparty {
	.our-promises {
		display: none;
	}
}

.footerPromises {
	
	.our-promises {
		height: 365px;
		background-color: $light-background-colour;
		margin-top: 15px;

		h2 {
			font-size: 30px;
			color: rgb(33, 40, 44);
			line-height: 0.8;
			text-align: center;
			padding: 60px 0 40px 0;
		}
		.full-width {
			margin: 0 auto;
		
			.promise {
				width: 33%;
				text-align: center;
				float: left;
				padding-top: 0px;
				background: none!important;
					
				h3 {
					font-size: 22px;
					color: rgb(33, 40, 44);
					font-weight: bold;
					line-height: 24px;
					text-align: center;
					width: 254px;
					padding: 32px 0 16px 0;
					margin: 0 auto;
				}
				p {
					width: 254px;
					margin: 0 auto;
				}
			
				img {
					display: none;
				}
				
				i {
					font-size: 50px;
					display: inline-block;
					vertical-align: middle;
					color: $red;
				}
				span {
					display: none;
				}
			}
		}
	}
}

.basket_container {
	.footerPromises {
		.our-promises {
			background-color: $white;
			margin: 50px 0;
			height: auto;

			h2 {
				display: none;
			}
		}
	}
}

@media screen and (max-width: 650px) {
	.footerPromises {
		.our-promises {
			height: 100%;
		}
	}
}