.packages {
	#boxnav:not(.footerNav), .container #boxesnavsetup.newnormalHide {
		display: none!important;
	}
	.container {
		padding-left: 0px;
		padding-right: 0px;
	}
	.row {
		margin-left: 0px;
		margin-right: 0px;
	}
	.megamenu {
		left: -1px!important;
	}
	.pricing-block-container {
		display: flex;
		flex-wrap: wrap;
		row-gap: 10px;

		.giftbox {
			width: 16.5% !important;
		}
	}
	.packageBlock:not(.block) {
		margin: 20px auto;
		line-height: 21px;
		
		ul, span:not(.closerBtn), a, .itemname, .itemprice {
			font-size: 14px!important;
		}
		
		.itemname {
			position: relative;
			display: inline-block;
			white-space: break-spaces;
			font-family: $demibold-font-stack;
				
			&:before {
				content: '- ';
				position: absolute;
				top: 0;
				left: -10px;
			}
		}
		
		.itemprice {
			padding-left: 13px;
		}
		
		p {
			a {
				color: #db071a;
				text-decoration: underline;
				padding-left: 25px;
			}
		}
		
		.pkg_BKZ, .pkg_PRD {
		
			.itemprice {
				display: inline-block;
				padding-left: 4px;
			}
		}
		
		ul {
			padding: 6px 0 6px 13px;
		
			li {
				padding: 3px 0;
				line-height: 23px;
			}
		}
	}
	.button {
		border-radius: 8px !important;
	}
	.quickviewPackagePricing {
		
		ul {
			padding: 0 0 0 5px;
			
			li {
				white-space: nowrap;
			}
		}
		
		.pkg_BKZ, .pkg_PRD, .pkg_subs {
		
			.itemprice {
				display: block;
			}
		}
		
		.more-info {
		
			ul {
			
				li {
					white-space: break-spaces;
				}
			}
		}
	}
	
	.grayTotal {
		color: #00000060;
		font-size: 14px;
	}
	.grayTotal, .graySecondary {
		color: #00000060;
	}
	.pricing-block.standard {

		.block {
			background: none;
			border-radius: 0;
		}
		
		.block.chosen {
			background: url(/images/tick.webp) no-repeat 95% 50%;
		}
	}
	.pricing-block.standard:not(.expanded) .block:not(:last-child):not(.quickviewPricing) {
		border-bottom: 0;
	}

	.pricingInfo {
		border: 2px solid;
		font-weight: bold;
		padding: 0px 5px;
		margin-top: 5px;
		margin-right: 10px;
		margin-bottom: 22px;
		height: 17px;
		width: 17px;
		float: left;
		line-height: 13px;
	}
	
	.price {
		font-size: 21px;
	}
}
.basketSummary {
	ul.pkg_items {
		li {
			p.delivery, p:not(.itemname):not(.itemprice) {
				display: none;
			}
		}
	}
}