.campaign-header {
	cursor: pointer;
	position: relative ;

	.top-layer {
		position: absolute;
		top: 0;
		left: 0;
		width: 1100px;
	}
	
	.sold-count {
		width: 400px;
		margin: 0 auto;
		padding-top: 6px;
		text-align: center;
		font-size: 21px;
		
		.counter {
			font-size: 35px;
			line-height: 35px;
			margin-top: 8px;
		}
	}
	
	.session-count {
		width: 200px;
		float: right;
		text-align: center;
		font-size: 16px;
		font-family: $demibold-font-stack;
		
		.number {
			font-size: 40px;
			line-height: 40px;
		}
	}
	
	img {
		display: block;
	}
}
