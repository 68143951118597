.magazine-categories {

    h2 {
        font-size: 25px;
        letter-spacing: -0.5px;
        padding-bottom: 11px;
    }

    p {
        line-height: 19px;
        margin-bottom: 22px;
    }

    .category-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        max-width: 1200px;
        
        .category-logos {
            width: 13%;
            margin: 0px 5.5px;
            padding-bottom: 35px;

            .category-logos-link {
                text-decoration: none;

                .category-img {
                    display: flex;
                    justify-content: center;
                    background-color: rgba(219, 63, 73, 0.10);
                    width: 100px;
                    height: 100px;
                    align-items: center;
                    border-radius: 50%;
                    margin: 0px auto 15px;

                    img {
                        height: 65px;
                        width: 65px;
                    }
                }

                .category-text {
                    color: $primary-color;
                    text-align: center;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    max-width: 113px;
                    width: 100%;
                    margin: auto;
                }
            }
        }
    }

    .categoryList {
        height: 310px;
        max-width: 1470px;
        overflow-x: scroll;
        overflow-y: hidden;
        margin: 0 auto;

        .category-container {
            width: 2350px;
            max-width: 2350px;
            justify-content: left;

            .category-logos {
                width: 450px;
                padding: 14px;
                border-radius: 10px;
                border: 1px solid $grey-border-colour;
                box-sizing: border-box;
                text-decoration: none;
                margin: 10px !important;

                .category-logos-link {
                    display: flex;

                    .category-img {
                        width: 60px;
                        height: 50px;
                        margin: initial;

                        img {
                            width: 30px;
                            max-height: 30px;
                        }
                    }

                    .category-text {
                        max-width: 90%;
                        text-align: left;
                        margin-left: 10px;
                        letter-spacing: -1px;

                        .category-text-header {
                            font-weight: bold;
                        }

                        .category-text-titles {
                            font-weight: normal;

                            span {
                                
                                &:first-child {

                                    &:after {

                                        content: ',';
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 991px) and (orientation: landscape) {
    .magazine-categories {
        
        .category-container {
            
            .category-logos {
                width: 11%;
                margin: 0px 13px;
            }
        }
    }
}

@media only screen and (max-width: 991px) and (orientation: portrait) {
    .magazine-categories {
        
        .category-container {
            
            .category-logos {
                margin: 0px 13px;
            }
        }
    }
}

@media screen and (max-width: 750px) {
    .magazine-categories {
        margin: 32px 0px;

        .browserCategoriesContainer {
            overflow: auto;

            .category-container {
                width: 1057px;
                margin-left: 0 !important;
                margin-right: 0 !important;

                .category-logos {
                    width: 151px;
                    margin: 0px;
                }
            }
        }
        .categoryList {
            height: 345px;
    
            .category-container {
                width: 1600px;
    
                .category-logos {
                    width: 300px;
                }
            }
        }
    }
}