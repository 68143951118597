.az-magazines, .basket-header {
	padding: 32px 85px;
	margin:0 auto ;
	overflow: hidden;
	h1 {
		color: $black;
	}	
	
	.tags {
		float: left;
		margin-top: 48px;
		width: 600px;
	}
	
	.sortby {
		margin-top: 48px;
		float: right;
		width: 315px;
		height: 107px;
		border-left: 1px solid $grey-border-colour ;
		select {
			float: right !important;
			width: 210px;
		}
		label {
			font-family: $demibold-font-stack ;
			display: block;
			float: left;
			margin: 8px 15px 0 30px;
		}

	}
	
	a {
		float: right;
	}
}

.basket {
	padding: 10px 0px;
	margin:0 auto ;
	background-color: $light-background-colour;
	
	
	.payment-method, .change, .removeItem {
		font-weight: 500;
	}
	
	a {
		color: $red;
		text-decoration: underline;
	}
	
	h2 {
		font-family: $demibold-font-stack ;
		font-weight: normal;
		font-size: 16px;
		padding: 26px 0;
		
		&.multimessage {
			font-size: 18px;
		}
	}
	
	.add-single-issue {
		background-color: #fff;
		padding: 12px;
		width: 200px;
		margin: 6px 0;
	}
	
	table {
		th {
			text-align: left;
			padding-bottom: 20px;
			border-bottom: $grey-border-colour 1px solid;
		}
		td {
			padding-top: 22px;
		}
		
		.extras td.line {
			border-top: $grey-border-colour 1px solid;
			padding-bottom: 22px;
		}
		.extras td.linebelow {
			border-bottom: $grey-border-colour 1px solid;
			padding-bottom: 22px;

			.sell-single-issue {
				padding-right: 12px;
			
				form {
					display: inline;
				}
			}
		}
		
		.oneOffPayments {
			display: none;
		}

		.col-mag {
			width: 325px;
			
			.cover {
				float: left;
				height: 73px;
			}
			
			.info {
				padding-left: 22px;
				overflow: hidden;
			}
			
			.gift-message {
				margin-top: 8px;
				.words {
					font-size: 13px;
				}
				
				img {
					width: 16px;
					padding: 2px 6px 0 0;
				}
				label.wide {
					margin-right: 25px;
				}
			}
		}
		.col-extras {
			width: 206px;
		}
		.col-payment-methods {
			width: 232px;
			padding-left: 15px;
			
			.gift-message {
				margin-top: 8px;
				.words {
					font-size: 13px;
				}
				
				img {
					width: 22px;
					padding: 2px 6px 0 0;
				}
				label.wide {
					margin-right: 25px;
				}
			}
		}
		.label {
			font-family:$demibold-font-stack;
			text-align: right;
			padding-right: 25px;
		}
		.total {
			font-size: 22px;
		}
		.col-remove {
			width: 175px;
			.rrp {
				text-decoration: line-through;
			}
		}
		.voucher {
			p {
				font-family:$demibold-font-stack;
				float: left;
				padding-top: 3px;
			}
			.text {
				width: 240px;
			}
		}
	}
}

.header {
	padding: 30px 0;
	overflow: hidden;
	h1 {
		font-family: $demibold-font-stack ;
		font-weight: normal;
		font-size: 40px;
		line-height: 45px;
		padding: 30px 0 0 80px;
		width: 550px;
		position: relative;
		color: $black;
	}
}

.checkout {
	padding: 0 85px;
	
	.checkoutLogo {
		height: 49px;
		width: 477px;
		background: $checkout_logo;
		background-repeat: no-repeat!important;
		background-position: center left!important;
	
		img {
			display: none;
		}
	}
	
	.panel-title {
		font-family: $demibold-font-stack ;
		font-weight: normal;
		font-size: 27px;
		padding: 16px 0 ;
		height: 23px;
		width: 90% ;	
	}
	.lhs {
		clear:left ;
		width: 530px;
		float: left;
	}
	.rhs {
		margin-left: 30px;
		float: left;
		width: 350px;
	}
	
	.cover {
		float: left;
		height: 73px;
	}
	
	li {
		padding: 15px 0;
		clear: both;	
		position: relative;
		overflow: visible;
	}	
	.ccc-optin {
		label {
			width: auto;
			padding-top: 0px;
			padding-left: 20px;
		}
	}
	.form li:before, .form li:after { 
		content:""; 
		display:table; 
	}
	.form li:after { 
		clear:both; 
	}
	.form li { 
		zoom:1; 
	}
	label {
		text-align: right;
		font-family: $demibold-font-stack ;
		font-weight: normal;
		font-size: 20px;
		width: 210px;
		padding-right: 25px;
		padding-top: 10px;	
		display: block;
		float: left	
	}
	
	.extratext {
		width: 288px;
		font-size: 14px;
		float: right;
		line-height: 20px;
		font-style: italic;
	}
	
	.success {
		border: 1px solid #0f0 !important;
		padding: 4px 6px;
	}
	
	.invalid {
		border: 1px solid #f00 !important;
		padding: 4px 6px;
	}
	
	label.invalid {
		z-index: 99 !important;
		background-color: #fff;
		width: auto !important;
		padding: 2px !important;
		float:left;
	}
	
	#EmailAddress-error {
		bottom: -65px;
	}
	
	
	.form input[type="email"].invalid, .form input[type="text"].invalid, .form input[type="password"].invalid {
		-moz-box-shadow: 0 3px 6px rgba(128, 0, 0, 0.2) inset;
		-webkit-box-shadow: 0 3px 6px rgba(128, 0, 0, 0.2) inset;
		box-shadow: 0 3px 6px rgba(128, 0, 0, 0.2) inset;
		behavior: url(/pie/pie.php);
	}
	
	label.small {
		text-align: left;
		padding-right: 25px;
		padding-top: 5px;	
		display: block;
		float: left ;	
		font: 16px $normal-font-stack;
		font-weight: 300;
		color: $primary-color;
		line-height: 24px;
		width: auto;
	}
	
	::-webkit-input-placeholder { /* WebKit browsers */
		color: #ccc;
		font-style: normal;
	}
	:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
		color: #ccc;
		font-style: normal;
	}
	::-moz-placeholder { /* Mozilla Firefox 19+ */
		color: #ccc;
		font-style: normal;
	}
	:-ms-input-placeholder { /* Internet Explorer 10+ */
		color: #ccc;
		font-style: normal;
	}
	
	.panel-continue {
		float:right;
		padding: 8px 16px ;
		line-height: 12px;
	}
	.panel-edit, .panel-done {
		font-family: $demibold-font-stack ;
		margin-left: 45px;
		padding: 32px 0;

		img {
			margin-left: 15px;
		}
	}
	.panel-edit {
		cursor: pointer;
	}
	
	
	.multichoice {
		input[type=radio] {
			float: left;
			margin: 8px 8px 0 235px;
		}
	}
	.addressblock {
		width: 600px;
		float: left;
	}
	address p {
		font-style: normal;
		margin: 0 !important;
	}

	.visible {
		padding-top: 20px;
	}
	img.ui-datepicker-trigger {
		margin: 10px;
	}
	
	.giftsubscription-address-option-line {
		clear: both ;
		overflow:hidden;
		label {
			width: 400px;
			text-align: left;
		}
		.radio {
			margin: 14px 12px 0 12px;
			float: left;
		}
	} 
	
	.delivery-address-option-line {
		clear: both ;
		overflow:hidden;
		label {
			width: 400px;
			text-align: left;
		}
		.radio {
			margin: 14px 12px 0 12px;
			float: left;
		}
	}
	
	.rduoption {
		width: 100px;
		float: left;
	}
	
	.rduradio {
		float: left;
		margin: 8px 8px 0 5px;
	}
	
	
	.payments {
		float: left;
		margin-bottom: 6px; 
		.mag-cover img {
			padding-right: 24px; 
		}
		
		.payment {
			font-size: 22px;
			padding: 6px 0;
			em {
				color: $red;
			}
		}
	}
	
	.card-choice {
		clear: both;
		
		.icons {
			&.ongoingcc {
				margin: 15px 0 ;
				padding-bottom: 15px;
				border-bottom: 1px #ddd solid;
			}
		}
	}
	
	.creditcard-details {
		select {
			width: 95px;
			text-align: center;
			margin-right: 12px;
		}
		.card-type {
			width: 165px;
		}
	}
	
	.payment-is-safe {
		margin-left: 235px;
	}
	
	.twolines {
		margin-top: -5px;
	}
	.sortcode {
		width: 48px !important;
		display: inline !important;
		text-align: center;
		float: none !important;
	}

	.directdebitlogo {
		float: right ;
		height: 40px;
		margin: 6px 0 15px 0;
	}
	
	.directdebit li {
		padding: 20px 0 60px 0;
	}

	.direct-debit-info {
		padding: 15px 15px 15px 28px;
		border: 1px solid $light-background-colour ;
		clear: both;
		
		.directdebitlogo {
			float: left;
		}
		
		h3 {
			font-size: 18px;
		}
		p {
			font-size: 14px;
			margin-bottom: 10px;
		}
	}
		
	.green-tick-box {
		background-color: #f9f9f9;
		border-top: none;
		padding: 20px 30px;
		position: relative;
		margin: 10px 0 30px;
			
		ul.green-ticks {
			
				li {
				background-image: url(/images/green-tick-icon.webp);
				background-repeat: no-repeat;
				background-position-y: center;
				padding: 3px 0;
				
				span {
					display: inline-block;
					padding: 0 0 0 30px;
				}
			}
		}
		
		.arrow-left {
			position: absolute;
			left: -19px;
			top: 51px;
			width: 0; 
			height: 0; 
			border-top: 20px solid transparent;
			border-bottom: 20px solid transparent;
			border-right: 20px solid #f9f9f9;
		}
	}
	
	.review-dd {
		padding: 0px !important;
		.lhs {
			p.details {
				font-size: 14px;
				padding: 5px 0 10px 0 ;
			}
			
			h4 {
				font-family: $demibold-font-stack ;
			}
		}
		
		p {
		font-size: 14px;
			margin-bottom: 8px;
		}
				
		.dd-details {
			border-top: 1px solid $medium-grey-border-colour;
			border-bottom: 1px solid $medium-grey-border-colour;
			overflow: hidden;

			dt {
				display: block;
				float: left;
				width: 210px ;
				font-family: $demibold-font-stack ;
				font-size: 18px;
				text-align: right;
				clear: both;
				padding: 6px 0;
			}
			dd {
				display: block;
				float: left;
				margin-left: 35px;
				padding: 6px 0;
			}
		}
		
		.ddguaranteewords {
			list-style-image: url('/images/red-tick.webp');
			font-size: 14px;

			li {
				padding: 5px 0;
				line-height: 18px;
			}
		}
	}	
	
	
	.data-protection {
		dt {
			display: block;
			clear: both ;
			font-family: $demibold-font-stack ;
			padding-left: 2px;
		}
		
		dd {
			display: block;
			clear: both ;
			overflow: hidden;
			padding-left: 2px;
		}
		
		input[type=checkbox] {
			display: block;
			float: left;
			margin: 10px 10px 0 0;
		}
	}
	
	#pay-securely-now {
		width: 195px;
		height: 50px;
		font-size: 20px;
	}
	
	
	.panel-detail-footer {
		width: 522px ;
		overflow: hidden;
		padding: 8px 4px;
	}

	.cascadeaccordion {
		min-height: 500px;
		padding: 10px 0 ;
		
		.panel:not(:first-child) {
			border-top: 1px solid rgb(213, 213, 213);
		}
	
		.panel {
			width: 100% ;
			margin: 0 auto; 
			padding-top: 6px;
			clear: both;
			position: relative;
		}
		
		.panel-name {
			float: left ;
			font-weight: bold;
		}
		
		.panel-detail {
			padding: 8px 0;
			overflow: hidden;
		}
		
		
		.panel-summary {
			width: 90%;
			min-height: 25px;
		}
		
		.greyed-out {
			opacity: 0.4;
		}
		
		#panel5 .panel-continue {
			position: absolute;	
			bottom: 80px;
			left: 415px;
		}
	}
}
	.paynow:not(.paypal), .paynow.tesco {
		width: 160px !important;
	}
	.paynow.paypal {
		width: 175px !important;
	}
	.paynow {
		margin: 0 auto 24px auto;
		overflow: hidden;
		a {
			margin-top: 11px;
			display: block;
			float: left;
			color: $red;

			img {
				padding-right: 5px;
				margin-bottom: -5px;
			}
		}   
	}

.receipt {
	h1 {
		width: 405px;
		float: left;
	}
	
	.blue-flash h1 {
		width: 705px;
	}
	.order-summary {
		clear: both;
		padding: 24px;
		background-color: $light-background-colour;
	
		.giftcard {
			padding: 10px;
			border: 2px solid #db071a ;
			overflow: hidden;
		
			img {
				padding-right: 10px;
				float: left;
				display: none;
			}
			
			.words:not(.paypalGift) {
				float: left;
			}
			.words {
				width: 200px;
				background: $gift_icon_checkout;
				background-repeat: no-repeat!important;
				background-position: top left!important;
			}
			
			h4 {
				font-family: $demibold-font-stack ;
				padding-left: 48px !important;
				margin-bottom: 13px;
			}
		}
		
		h2 {
			font-size: 16px;
		}
		
		table {
			th {
				text-align: left;
				padding-bottom: 20px;
				border-bottom: $grey-border-colour 1px solid;
			}
			td {
				padding-top: 22px;
			}
		}
		div.responsiveDiv {
			display: inline-block;
			vertical-align: top;
				
		}
		.responsiveDiv {
			/* one item */
			&:first-child:nth-last-of-type(1) {
				width: 99%;
			}
			
			/* two items */
			&:first-child:nth-last-of-type(2),
			&:first-child:nth-last-of-type(2) ~ .responsiveDiv {
				width: 49%;
			}
			
			/* three items */
			&:first-child:nth-last-of-type(3),
			&:first-child:nth-last-of-type(3) ~ .responsiveDiv {
				width: 32.3%;
			}
			
			/* four items */
			&:first-child:nth-last-of-type(4),
			&:first-child:nth-last-of-type(4) ~ .responsiveDiv {
				width: 24%;
			}
			
			/* five items */
			&:first-child:nth-last-of-type(5),
			&:first-child:nth-last-of-type(5) ~ .responsiveDiv {
				width: 19%;
			}
		}
		.col-mag {
			width: 318px;

			.cover {
				float: left;
				height: 73px;
			}
		
			.info {
				padding-left: 22px;
				overflow: hidden;
			}
		}
		
		.col-delivery {
			width: 225px;
		}
		.col-extras {
			width: 190px;
		}
		.col-payment-method {
			width: 350px;
		}
	}
	.order-info {	
		padding: 24px 0 24px 50px;
		overflow: hidden;
		
		.lhs {
			clear:left ;
			font-size: 18px;
			width: 576px;
			float: left;
			> p {
				padding-bottom: 30px ;
			}
			
			h2 {
				line-height: 30px;
			}
			
			h5 {
				font-family: $demibold-font-stack ;
				font-size: 16px;
				line-height: 24px;
				color: #000001;
				padding: 10px 0;
			}
			
			.giftcard {
				margin-top: 26px;
				padding: 10px;
				border: 2px solid #db071a ;
				overflow: hidden;
				img {
					padding-right: 10px;
					float: left;
				}
				
				.words {
					float: left;
					width: 200px;
				}
				h4 {
					font-family: $demibold-font-stack ;
				}
			}
				
			.welcomegift {
				margin-top: 26px;
				padding: 26px;
				border: 1px solid $grey-border-colour ;
				overflow: hidden;
				img {
					padding-right: 26px;
					float: left;
				}
				
				.words {
					float: left;
					width: 350px;
				}
			}
		}
		
		ul {
			list-style: disc;
			
			ul, ol {
				padding-left: 40px;
			}
			
			ol {
				
				li {
					padding: 0;
					font-size: 13px;
					list-style: decimal;
				}
			}
			
			ul {
				
				li {
					font-size: 16px;
					padding: 3px 0;
				}
			}
		}
		
		li {
			padding: 5px 0;
		}
		
		p {
			font-size: 16px;
			margin-bottom: 8px;
			font-family: $normal-font-stack ;
			font-weight: 300;
		}
		
		h4 {
			font-family: $demibold-font-stack ;
			padding: 15px 0 10px;
		}
				
		.rhs {
			background-color: $light-background-colour;
			padding: 25px;
			margin-left: 30px;
			float: left;
			width: 369px;
			overflow: hidden;
		}
	}
	
}
.also-bought {
	display: inline-block;
	width: 20%;
	margin: 0 2%; 
	text-align: center;
	
	img {
		width: 100%!important;
	}
	
	p {
		padding: 10px 0;
	}

	a {
		text-decoration: none;
		color: $white;
		background: $red;
		padding: 5px 40px;
		text-align: center;
		margin: 0 auto;
	}
}
.tt-info.thirdparty {
	position: absolute;
	top: 23px;
	right: 19px;
	z-index: 5;
	width: 22px;
}
#card-cvv-help-ddd, #card-cvv-help-dddd {
	opacity: 0;
    visibility: hidden;
	width: 330px;
    position: absolute;
    background: $light-background-colour;
    padding: 13px 5px 5px 13px;
    top: -125px;
    left: 179px;
    z-index: 9999;
	
	&:after {
		content: '';
		position: absolute;
		bottom: -15px;
		left: 303px;
		border-top: 15px solid $light-background-colour;
		border-left: 15px solid transparent;
		border-right: 15px solid transparent;
	}

	div, img {
		display: inline-block;
		vertical-align: middle;
		width: 49%;
	}
}
.tt-info.thirdparty.mastercardcvv:hover ~ #card-cvv-help-ddd {
	opacity: 1;
	visibility: visible;
}
.tt-info.thirdparty.amexcvv:hover ~ #card-cvv-help-dddd {
	opacity: 1;
	visibility: visible;
}

@media screen and (max-width: 650px) {
	#vouchercode {
		width: 75%;
	}
	
	body.receipt {
	
		h1.pull-left {
			float: none!important;
			padding-left: 0px;
			text-align: center;
			width: 100%;
			font-size: 38px;
			line-height: 40px;
		}
		.order-summary {
			div.responsiveDiv {
				display: block!important;
				width: 100%!important;
			}
			div.col-delivery {
				padding-top: 15px;
				clear: both;
			}
			div.col-extras {
				p {
					padding-left: 77px;
				}
			}
		}
		.order-info {
			padding: 24px 0px;
			
			.lhs, .rhs {
				float: none;
				width: 100%;
				clear: both;
				margin-left: 0px;
			}
			.rhs {
				padding: 15px;
			}
		}
	}
	.also-bought {
		width: 45%;
		margin: 11px 2%;
		
		img {
			width: 100%!important;
		}
	
		a {
			white-space: nowrap;
		}
	}
}
body.bootstrap {
	font: 15px $normal-font-stack;
	font-weight: 300;
	color: $primary-color;
	line-height: 24px;
	
	.underlined {
		text-decoration: underline!important;
		color: inherit;
	}
	p:not(.h5) {
		font-size: 15px;
	}
	h1, h2, h3, h4, h5, h6 {
		font-family: $demibold-font-stack;
	}
	h3 {
		small {
			font-size: 15px!important;
		}
	}
	h4 {
		font-size: 18px;
		
		span {
			font-family: $normal-font-stack;
			font-weight: 600;
			font-size: 22px;
		}
		.delvierText {
			font-size: 14px;
		}
	}
	h5 {
		font-size: 1.25rem;
	}
	h6 {
		font-size: 1rem;
	}
	.semantic-blue-flash {
		p {
			font-size: 18px;
		}
	}
	button.paypal-btn {
		background: #ffc439!important;
		border-color: #ffc439!important;
		font-weight: 600;
		
		img.paypal {
			vertical-align: sub;
			max-width: 73px;
		}
	}
	.boxes1, .boxes2 {
		display: none;
	}
	.pricingText {
		font-size: 20px;
	}
	.greyed-out {
		color: #bbbbbb;
	}
	.updated, .voucherSuccess, .voucherSuccessShow, .messageWitdh {
		width: fit-content;
	}
	.messageWitdh {
		padding: 5px 20px!important;
	}
	.lightBG {
		background: $light-background-colour-block;
	}
	.summary {
		background: $light-background-colour;
		
		.info {
			p {
				margin-bottom: 0px;
			}
		}
		a.tooltipLink {
			background-color: $black;
			color: $white !important;
		}
		.customerBenefits {
			margin-top: 1px;
		}

		hr {
			background: rgba(0, 0, 0, 0.5);
		}

		#basket-total-row {
			padding-bottom: 15px;
		}
	}
	.tooltip.show {
		opacity: 1;
	}
	.tooltip {
			
		.tooltip-inner {
			max-width: 422px;
			color: $black !important;
			border: 1px solid $medium-grey-border-colour;
			font-size: 13px;
			padding: 14px 9px 9px 9px;
			text-align: left;
			z-index: 399;
			background-color: $white !important;
			
			ul, p {
				font-size: 13px;
			}
		}
		.arrow {
			display: none;
		}
	}
	.selectstartissue {
		height: calc(1.5em + .75rem + 5px);
		padding: 5px 10px;
		width: 275px;
		display: inline-block;
		font-size: 13px !important;
	}
	.alertI, .tooltipLink, .secondaryI, .infoI {
		height: 22px;
		display: inline-block;
		text-align: center;
		width: 22px;
		font-weight: normal;
	}
	.alertI, .secondaryI, .infoI {
		color: $white !important;
	}
	.alertI {
		background-color: $red;
	}
	.secondaryI {
		background-color: $medium-grey-border-colour;
	}
	.infoI {
		background-color: #343a40;
	}
	.btn:not(.deselectFilters):not(.closeFiltersDropdown):not(.paywall-btn):not(.voucherapply):not(.checkout_btn) {
		border-radius: 8px !important;
		font-family: $demibold-font-stack;
		font-size: 18px!important;
		line-height: 22px!important;
		color: $white;
		padding: 8px;
		border: none;
		height: 41px!important;
		white-space: nowrap;
	}
	.red-btn, .btn-danger, .btn-danger.disabled {
		background: $red;
		border-color: $red;
		
		&:hover, &:active, &:focus {
			background: $redHover;
			border-color: $redHover;
			box-shadow: 0 0 0 0.2rem $redHover;
		}
	}
	.red-btn {
		margin-bottom: 50px;
	}
	label:not(.categorybtn) {
		font-weight: 300;
	}
	.editOptions {
		a {
			color: $black;
			text-decoration: underline;
			cursor: pointer;
			
			&:hover {
				text-decoration: none;
			}
		}
	}
	.rrp, .preVoucherPrice {
		text-decoration: line-through;
		color: #A4A4A4;
		display: block;
	}
	input[type=tel], input[type=email], input[type=text], input[type=password], select {
		float: none;
	}
	.changedCountryBlock {
		display: none;
	}
	.overlay {
		z-index: 99999999;
	}
}
body.basket-2022 {
	background: $light-background-colour;
	
	hr {
		color: $medium-grey-border-colour;
	}
	
	.nav, nav, header, .top-header {
		background: $white;
	}
	
	h1.basketCount {
		font-size:34px;
		color: #1B1B1B;
		font-family: $normal-font-stack;
		font-weight: 300;
		
		span {
			font-weight: normal;
		}
	}
	
	.summary-bg {
		background: $light-background-colour;
	}
	
	.summary, .you-viewed {
		background: $white;
		
	}
	
	.summary {
		p:not(.h5), a, .words, label {
			font-size: 14px;
		}
	}
	.footerPromises {
		.our-promises {
			background: $white;
			
			.full-width {

				.promise {
					
					span.first {
						background-image: url("/images/transparent/price.webp");
					}
					span.second {
						background-image: url("/images/transparent/money-back.webp");
					}
					span.third {
						background-image: url("/images/transparent/lorry.webp");
					}
				}
			}
		}
	}
	
	#checkoutButton, #checkoutButtonMobile {
		padding-top: 10px!important;
	}

	#paypalimmediatecheckoutbutton {
		margin-top: 10px !important;
	}
	
	.helpBlock {
		p {
			font-size: 14px;
		}
	}
}

@media screen and (max-width: 768px) {
	body.basket-2022 {
	
		h1.basketCount {
			font-size: 32px;
		}
		
		.selectstartissue {
			width: 250px;
		}
		
		.stickySummary {
			position: relative!important;
		}
	}
	body.bootstrap {

		.selectstartissue {
			width: 100%;
		}
	}
}