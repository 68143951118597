/* Magazine specific */
@import "fonts_colours";
@import "images_icons";

@import "meyer-reset";
@import "bourbon/bourbon";
@import "my-mixins";

@import "common";
@import "form-elements";

@import "generic";
@import "subscription";
@import "promises" ;
@import "quickview";
@import "search";
@import "campaign";
@import "boxes";
@import "magazines";
@import "checkout";
@import "newsletter";
@import "giftcard";
@import "standalone";
@import "newnormal";
@import "header";
@import "reward_redemption";
@import "bundle";
@import "packages";
@import "digital";

@import "e-learning";
@import "fontawesome_icons";
@import "basket-slider";
@import "basket";

@import "combined";

@import "homepage";
@import "categories";
@import "guides";

@media print {
	.print-hidden-content { display: none!important;}
	.print-padded-content { margin-top: 75px!important;}
}

@import "jquery-ui.theme.min";
@import "odometer.theme";
@import "iframe";
@import "test-462461";

@import "tesco"; /* MOBILE CHECKOUT */
@import "checkout/receipt";

html {
	scroll-padding-top: 200px !important;
}

.cancel-msg {
	display: none;
}

.brandedTextColor {
	color: $red;
	
	strong {
		color: $primary-color;
	}
}

@import "branded_standalone";

@media screen and (max-width: 650px) {
	.mobileShow {
		display: block !important;
		
		#gifttext {
			img {
				width: 75%;
			}
		}
	}

	.mobileHide {
		display: none !important;
	}
}
