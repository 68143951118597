@font-face{
	font-family:"Montserrat Normal";
	src:url("/Fonts/Montserrat/Montserrat-Regular.ttf");
	src:url("/Fonts/Montserrat/Montserrat-Regular.ttf") format("truetype"),
		url("/Fonts/Montserrat/Montserrat-Medium.ttf") format("truetype"),
		url("/Fonts/Montserrat/Montserrat-Light.ttf") format("truetype"),
		url("/Fonts/Montserrat/Montserrat-Thin.ttf") format("truetype"),
		url("/Fonts/Montserrat/Montserrat-Italic.ttf") format("truetype"),
		url("/Fonts/Montserrat/Montserrat-MediumItalic.ttf") format("truetype"),
		url("/Fonts/Montserrat/Montserrat-LightItalic.ttf") format("truetype"),
		url("/Fonts/Montserrat/Montserrat-ThinItalic.ttf") format("truetype");
}

@font-face{
	font-family: "Montserrat SemiBold";
	src: url("/Fonts/Montserrat/Montserrat-SemiBold.ttf");
	src: url("/Fonts/Montserrat/Montserrat-SemiBold.ttf") format("truetype"),
		url("/Fonts/Montserrat/Montserrat-SemiBoldItalic.ttf") format("truetype"),
}

@font-face {
	font-family: "Montserrat Bold";
	src:url("/Fonts/Montserrat/Montserrat-Bold.ttf");
	src:url("/Fonts/Montserrat/Montserrat-Bold.ttf") format("truetype"),
		url("/Fonts/Montserrat/Montserrat-BoldItalic.ttf") format("truetype"),
		url("/Fonts/Montserrat/Montserrat-Black.ttf") format("truetype"),
		url("/Fonts/Montserrat/Montserrat-BlackItalic.ttf") format("truetype");
}

$normal-font-stack:    'Montserrat', sans-serif;
$demibold-font-stack:  'Montserrat SemiBold', sans-serif;
$bold-font-stack:  'Montserrat Bold', sans-serif;

$primary-color: #000;
$off-black-text: #6C6C6C;
$very-light-background-colour: #f1f1f1;    
$light-background-colour: #EDEDED;  
$light-background-colour-block: #f9f9f9;
$medium-background-colour: #DEDEDE;
$mid-grey-background: #F5F5F5;
$off-black-border-colour: #1B1B1B;
$grey-border-colour: #c2c2c2;
$medium-grey-border-colour: #B3B3B3;
$turquoise: #1295D8;
$blue-flash-bg: $turquoise;
$flashOpacity: 0.851;
$light-turquoise: #22B0E2 ;
$black: #000;
$white: #fff;
$red: rgb(219, 7, 26);
$redHover: #c82333;
$orange: #fe9802;
$grey-text-colour: #737373;
$blue-flash-text: $white;
$star_rating: #fecf01;
$tableGrey: #f9f9f9;
$tableHeaderGrey: $light-background-colour;
$tableHover: $light-background-colour;