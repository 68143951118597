.button {
	font-family: $demibold-font-stack;
	font-size: 18px!important;
	line-height: 20px!important;
	color: #ffffff;
	padding: 8px;
	border: none;
	height: 41px!important;
	white-space: nowrap;
	border-radius: 0px !important;
	
	&:hover {
		color: #ffffff;
		background: #b50918;
	}
}

button.ellipsis {
	white-space: nowrap; 
	max-width: 630px; 
	overflow: hidden;
	text-overflow: ellipsis;
}

.shallow {
	height: 28px !important;
	font-family:$demibold-font-stack;
	font-size: 16px;
	padding: 4px 10px;
}

.form-control {
	border-radius: 0px !important;
}
input.text {
	border-width: 1px;
	border-color: rgb(194, 194, 194);
	border-style: solid;
	height: 39px;
	font-size: 16px;
	font-weight: 300;
	line-height: 1.313;
	padding: 0 17px;	
}
#start-month, #start-year,
#expiry-month, #expiry-year {
	width: 130px;
}
#start-month, #expiry-month {
	margin-right: 20px;
}
.cascadeaccordion {
	input[type="tel"], input[type="email"], input[type="text"], input[type="password"] {
		height: 40px;
		border:1px solid #ddd;
		padding:4px 6px;
		width: 290px;
		display: block;
		float: left;
		box-sizing: border-box !important;
		font-size: 16px;
	}
	input[type="tel"]:hover, input[type="tel"]:focus, input[type="email"]:hover, input[type="email"]:focus, input[type="text"]:hover, input[type="text"]:focus, input[type="password"]:hover, input[type="password"]:focus {
		border-color:#b1b1b1;
	}
}
input[type="tel"], input[type="email"], input[type="text"], input[type="password"], select {
	height: 40px;
	border:1px solid #ddd;
	background: $white;
	padding:4px 6px;
	width: 290px;
	display: block;
	float: left;
	box-sizing: border-box !important;
	font-size: 16px;
	font-weight: 300;
}
input[type="tel"]:hover, input[type="tel"]:focus, input[type="email"]:hover, input[type="email"]:focus, input[type="text"]:hover, input[type="text"]:focus, input[type="password"]:hover, input[type="password"]:focus, select:hover, select:focus {
	border-color:#b1b1b1;
}

select.withbg {
	background: url(/images/selectbg.webp) no-repeat right #fff;
	background: none\0;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

select.withbg::-ms-expand {
    display: none;
}

.country-field {
	background: $countrySelectTransparent_icon no-repeat right, linear-gradient(90deg, $white 0%, $white 77%, $red 77%) !important;
	background-color: $very-light-background-colour;
}
.standaloneCountry.country-field {
	background: $countrySelectTransparent_icon no-repeat right, linear-gradient(90deg, $white 0%, $white 85%, $red 85%) !important;
}

.paypalBtn {
	display: none;
	cursor: default!important;
}
#paypalimmediatecheckoutbutton, .paypalBtn {
	background-color: #ffc438;
    height: 36px!important;
    width: 207px;
}
#paypalimmediatecheckoutbutton {
	background-image: url(/images/PayPal_btn.webp);
    background-repeat: no-repeat;
    background-position: center;
    border: 0px solid;
    background-size: 207px 36px;
}
.variationselect {
	select {
		width: 100%;
		margin: 8px 0 10px;
	}
}

.contact_iframe {
	height: 950px;
}

@media screen and (max-width: 650px) {	

	button.ellipsis {
		max-width: 350px; 
	}

	#pdpPromCode {
		#offercode {
			width: 75% !important;
		}
	}

	.contact_iframe {
		height: 1250px;
	}
}