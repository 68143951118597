body.bootstrap {
	
	.quickview:not(.modal) {
		width: 760px!important;
		
		.form-group.print, .form-group.digital, .form-group.bundle {
			width: 95%!important;
		}
		
		.pricing-block {
		
			.block {
				background: none;
			}
		}
	}
}
@media screen and (max-width: 800px) {
	body.bootstrap {

		.quickview:not(.modal) {
			width: auto !important;

			.prices {
				padding: 0;
			}

			.quickviewHolder {
				.body {
					padding: 0 0 30px 0;
				}
			}
		}
	}
}
.quickview:not(.modal) {
	top: 50%;
	left: 50%;
	width: 730px!important;
	margin: 10px auto!important;
	background-color: $white;
	padding: 0 20px 20px 20px;
	z-index: 7;

	.magazine-name {
		padding: 15px 0;
		float: left;
		font-size: 25px;
		width: 86%;
	}
	
	.choose-country,  .block.buymessage {
		display: none;
	}
	.block.current {
		background-color: $light-background-colour;
	}
	
	.closer {
		background-color: $red;
		float: right;
		font-family: $demibold-font-stack ;
		font-size: 18px;
		font-weight: normal;
		color: $white;
		width:120px;
		height: 50px;
		line-height: 50px;
		text-align: center;
		cursor: pointer;

		img {
			margin: 11px 15px 0 -3px;
			float: right;
		}
	}

	.product-panel {
		clear: both;
		overflow: visible;
		
		.covers {
			height: 420px ;
			width: 355px;
			float: left;
			
			.small {
				width: 64px;
				float: left;
				img {
					height: 87px;
					box-shadow: 2px 1px 3px #770000;
				}
			}
			.arrow {
				float: left;
				height: 158px;
				width: 24px;
				padding: 160px 0 0 0;
			}
			.arrow.right {
				float: right;
			}
			
			.large {
				margin: 0 auto;
				position: relative ;
				width: 325px;
				> img.print {
					box-shadow: 2px 1px 3px #770000;
				}
				> img {
					height: 400px;
					margin: 0 auto;
					display: block;
				}
			}
		}	
		
		
		.prices {
			position: relative;
			float: right;
			#calendarblurbhere {
				width: 300px;
				padding-left: 15px;
			}
			.mobile {
				width: 340px;
				display: inline-block;
				
				.container {
					.subscription {
						overflow: visible;
						
						.pricing-container {
							.block {
								&:nth-last-child(1) {
									overflow: visible;
									min-height: 93px;
								}
								
								.tt-info {
									&:hover {
										.more-info.bottom {
											left: -395px !important;
										}
									}
								}
							}
						}
					}
				}
			}
			.bundle:not(.pricing-block) {
				.price-statement {
					margin-top: 15px;
					font-family: $demibold-font-stack;
				}
			}
		}
	}
}

.quickview-link {
	font-size: 13px;
	color: rgb(115, 115, 115);
	cursor: pointer;
	line-height: 13px;
	width: 102px;
	height: 19px;
	background-position-x: 4px;
	background-position-y: 4px;
	padding-left: 16px;
}

.quickview-button:not(.make-changes), .viewMoreBTN, .fom-button {
	font-size: 13px;
	cursor: pointer;
	border-style: solid;
	border-width: 1px;
	padding: 0 10px;
	display: inline-block !important;
}
.viewMoreBTN {
	color:rgb(115, 115, 115);
	background:rgb(255, 255, 255);
	border-color: rgb(115, 115, 115);
}
.quickview-button:not(.make-changes), .fom-button {
	background-color: $red;
	border-color: $red;
	color: rgb(255, 255, 255);
}
.quickview-button.orange {
	color: $white!important;
}
.quickview-single-issue, .quickviewcontainer {
	
	.quickview:not(.modal) {
		.closer {
			margin-right: -20px;
		}
		.product-panel {
		
			.covers {
				height: 465px;

				.large {
					width: 390px;
					margin-top: 9px;
					
					img {
						height: 420px!important;
					}

					img.digital {
						height: 400px !important
					}
				}
			}
			.prices {
				.mobile {

					h3 {
						font-size: 22px;
					}
					.open {
						margin-top: 15px;
						
						.country-select {
							
							.flag {
								margin-left: 0px;
							}
						}
					}
					.container {
						margin: 0 auto 0 0;
							
						.subscription {
							padding: 0 10px 0 0;
							
							.block {
								border-bottom: #c2c2c2 0px solid;
								border: #c2c2c2 0px solid;
							}
						}
					}
				}
			}
		}
		.tick_box {
			position: relative;
			clear: both;

			ul {
				padding: 10px 10px 10px 0;
				display: inline-block;

				li {
					background: url(/images/circletick.webp) no-repeat;
					padding: 9px 0px 9px 23px;
					background-size: 15px;
					background-position: 0px 13px;
				}
			}
		}
	}
}
.quickviewHolder {
	padding-top: 0px!important;
	overflow: visible;
		
	.body {
		padding: 0px 0px 30px 15px;
		overflow: visible;
		float: left;
		
		.lhs {
			float: left ;
			background-color: #fefefe;
			
			p {
				padding-left: 0px !important;
			}

			#rhsprintbenefit {
				margin: 5px auto !important;
				width: 100%;
				text-align: center;
			}
			
			.subscription .country-select .country-field {
				width: 84%;
				margin-right: 0;
			}
			#rhs-mag-cover{
				margin: 0 auto;
					display: block;
					width: 100%;
			}
			
			.rhs.gift-yes img {
				width: 80%;	
			}
			
			.centred {
				margin: 0 auto;
				text-align: center;
			}
			.simple-inline {
				display: inline;
				font-size: 15px;
				width: 170px;
				float: none;
				height: 40px;
				margin: 2px 0;
				background-color: #ffffff;
				appearance: none;
				padding: 4px 14px;
				
				.showXHH {
					display: block!important;
				}
			}
			#magazinesize-select {
				width: 190px;
			}
			#magazinetype {
				padding-top: 4px;
			}
			#giftheadline {
				font-weight: bold;
			}
			#gifttext, #giftheadline {
				text-align: center;
			}
			#gifttext {
				margin-bottom: 0!important;
			}
			.giftbox {
				margin-top: 13px;
			}
			.giftbox img {
				display: block;
				margin: 0 auto;
				width: 50%!important;
			}
			#giftblock label {
				width: 120px;
			}
			#giftblock .labelled {
				margin: 0;
				padding: 0;
				background: none;
				height: 40px;
			}
			#gifterror {
				visibility: hidden; 
				display: none;
			}
			.gifterror.bottomerror {
				display: none;
			}
			.gifterror.bottomerror.error {
				display: inline-block;
				margin: 10px 15px;
				text-align: center;
				width: 95%;
			}
			.gifterror.bottomerror p {
				padding: 3px;
				border: 1px solid #db071a;
			}
			.infoerror {
				color: #fff;
				background: #db071a;
				width: 23px;
				height: 23px;
				line-height: 12px;
				margin-right: 10px;
				font-weight: bold;
				font-size: 19px;
				box-sizing: border-box;
				display: inline-block;
				padding: 6px;
			}
			#giftblock.error p, #giftblock.error label {
				color: #db071a;
			}
			#giftblock.error label {
				border-color: #db071a;
			}
			
			#giftblock.error #gifterror {
				visibility: visible; 
			}
			.subscription .country-select {
				margin-bottom: 20px;
			}
			.pricing p strong span {
				display: block;
			}
			.tt-info:hover {
				.more-info.bottom {
					left: -225px !important;
				}
			}
			.pricing-container {
				margin: 10px 0 ;
				position: relative;
				z-index: 8;
			}
			
			#pricingblock form {
				display: none;
			}
			
			.pricing-block {
				background-color: $white;
				width: 100%;
			}
			.pricing-block, .country-field {
				cursor: default;
			}
			.country-field {
				max-width: 282px !important;
				margin-right: 0;
			}
			.subscription .country-select .country-field, .country-field, .simple-inline, .pricing-block .block:not(#fixedPricing):not(#rollingPricing):not(.quickviewPricing) {
				width: 100% !important;
				max-width: 325px;
				border-radius: 8px;
				background-image: url(/images/drop-down.webp) !important;
				background-size: 20px 20px !important;
				background-position-x: calc(100% - 14px) !important;
				background-repeat: no-repeat !important;
				background-position-y: center;
				font-size: 14px !important;

				p, strong, input {
					font-size: 14px !important;
				}
			}
			.button.red {
				border-radius: 8px !important;
				margin-bottom: 6px;
				height: 47px !important;
				font-size: 16px !important;
				font-weight: 700;
			}
			.pricing-block .block {
				border: 1px #c2c2c2 solid;
				padding: 5px 20px 5px 10px;
				line-height: 22px;
				
				.asa {
					font-size: 12px;
				}
			}
			.pricing-block.expanded .block {
				border-radius: 0;
				border-top: none ;	
				background: none;
			}
			.pricing-block.expanded .block:first-child {
				border-top: 1px #c2c2c2 solid ;
			}
			.pricing-block.expanded .block:hover {
				background-color: #ccc;
			}
			.pricing-block.expanded .block.chosen {
				background: url('/images/tick.webp') no-repeat 95% 50%;
			}
			
			#intro_more.fold-out {
				margin-bottom: 100px;
			}
			
			#rhsgifttext img {
				max-width: 100px;
				max-height: 100px;
				float: right;
			}
			.messagebox.yesGift  {
				margin-top: 735px!important;
			}
			.messagebox.noGift {
				margin-top: 715px!important;
			}
			.messagebox.error {
				margin-top: 705px!important;
			}
			.right-column {
				width: 660px;
				padding-right: 40px;
			}
			.faqs_container .faqs {
				width: 81.75%;
			}
			.faqs_container {
				margin: 75px auto;
			}
		}
	} 
}